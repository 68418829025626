import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { householdMembers, pets } from "./datasource";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import citizenProfileService from "../service/citizen/CitizenProfile";
import { USStates } from "./datasource";
import { Query } from "@syncfusion/ej2-data";
import DatePickerTemplate from "../components/DatePickerTemplate";
import { decrypt } from "../service/encryption/Encryption";
import mailer from "../service/api/mailer";
import { auth, db } from "../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const sortSettings = {
  allowSorting: true,
};

const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  mode: "Dialog",
  showDeleteConfirmDialog: true,
  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "CheckBox",
};

const intl = new Internationalization();

const formatDate = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp); // Assuming timestamp is already a string
    return date.toLocaleDateString("en-US"); // Format as MM/DD/YYYY
  }
  return "";
};

const militaryVetTypes = [{ value: "Yes" }, { value: "No" }];
const relationshipTypes = [
  { value: "Husband" },
  { value: "Wife" },
  { value: "Daughter" },
  { value: "Son" },
  { value: "Other Relatives" },
];

const petSizeTypes = [
  { value: "Small" },
  { value: "Medium" },
  { value: "Large" },
];

const petCoatTypes = [
  { value: "Wire" },
  { value: "Straight" },
  { value: "Shaggy" },
  { value: "Curly" },
  { value: "Wavy" },
];

const militaryvetParams = {
  params: {
    allowFiltering: true,
    dataSource: militaryVetTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const relationshipParams = {
  params: {
    allowFiltering: false,
    dataSource: relationshipTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const petSizeParams = {
  params: {
    allowFiltering: false,
    dataSource: petSizeTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const petCoatParams = {
  params: {
    allowFiltering: true,
    dataSource: petCoatTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const CitizenProfile = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempFormData, setTempFormData] = useState({});
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [householdMembers, setHouseholdMembers] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const errorTimeoutRef = useRef(null);
  const [dob, setDob] = useState(null);
  const [householdId, setHouseholdId] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    if (citizenData) {
      setData(citizenData);
      setTempFormData(citizenData);
      if (citizenData.updatedData_at)
        setLastEditedTime(
          citizenData.updated_at instanceof Date
            ? citizenData.updated_at
            : new Date(citizenData.updated_at)
        );

      setHouseholdId(citizenData.household_id);
      const householdData = await citizenProfileService.getHouseholdInfo(
        citizenData.household_id
      );
      setHouseholdFormData({
        ...householdData,
        disability: citizenData.disability,
        military_vet: citizenData.military_vet,
      });
      setTempHouseholdFormData({
        ...householdData,
        disability: citizenData.disability,
        military_vet: citizenData.military_vet,
      });
      setLastHouseholdEditedTime(
        householdData.updated_at
          ? householdData.updated_at instanceof Date
            ? householdData.updated_at
            : new Date(householdData.updated_at)
          : null
      );

      // Fetch household members
      const householdMembersData = (
        await citizenProfileService.getHouseholdMembers(
          citizenData.household_id,
          citizenData.household_member_id
        )
      ).filter(
        (member) =>
          member.household_member_id !== citizenData.household_member_id
      );
      console.log("householdMembersData", householdMembersData);
      setHouseholdMembers(householdMembersData);
    }

    // Fetch pets
    const petsData = await citizenProfileService.getPets(
      citizenData.household_id
    );
    setPets(petsData);
  };

  const togglePersonalInfo = (event) => {
    event.preventDefault();
    setIsPersonalInfoVisible(!isPersonalInfoVisible);
  };

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(true);
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    await savePersonalInfo();
  };

  const savePersonalInfo = async () => {
    const errors = validatePersonalInfo(tempFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);

      return;
    }

    const currentTime = new Date();
    const updatedData = {
      ...tempFormData,
      disability: tempHouseholdFormData.disability,
      military_vet: tempHouseholdFormData.military_vet,
      updated_at: currentTime,
    };
    console.log("updatedData", updatedData);

    try {
      const requiredFields = [
        "first_name",
        "last_name",
        "mobile_number",
        "address1",
        "city",
        "state",
        "zip",
        "county",
        "alternate_contact_name",
        "alternate_email",
        "alternate_mobile_number",
        "date_of_birth",
        "gender",
        "military_vet",
        "disability",
      ];
      const isProfileComplete = requiredFields.every(
        (field) => updatedData[field]
      );
      if (isProfileComplete) {
        console.log("Profile is complete");
        await citizenProfileService.updateCitizenProfile("users", userId, {
          ...updatedData,
          profile_completed: true,
        });
      } else {
        console.log("Profile is not complete");
        await citizenProfileService.updateCitizenProfile("users", userId, {
          ...updatedData,
          profile_completed: true,
        });
      }
      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTempFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputBlur = async () => {
    const errors = validatePersonalInfo(tempFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);

      return;
    }

    const currentTime = new Date();
    const updatedData = {
      ...tempFormData,
      disability: tempHouseholdFormData.disability,
      military_vet: tempHouseholdFormData.military_vet,
      home_insurance: tempHouseholdFormData.home_insurance,
      automotive_insurance: tempHouseholdFormData.automotive_insurance,
      updated_at: currentTime,
    };

    try {
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedData
      );
      setData(updatedData);
      setLastEditedTime(currentTime);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const handleHouseholdInputBlur = async () => {
    const errors = validateHouseholdInfo(tempHouseholdFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);
      return;
    }

    try {
      const currentTime = new Date();

      // Remove nested household object and other unnecessary fields
      const { household, id, ...rawData } = tempHouseholdFormData;

      // Create a clean object with only the fields we want
      const updatedHouseholdData = {
        annual_income: rawData.annual_income || "",
        owner_or_renter: rawData.owner_or_renter || "",
        insurance: Boolean(rawData.insurance),
        household_size: rawData.household_size || "",
        home_insurance: Boolean(rawData.home_insurance),
        home_insurance_type: rawData.home_insurance_type || "",
        automotive_insurance: Boolean(rawData.automotive_insurance),
        automotive_insurance_type: rawData.automotive_insurance_type || "",
        updated_at: currentTime,
      };

      if (!data.household_id) {
        console.error("No household ID found");
        return;
      }

      // Update the backend
      await citizenProfileService.updateHouseholdInfo(
        data.household_id,
        updatedHouseholdData
      );

      // Update the frontend state with the clean data
      setHouseholdFormData(updatedHouseholdData);
      setTempHouseholdFormData(updatedHouseholdData);
      setLastHouseholdEditedTime(currentTime);
      setIsHouseholdEditMode(false);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating household profile: ", error);
    }
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const renderField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = ""
  ) => {
    const requiredFields = [
      "first_name",
      "last_name",
      "birth_date",
      "address1",
      "city",
      "state",
      "zip",
      "mobile_number",
      "email",
      "alternate_contact_name",
      "alternate_email",
      "alternate_mobile_number",
      "gender",
    ];
    const isRequired = requiredFields.includes(name);

    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative flex items-center text-left text-xs leading-[20px]">
          {label}
          {isRequired && (
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          )}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${
            validationErrors[name] ? "border-red-500" : "border-gray-300"
          } px-3 py-2`}
        >
          {isEditMode ? (
            name === "birth_date" ? (
              <DatePickerTemplate
                value={tempFormData[name || ""]}
                onChange={(val) =>
                  handleInputChange({ target: { name, value: val } })
                }
              />
            ) : isDropdown ? (
              <select
                className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {name === "gender" ? (
                  <>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </>
                ) : (
                  USStates.map((state) => (
                    <option key={state.stateID} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  ))
                )}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
                type="text"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                placeholder={placeholder}
              />
            )
          ) : (
            <div
              className={`flex-1 text-xs ${
                tempFormData[name]
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {tempFormData[name] || placeholder}
            </div>
          )}
        </div>
        {isEditMode && validationErrors[name] && (
          <div className="mt-1 text-xs text-red-500">
            {validationErrors[name]}
          </div>
        )}
      </div>
    );
  };

  const validatePersonalInfo = (formData) => {
    const errors = {};
    const requiredFields = [
      "first_name",
      "last_name",
      "birth_date",
      "address1",
      "city",
      "state",
      "zip",
      "mobile_number",
      "email",
      "alternate_contact_name",
      "alternate_email",
      "alternate_mobile_number",
      "gender",
    ];

    requiredFields.forEach((field) => {
      if (
        !formData[field] ||
        (typeof formData[field] === "string" && formData[field].trim() === "")
      ) {
        errors[field] = "This field is required";
      }
    });

    return errors;
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    console.log(
      "Decrypted otp: ",
      decrypt("U2FsdGVkX1/29ppZYHqOhgNJYos2nVGPUDV7gnrkC+Y=")
    );
    const isNewlyRegistered = localStorage.getItem("newlyRegistered");
    if (isNewlyRegistered === "true") {
      setShowPopup(true);
      localStorage.removeItem("newlyRegistered"); // Remove the flag after showing the popup
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            color: "#1e1919",
            marginBottom: "10px",
          }}
        >
          Welcome to your Citizen Profile!
        </h2>
        <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
          Please finish your profile to get the most out of our services.
        </p>
        <button
          onClick={handleClosePopup}
          className="btn-default"
          style={{
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  const [isHouseholdInfoVisible, setIsHouseholdInfoVisible] = useState(true);
  const [householdFormData, setHouseholdFormData] = useState({
    annual_income: "",
    ssn: "",
    owner_or_renter: "",
    insurance: false,
    household_size: "",
    disability: false,
    military_vet: false,
    home_insurance: false,
    home_insurance_type: "",
    automotive_insurance: false,
    automotive_insurance_type: "",
  });
  const [tempHouseholdFormData, setTempHouseholdFormData] = useState({
    ...householdFormData,
  });
  const [lastHouseholdEditedTime, setLastHouseholdEditedTime] = useState(null);

  const [isHouseholdEditMode, setIsHouseholdEditMode] = useState(false);

  const toggleHouseholdInfo = (event) => {
    event.preventDefault();
    setIsHouseholdInfoVisible(!isHouseholdInfoVisible);
  };

  const startHouseholdEditing = (event) => {
    event.preventDefault();
    setIsHouseholdEditMode(true);
  };

  const validateHouseholdInfo = (formData) => {
    const errors = {};
    const requiredFields = ["owner_or_renter", "insurance", "household_size"];

    requiredFields.forEach((field) => {
      if (
        formData[field] === undefined ||
        formData[field] === "" ||
        formData[field] === null
      ) {
        errors[field] = "This field is required";
      }
    });

    return errors;
  };

  const saveHouseholdChanges = async (event) => {
    event.preventDefault();

    try {
      const currentTime = new Date();

      // Ensure we're working with a clean object
      const updatedHouseholdData = {
        annual_income: String(tempHouseholdFormData.annual_income || ""),
        owner_or_renter: String(tempHouseholdFormData.owner_or_renter || ""),
        // insurance: Boolean(tempHouseholdFormData.insurance),
        household_size: String(tempHouseholdFormData.household_size || ""),
        home_insurance: Boolean(tempHouseholdFormData.home_insurance),
        home_insurance_type: String(
          tempHouseholdFormData.home_insurance_type || ""
        ),
        automotive_insurance: Boolean(
          tempHouseholdFormData.automotive_insurance
        ),
        automotive_insurance_type: String(
          tempHouseholdFormData.automotive_insurance_type || ""
        ),
        updated_at: currentTime,
      };

      // Ensure we have a valid household_id
      if (!data?.household_id) {
        throw new Error("No household ID found");
      }

      // Log the data we're sending
      console.log("Sending data:", updatedHouseholdData);

      // Update the backend
      const result = await citizenProfileService.updateHouseholdInfo(
        data.household_id,
        updatedHouseholdData
      );

      // Update the frontend state with the clean data
      setHouseholdFormData(result);
      setTempHouseholdFormData(result);
      setLastHouseholdEditedTime(currentTime);
      setIsHouseholdEditMode(false);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating household profile: ", error);
    }
  };

  const cancelHouseholdEditing = (event) => {
    event.preventDefault();
    setTempHouseholdFormData({ ...householdFormData });
    setIsHouseholdEditMode(false);
  };

  const handleHouseholdInputChange = (event) => {
    const { name, value } = event.target;
    setTempHouseholdFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "disability" ||
        name === "military_vet" ||
        name === "insurance" ||
        name === "home_insurance" ||
        name === "automotive_insurance"
          ? value === "Yes"
          : value,
    }));
  };

  const renderHouseholdField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = "",
    isOptional = false
  ) => {
    const isRequired = !isOptional;

    const handleAnnualIncomeChange = (e) => {
      let inputValue = e.target.value;

      const numericValue = inputValue.replace(/[^0-9]/g, "");

      handleHouseholdInputChange({
        target: { name: name, value: numericValue ? `$${numericValue}` : "" },
      });
    };

    const validateAnnualIncome = () => {
      if (
        name === "annual_income" &&
        (!tempHouseholdFormData[name] || tempHouseholdFormData[name] === "$")
      ) {
        // Trigger validation error if the field is invalid
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Annual income is required",
        }));
      } else {
        // Remove the error if it's valid
        setValidationErrors((prevErrors) => {
          const { [name]: _, ...newErrors } = prevErrors;
          return newErrors;
        });
      }
    };

    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 hover:cursor-pointer ${className}`}
      >
        <div className="text-gray-600 relative text-left  text-xs leading-[20px] hover:cursor-pointer">
          {label}
          {isRequired ? (
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          ) : (
            <span className="ml-1 text-xs text-gray-400">(Optional)</span>
          )}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isHouseholdEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${
            validationErrors[name] ? "border-red-500" : "border-gray-300"
          } px-3 py-2`}
        >
          {isHouseholdEditMode ? (
            isDropdown ? (
              <select
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none] hover:cursor-pointer"
                name={name}
                value={
                  name === "insurance"
                    ? tempHouseholdFormData[name]
                      ? "Yes"
                      : "No"
                    : tempHouseholdFormData[name] || ""
                }
                onChange={handleHouseholdInputChange}
                // onBlur={handleHouseholdInputBlur}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {name === "owner_or_renter" ? (
                  <>
                    <option value="Owner" className="hover:cursor-pointer">
                      Owner
                    </option>
                    <option value="Renter" className="hover:cursor-pointer">
                      Renter
                    </option>
                    <option
                      value="Rent to Own"
                      className="hover:cursor-pointer"
                    >
                      Rent to Own
                    </option>
                  </>
                ) : name === "annual_income" ? (
                  <>
                    <option value="0-20000" className="hover:cursor-pointer">
                      0-$20,000
                    </option>
                    <option
                      value="20001-40000"
                      className="hover:cursor-pointer"
                    >
                      $20,001-$40,000
                    </option>
                    <option
                      value="40001-60000"
                      className="hover:cursor-pointer"
                    >
                      $40,001-$60,000
                    </option>
                    <option
                      value="60001-80000"
                      className="hover:cursor-pointer"
                    >
                      $60,001-$80,000
                    </option>
                    <option
                      value="80001-100000"
                      className="hover:cursor-pointer"
                    >
                      $80,001-$100,000
                    </option>
                    <option value="100000+" className="hover:cursor-pointer">
                      Greater than $100,000
                    </option>
                  </>
                ) : name === "home_insurance_type" ? (
                  <>
                    <option value="Homeowners" className="hover:cursor-pointer">
                      Homeowners
                    </option>
                    <option value="Renter" className="hover:cursor-pointer">
                      Renter
                    </option>
                  </>
                ) : name === "automotive_insurance_type" ? (
                  <>
                    <option
                      value="Full Coverage"
                      className="hover:cursor-pointer"
                    >
                      Full Coverage
                    </option>
                    <option value="Liability" className="hover:cursor-pointer">
                      Liability
                    </option>
                  </>
                ) : (
                  <option value="" disabled>
                    {placeholder}
                  </option>
                )}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                placeholder={placeholder}
                name={name}
                value={tempHouseholdFormData[name] || ""}
                onChange={
                  name === "annual_income"
                    ? handleAnnualIncomeChange
                    : handleHouseholdInputChange
                }
                onBlur={
                  name === "annual_income"
                    ? validateAnnualIncome
                    : handleHouseholdInputBlur
                }
              />
            )
          ) : (
            <div
              className={`flex-1  text-xs ${
                householdFormData[name] !== undefined &&
                householdFormData[name] !== ""
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {name === "insurance"
                ? householdFormData[name]
                  ? "Yes"
                  : "No"
                : householdFormData[name] || placeholder}
            </div>
          )}
        </div>
        {isHouseholdEditMode && validationErrors[name] && (
          <div className="mt-1 text-xs text-red-500">
            {validationErrors[name]}
          </div>
        )}
      </div>
    );
  };

  {
    /* Household Members */
  }
  const [isHouseholdMembersVisible, setIsHouseholdMembersVisible] =
    useState(true);
  const [lastHouseholdMembersEditedTime, setLastHouseholdMembersEditedTime] =
    useState(null);

  const toggleHouseholdMembers = (event) => {
    event.preventDefault();
    setIsHouseholdMembersVisible(!isHouseholdMembersVisible);
  };

  const gridRef = useRef(null);

  const handleAddClick = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const createConfirmDialog = (title, content, onConfirm) => {
    const dialog = new DialogComponent({
      header: title,
      content: content,
      buttons: [
        {
          click: () => {
            onConfirm();
            closeAndDestroyDialog(dialog);
          },
          buttonModel: { content: "Yes", isPrimary: true },
        },
        {
          click: () => {
            closeAndDestroyDialog(dialog);
          },
          buttonModel: { content: "No" },
        },
      ],
      width: "300px",
      isModal: true,
      visible: false,
      cssClass: "delete-confirmation-dialog",
      close: () => closeAndDestroyDialog(dialog),
    });
    dialog.appendTo("#dialog-container");

    // Apply custom styles to the dialog header
    dialog.created = () => {
      if (dialog.element) {
        const headerElement = dialog.element.querySelector(
          ".e-dlg-header-content"
        );
        if (headerElement) {
          headerElement.style.backgroundColor = "#348BFF";
          headerElement.style.color = "#FFFFFF";
          headerElement.style.padding = "10px";
        }
      }
    };

    dialog.show();
  };

  const closeAndDestroyDialog = (dialog) => {
    dialog.hide();
    setTimeout(() => {
      dialog.destroy();
      const dialogElement = document.querySelector(
        ".delete-confirmation-dialog"
      );
      if (dialogElement) {
        dialogElement.remove();
      }
    }, 300); // Wait for the hide animation to complete
  };

  const actionBegin = async (args) => {
    // Initialize data object if it doesn't exist
    if (!args.data) {
      args.data = {};
    }

    if (args.requestType === "add") {
      // Set default values for new records
      args.data = {
        ...args.data,
        disability: "N/A",
        military_vet: "No",
      };
    }

    if (args.requestType === "beginEdit") {
      // Get the existing data from the row
      const rowData = args.rowData;

      // Set the date of birth if it exists
      if (rowData?.date_of_birth) {
        setDob(rowData.date_of_birth);
      }

      // Safely set the values with defaults
      args.data = {
        ...args.data,
        ...rowData,
        disability: rowData?.disability || "N/A",
        military_vet: rowData?.military_vet || "No",
      };
    }

    if (args.requestType === "beginEdit" || args.requestType === "add") {
      gridRef.current.columns.forEach((col) => {
        if (col.validationRules && col.validationRules.required) {
          // Append * to required field headers
          col.headerText = col.headerText.replace(/\*$/, "") + " *";
        }
      });
    }
  };

  const actionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure householdMemberID and status are set
        const newMemberData = {
          ...args.data,
          household_member_id:
            args.data.household_member_id || generateUniqueID(),
          is_deleted: false,
          date_of_birth: dob
            ? new Date(dob).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
          disability: args.data.disability || "N/A",
          military_vet: args.data.military_vet || "No",
          email: args.data.email || "",
          acc_created: false,
        };
        // Check if email already exists in users collection
        const usersRef = collection(db, "users");
        console.log("args.data.email", args.data);
        const q = query(usersRef, where("email", "==", args.data.email));
        const querySnapshot = await getDocs(q);
        //console.log("querySnapshot", querySnapshot.docs[0].data());
        //console.log("data.email", data.email);

        if (
          !querySnapshot.empty &&
          querySnapshot.docs[0].data().email != data.email
        ) {
          console.log("Email exists, set acc_created to true");
          // Email exists, set acc_created to true
          newMemberData.acc_created = true;
          if (
            querySnapshot.docs[0].data().household_member_id &&
            querySnapshot.docs[0].data().user_role == "citizen"
          ) {
            console.log(
              "querySnapshot.docs[0].data().household_member_id",
              querySnapshot.docs[0].data().household_member_id
            );
            newMemberData.household_member_id =
              querySnapshot.docs[0].data().household_member_id;
          }
        }
        console.log("newMemberData after change", newMemberData);
        await citizenProfileService.addHouseholdMember(
          householdId,
          newMemberData
        );
        setDob(() => null);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedMemberData = {
          ...args.data,
          is_deleted: false,
          date_of_birth: dob
            ? new Date(dob).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
          disability: args.data.disability || "N/A",
          military_vet: args.data.military_vet || "No",
          email: args.data.email || "",
          acc_created: false,
        };
        console.log("updatedMemberData", updatedMemberData);
        await citizenProfileService.updateHouseholdMember(
          householdId,
          updatedMemberData.id,
          updatedMemberData
        );
        setDob(() => null);
      }
      setLastHouseholdMembersEditedTime(new Date());
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedMemberData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updateHouseholdMember(
        householdId,
        updatedMemberData.id,
        updatedMemberData
      );
      setLastHouseholdMembersEditedTime(new Date());
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Household Member"
            : "Add New Household Member";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#467BA4";
          }
          if (header) {
            header.style.color = "#467BA4";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  // Filter household members to only include those with status true
  const filteredHouseholdMembers = Array.isArray(householdMembers)
    ? householdMembers
        .filter((member) => !member.is_deleted)
        .map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth
            ? new Date(member.date_of_birth).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "mm/dd/yyyy",
        }))
    : [];

  {
    /*Pets*/
  }
  const [pets, setPets] = useState([]);
  const [isPetsVisible, setIsPetsVisible] = useState(true);
  const [lastPetsEditedTime, setLastPetsEditedTime] = useState(null);
  const petsGridRef = useRef(null);

  const togglePets = () => {
    setIsPetsVisible(!isPetsVisible);
  };

  const handleAddPet = () => {
    if (petsGridRef.current) {
      petsGridRef.current.addRecord();
    }
  };

  const actionBeginPets = (args) => {
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      petsGridRef.current.columns.forEach((col) => {
        if (col.validationRules && col.validationRules.required) {
          // Append * to required field headers
          col.headerText = col.headerText.replace(/\*$/, "") + " *";
        }
      });
    }
  };

  const petsActionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure pet_id and status are set
        const newPetData = {
          ...args.data,
          pet_id: generateUniqueID(),
          is_deleted: false,
        };
        await citizenProfileService.addPet(householdId, newPetData);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedPetData = {
          ...args.data,
          is_deleted: false,
        };
        // Use the id field for updating, as it corresponds to the Firestore document ID
        await citizenProfileService.updatePet(
          householdId,
          updatedPetData.id,
          updatedPetData
        );
      }
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(householdId);
      setPets(updatedPets);
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedPetData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updatePet(
        householdId,
        updatedPetData.id, // Use id instead of pet_id
        updatedPetData
      );
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(householdId);
      setPets(updatedPets);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Pet" : "Add New Pet";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  // Filter pets to only include those with status true
  const filteredPets = Array.isArray(pets)
    ? pets.filter((pet) => !pet.is_deleted)
    : [];

  const deletePet = async (data) => {
    try {
      await citizenProfileService.deletePet(householdId, data.pet_id);
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(householdId);
      setPets(updatedPets);
    } catch (error) {
      console.error("Error deleting pet:", error);
    }
  };

  const handleCommandClick = async (args) => {
    console.log("Command clicked:", args.rowData);
    if (args.commandColumn.type === "Invite") {
      console.log("Invite clickeddd");
      if (args.rowData.email && !args.rowData.acc_created) {
        console.log(args.rowData.email);

        const docRef = await addDoc(collection(db, "invited_users"), {
          invited_by: data.id,
          sent_to: args.rowData.email,
          date_invited: serverTimestamp(),
          first_name:
            args.rowData.first_name.charAt(0).toUpperCase() +
            args.rowData.first_name.slice(1),
          last_name:
            args.rowData.last_name.charAt(0).toUpperCase() +
            args.rowData.last_name.slice(1),
          type: "household_member",
          acc_created: false,
        });

        // Update the document to include its ID
        await updateDoc(docRef, {
          id: docRef.id,
        });

        console.log("Invitation sent successfully! ", docRef.id);

        //const link = `${registrationLink}/${invitedUser.id}/${user.id}/${user?.household_id}/${householdData?.household_member_id}/${params?.id}`;

        const link = `${window.location.origin}/registration/${docRef.id}/${data.id}/${data?.household_id}/${data?.household_member_id}`;
        try {
          await mailer.sendHouseholdMemberInviteEmail(
            args.rowData.email,
            args.rowData.first_name.charAt(0).toUpperCase() +
              args.rowData.first_name.slice(1),
            link,
            data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1)
          );
          console.log("Email sent");
        } catch (error) {
          console.error("Error sending email:", error);
        }
      } else {
        console.log("Account already created");
      }
    }
  };

  return (
    <form
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Citizen Profile
        </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 text-sm font-bold text-blue-500">
              PERSONAL INFORMATION
            </h2>
          </div>
          <div className="flex items-center gap-2">
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="btn-default flex cursor-pointer items-center gap-2 px-3 py-1.5"
                  onClick={saveChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="btn-cancel flex cursor-pointer  gap-2 px-3 py-1.5"
                  onClick={cancelEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={startEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
              onClick={togglePersonalInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPersonalInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPersonalInfoVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <div className="flex flex-row gap-4">
              {renderField("First Name", "first_name", "", false, "flex-1")}
              {renderField("Last Name", "last_name", "", false, "flex-1")}
              {renderField(
                "Date of Birth",
                "birth_date",
                "MM/DD/YYYY",
                false,
                "flex-1"
              )}
            </div>

            {renderField("Address 1", "address1", "")}
            {renderField("Address 2", "address2", "")}
            <div className="flex flex-row gap-4">
              {renderField("City", "city", "", false, "flex-1")}
              {renderField(
                "Select a state",
                "state",
                "Select a state",
                true,
                "flex-1"
              )}
              {renderField("Zip", "zip", "e.g., 62704", false, "flex-1")}
            </div>
            <div className="flex flex-row gap-4">
              {renderField("Email", "email", "", false, "flex-1")}
              {renderField(
                "Phone Number",
                "mobile_number",
                "",
                false,
                "flex-1"
              )}
              {renderField("Sex", "gender", "Sex", true, "flex-1")}
            </div>
            <header className="flex flex-row items-center justify-between self-stretch">
              <div>
                <h2 className="text-sm font-bold text-blue-500">
                  Alternate Contact
                </h2>
              </div>
            </header>
            <div className="flex flex-row gap-4">
              {renderField(
                "Contact Name",
                "alternate_contact_name",
                "",
                false,
                "flex-1"
              )}
              {renderField("Email", "alternate_email", "", false, "flex-1")}
              {renderField(
                "Phone Number",
                "alternate_mobile_number",
                "",
                false,
                "flex-1"
              )}
            </div>
            <div className="mb-2 flex flex-row gap-2">
              <div className="w-auto">
                <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                  Do you have a disability?
                  {isEditMode && (
                    <span
                      className="ml-1 text-red-500"
                      style={{ fontSize: "26px" }}
                    >
                      *
                    </span>
                  )}
                </p>
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="disability"
                      value="Yes"
                      checked={tempHouseholdFormData.disability === true}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">Yes</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="disability"
                      value="No"
                      checked={tempHouseholdFormData.disability === false}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">No</span>
                  </label>
                </div>
                {isEditMode && validationErrors.disability && (
                  <div className="mt-1 text-xs text-red-500">
                    {validationErrors.disability}
                  </div>
                )}
              </div>

              <div className="w-auto" style={{ marginLeft: "50px" }}>
                <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                  Are you Active Military / Vet?
                  {isEditMode && (
                    <span
                      className="ml-1 text-red-500"
                      style={{ fontSize: "26px" }}
                    >
                      *
                    </span>
                  )}
                </p>
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="military_vet"
                      value="Yes"
                      checked={tempHouseholdFormData.military_vet === true}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">Yes</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="military_vet"
                      value="No"
                      checked={tempHouseholdFormData.military_vet === false}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">No</span>
                  </label>
                </div>
                {isEditMode && validationErrors.military_vet && (
                  <div className="mt-1 text-xs text-red-500">
                    {validationErrors.military_vet}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>

      {/* Household Information */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">
              HOUSEHOLD INFORMATION
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastHouseholdEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {isHouseholdEditMode ? (
              <>
                <button
                  type="button"
                  className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={saveHouseholdChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="btn-cancel flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={cancelHouseholdEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={startHouseholdEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdInfoVisible && (
          <div className="mt-2 flex flex-col gap-2 self-stretch">
            <div className="flex flex-row gap-4 hover:cursor-pointer">
              {renderHouseholdField(
                "Annual Household Income",
                "annual_income",
                "Select Annual Income",
                true,
                "flex-1",
                "number",
                true // Mark as optional
              )}

              {renderHouseholdField(
                "Owner or Renter?",
                "owner_or_renter",
                "Select Owner or Renter",
                true,
                "flex-1"
              )}
              {/* {renderHouseholdField(
                "Do you have insurance?",
                "insurance",
                "Select Yes or No",
                true,
                "flex-1"
              )} */}
              {renderHouseholdField(
                "Household size",
                "household_size",
                "",
                false,
                "flex-1"
              )}
            </div>

            <div className="flex w-full flex-row gap-8 pt-3">
              <div className="flex w-1/3 flex-row gap-4">
                <div className="w-auto">
                  <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                    Do you have Home Insurance?
                    {isEditMode && (
                      <span
                        className="ml-1 text-red-500"
                        style={{ fontSize: "26px" }}
                      >
                        *
                      </span>
                    )}
                  </p>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="home_insurance"
                        value="Yes"
                        checked={tempHouseholdFormData.home_insurance === true}
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="home_insurance"
                        value="No"
                        checked={tempHouseholdFormData.home_insurance === false}
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">No</span>
                    </label>
                  </div>
                  {isHouseholdEditMode && validationErrors.home_insurance && (
                    <div className="mt-1 text-xs text-red-500">
                      {validationErrors.home_insurance}
                    </div>
                  )}
                </div>
                {tempHouseholdFormData.home_insurance &&
                  renderHouseholdField(
                    "Home Insurance Type",
                    "home_insurance_type",
                    "Select Home Insurance Type",
                    true,
                    "flex-1",
                    "dropdownedit",
                    false
                  )}
              </div>
              <div className="flex w-1/3 flex-row gap-4">
                <div className="w-auto">
                  <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                    Do you have Automotive Insurance?
                    {isEditMode && (
                      <span
                        className="ml-1 text-red-500"
                        style={{ fontSize: "26px" }}
                      >
                        *
                      </span>
                    )}
                  </p>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="automotive_insurance"
                        value="Yes"
                        checked={
                          tempHouseholdFormData.automotive_insurance === true
                        }
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="automotive_insurance"
                        value="No"
                        checked={
                          tempHouseholdFormData.automotive_insurance === false
                        }
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">No</span>
                    </label>
                  </div>
                  {isHouseholdEditMode &&
                    validationErrors.automotive_insurance && (
                      <div className="mt-1 text-xs text-red-500">
                        {validationErrors.automotive_insurance}
                      </div>
                    )}
                </div>
                {tempHouseholdFormData.automotive_insurance &&
                  renderHouseholdField(
                    "Automotive Insurance Type",
                    "automotive_insurance_type",
                    "Select Automotive Insurance Type",
                    true,
                    "flex-1",
                    "dropdownedit",
                    false
                  )}
              </div>
            </div>
          </div>
        )}
      </section>

      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">
              ADDITIONAL HOUSEHOLD MEMBERS
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited:{" "}
              {formatLastEditedTime(lastHouseholdMembersEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="btn-default flex cursor-pointer  gap-1 px-3 py-1.5"
              onClick={handleAddClick}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>

            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdMembers}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdMembersVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdMembersVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={gridRef}
              dataSource={filteredHouseholdMembers}
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionBegin={actionBegin}
              actionComplete={actionComplete}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              commandClick={handleCommandClick}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="acc_created"
                  headerText="householdMemberID"
                  visible={false}
                />
                <ColumnDirective
                  field="household_member_id"
                  headerText="householdMemberID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="first_name"
                  headerText="First Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="last_name"
                  headerText="Last Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="email"
                  headerText="Email"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="date_of_birth"
                  headerText="Date of Birth"
                  width="100"
                  format="MM/dd/yyyy"
                  editTemplate={(props) => (
                    <DatePickerTemplate
                      label="Date of Birth *"
                      onChange={(val) => {
                        setDob(() => val);
                      }}
                      value={props.date_of_birth}
                    />
                  )}
                />
                <ColumnDirective
                  field="relationship"
                  headerText="Relationship"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={relationshipParams}
                />
                <ColumnDirective
                  field="disability"
                  headerText="Person with disability?"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={militaryvetParams}
                />
                <ColumnDirective
                  field="military_vet"
                  headerText="Active Military/ Vet"
                  width="120"
                  editType="dropdownedit"
                  edit={militaryvetParams}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="140"
                  commands={[
                    {
                      type: "Invite",
                      buttonOption: {
                        content: "Invite",
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        )}
      </section>

      {/* Pets Section */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4 pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1  text-sm font-bold text-blue-500">PETS</h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastPetsEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
              onClick={handleAddPet}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={togglePets}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPetsVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPetsVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={petsGridRef}
              dataSource={filteredPets}
              keyField="pet_id"
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionBegin={actionBeginPets}
              actionComplete={petsActionComplete}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="pet_id"
                  headerText="Pet ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="pet_name"
                  headerText="Pet Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_color"
                  headerText="Pet Color"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_coat"
                  headerText="Pet Coat"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={petCoatParams}
                />
                <ColumnDirective
                  field="pet_breed"
                  headerText="Pet Breed"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_size"
                  headerText="Pet Size"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={petSizeParams}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        )}
      </section>
      <DialogComponent
        width="400px"
        isModal={true}
        visible={showPopup}
        close={handleClosePopup}
        header={null}
        content={dialogContent}
        showCloseIcon={true}
        closeOnEscape={true}
        target={document.body}
        cssClass="welcome-dialog"
      />
    </form>
  );
};

CitizenProfile.propTypes = {
  className: PropTypes.string,
};

export default CitizenProfile;
