import React, { useState, useRef, useEffect } from "react";
import { useDisasterContext } from "../../Disasters/DisasterContext";
import PropTypes from "prop-types";

import AssistanceLog from "../../Disasters/AssistanceLog";
import DisasterDetail from "./DisasterDetail";
import DisasterDashboard from "./DisasterDashboard";
import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import DisasterAffectedCounty from "./DisasterAffectedCounty";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

const DisasterManagementList = ({ className = "" }) => {
  const [activeDisasterTab, setActiveDisasterTab] = useState("Dashboard");
  const [selectedDisaster, setSelectedDisaster] = useState("All");
  const location = useLocation();
  const [selectedDisasters, setSelectedDisasters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [showBlur, setShowBlur] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (location.state?.selectedDisasterId) {
      setSelectedDisasters([location.state.selectedDisasterId]);
    }
  }, [location.state?.selectedDisasterId]);

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        setIsLoading(true);
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().disaster_name,
          status: doc.data().disaster_status,
          disaster_type: doc.data().disaster_type,
          county: doc.data().disaster_county,
          state: doc.data().disaster_state,
        }));

        const activeDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "active"
        );

        setTimeout(() => {
          setShowBlur(activeDisasters.length === 0);
          setIsLoading(false);
        }, 1000);

        setActiveDisasters(activeDisasters.map((disaster) => disaster.id));
        setDisasterOptions([...activeDisasters]);
        if (activeDisasters.length > 0) {
          setSelectedDisasters([activeDisasters[0].id]);
        }
        if (location.state?.selectedDisasterId) {
          setSelectedDisasters([location.state.selectedDisasterId]);
        }
      } catch (error) {
        console.error("Error fetching disasters:", error);
        setTimeout(() => {
          setShowBlur(true);
          setIsLoading(false);
        }, 1000);
      }
    };

    fetchDisasters();
  }, []);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDisasterChange = (disaster) => {
    setSelectedDisasters((prev) => {
      if (disaster === "All") {
        return ["All"];
      }
      const newSelection = prev.includes(disaster)
        ? prev.filter((d) => d !== disaster && d !== "All")
        : [...prev.filter((d) => d !== "All"), disaster];
      return newSelection.length === 0 ? ["All"] : newSelection;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const auth = getAuth();
  const [caseNumber, setCaseNumber] = useState("");

  useEffect(() => {
    const fetchCaseNumber = async () => {
      if (auth.currentUser?.uid && selectedDisasters.length > 0) {
        if (selectedDisasters.includes("All")) {
          setCaseNumber("");
          return;
        }

        try {
          const surveyQuery = query(
            collection(db, `users/${auth.currentUser.uid}/survey_responses`)
          );

          const querySnapshot = await getDocs(surveyQuery);
          let foundCaseNumber = "";
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.disasters) {
              const surveyDisasterIds = data.disasters.map(
                (d) => d.disaster_id
              );
              const selectedDisastersInSurvey = selectedDisasters.filter((id) =>
                surveyDisasterIds.includes(id)
              );
              if (
                selectedDisastersInSurvey.length === selectedDisasters.length
              ) {
                foundCaseNumber = data.case_number;
              }
            }
          });

          setCaseNumber(foundCaseNumber);
        } catch (error) {
          console.error("Error fetching case number:", error);
          setCaseNumber("");
        }
      } else {
        setCaseNumber("");
      }
    };

    fetchCaseNumber();
  }, [auth.currentUser?.uid, selectedDisasters]);

  const tabs = ["Dashboard", "Details", "Affected County"];
  const handleTabClick = (tab) => {
    setActiveDisasterTab(tab);
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-bold text-blue-500">Loading...</div>
      </div>
    );
  }

  if (showBlur) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
          <div className="flex w-full flex-col items-center justify-center gap-2 space-x-4">
            <p className="text-[40px] font-bold text-[#348BFF]">
              This section will be activated when a disaster is declared
            </p>
            <button
              onClick={handleGoBack}
              className="mx-auto flex w-24 cursor-pointer items-center justify-center rounded-lg border border-blue-500 bg-white px-4 py-2 text-center text-blue-500"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start  pt-[12px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div className="mb-4 flex w-full flex-row items-center justify-between px-2.5">
        <div className="mt-3 flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <a className="relative inline-block min-w-[80px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Disaster Management
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[55px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            <p className="flex items-center justify-center">
              <span className="truncate">
                {selectedDisasters.length > 0
                  ? disasterOptions.find(
                      (disaster) => disaster.id === selectedDisasters[0]
                    )?.name || "Select a Disaster"
                  : "Select a Disaster"}
              </span>
            </p>
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[120px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            {activeDisasterTab}
          </a>
        </div>
        <div className="disaster-name text-xl text-dodgerblue font-bold">
          {selectedDisasters.length > 0
            ? disasterOptions.find(
                (disaster) => disaster.id === selectedDisasters[0]
              )?.name || "Select a Disaster"
            : "Select a Disaster"}
        </div>
      </div>
      <div className="flex max-w-full flex-row items-center justify-between gap-5 self-stretch pb-6 mq1600:flex-wrap px-2.5">
        <div className="flex flex-row items-start justify-start gap-1.5 text-xs text-darkslateblue">
          <Link
            to="/disasterdeclaration"
            className="text-[#348BFF] hover:cursor-pointer flex items-center text-sm font-500"
          >
            <i className="fas fa-arrow-left mr-2"></i>
            Back to list
          </Link>
        </div>
        <div className="flex flex-wrap gap-2 text-xs">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`rounded-full px-4 py-1 ${
                activeDisasterTab === tab
                  ? "btn-default"
                  : "btn-default-border hover:bg-blue-100"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div className="relative inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200"></div>
      <div className="h-[calc(100vh-170px)] w-full overflow-y-auto">
        {activeDisasterTab === "Dashboard" && (
          <DisasterDashboard propId={selectedDisasters} />
        )}
        {activeDisasterTab === "Details" && (
          <DisasterDetail propId={selectedDisasters} />
        )}
        {activeDisasterTab === "Affected County" && (
          <DisasterAffectedCounty propId={selectedDisasters} />
        )}
      </div>
    </div>
  );
};

DisasterDetail.propTypes = {
  className: PropTypes.string,
};

export default DisasterManagementList;
