import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Agenda,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  CheckBoxComponent,
  SwitchComponent,
} from "@syncfusion/ej2-react-buttons";
import {
  addDoc,
  collection,
  updateDoc,
  where,
  query,
  getDocs,
  doc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import citizenProfileService from "../service/citizen/CitizenProfile";
import { db } from "../firebase";
import { extend } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

const COLLECTION_ENUM = {
  CALENDAR_EVENTS: "calendar-events",
};

const EVENTS_THEME = {
  center: "#38b000",
  partner: "#f1ba0a",
  citizen: "#0077b6",
};

const Calendar = () => {
  const schedRef = useRef(null);
  const cellClickCount = useRef(0);
  const cellClickCountTimer = useRef(null);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const modalType = useRef(null);
  const newSchedData = useRef({
    id: null,
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    is_all_day: false,
    location: "",
    reminders: "",
    private: true,
  });

  const [userData, setUserData] = useState({});
  const [schedDataSource, setSchedDataSource] = useState([]);
  const [remindersData, setRemindersData] = useState([
    {
      label: "1 hour before event",
      value: "one_hour_before_event",
    },
    {
      label: "30 mins before event",
      value: "thirty_minutes_before_event",
    },
    {
      label: "15 mins before event",
      value: "fifteen_minutes_before_event",
    },
  ]);

  const dropdownFields = { text: "label", value: "value" };

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const dialogTargetRef = useRef(null);

  const createYesterdayView = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    return currentDate;
  };

  const onCloseQi = (isDelay) => {
    if (isDelay) {
      setTimeout(() => {
        schedRef.current.closeQuickInfoPopup();
      }, 1000);
    } else {
      schedRef.current.closeQuickInfoPopup();
    }
  };

  const onSchedDataToDefault = () => {
    newSchedData.current.id = null;
    newSchedData.current.title = "";
    newSchedData.current.description = "";
    newSchedData.current.start_date = "";
    newSchedData.current.end_date = "";
    newSchedData.current.start_time = "";
    newSchedData.current.end_time = "";
    newSchedData.current.is_all_day = false;
    newSchedData.current.location = "";
    newSchedData.current.reminders = "";
    newSchedData.current.private = true;
  };

  const onChangeProps = (key) => (e) => {
    if (key === "start_time" || key === "end_time") {
      const time = new Date(e.target.value);
      time.setDate(
        new Date(
          newSchedData.current[key === "start_time" ? "start_date" : "end_date"]
        ).getDate() || new Date().getDate()
      );
      time.setFullYear(
        new Date(
          newSchedData.current[key === "start_time" ? "start_date" : "end_date"]
        ).getFullYear() || new Date().getFullYear()
      );
      time.setMonth(
        new Date(
          newSchedData.current[key === "start_time" ? "start_date" : "end_date"]
        ).getMonth() || new Date().getMonth()
      );

      if (key === "end_time" && newSchedData.current.end_date) {
        newSchedData.current.end_date.setTime(time.getTime());
      } else if (key === "start_time" && newSchedData.current.start_date) {
        newSchedData.current.start_date.setTime(time.getTime());
      }

      newSchedData.current[key] = time;
      return;
    }

    if (key === "end_date") {
      const date = new Date(e.target.value);
      if (newSchedData.current.end_time) {
        date.setHours(
          new Date(newSchedData.current.end_time).getHours(),
          new Date(newSchedData.current.end_time).getMinutes(),
          0,
          0
        );
        newSchedData.current?.end_time?.setDate(date.getDate());
      }
      newSchedData.current[key] = date;
      return;
    }

    if (key === "start_date") {
      const date = new Date(e.target.value);
      if (newSchedData.current.start_time) {
        date.setHours(
          new Date(newSchedData.current.start_time).getHours(),
          new Date(newSchedData.current.start_time).getMinutes(),
          0,
          0
        );
        newSchedData.current?.start_time?.setDate(date.getDate());
      }
      newSchedData.current[key] = date;
      return;
    }

    newSchedData.current[key] = e.target.value;
  };

  const schedContent = useCallback(
    ({ start_time, user_id, is_all_day }) => {
      const { id } = userData;

      const cellDate = new Date(start_time);

      if (schedRef.current.currentView !== "Month") {
        cellDate.setDate(cellDate.getDate() - 1);
      }

      onChangeProps("start_date")({ target: { value: start_time } });
      onChangeProps("start_time")({ target: { value: start_time } });

      const defaultEndDate = new Date(start_time);
      defaultEndDate.setDate(defaultEndDate.getDate() + 1);
      onChangeProps("end_date")({ target: { value: defaultEndDate } });

      if (!newSchedData.current.end_time) {
        onChangeProps("end_time")({
          target: {
            value: new Date().setHours(
              new Date(newSchedData.current.start_time).getHours() + 1,
              new Date(newSchedData.current.start_time).getMinutes(),
              0,
              0
            ),
          },
        });
      }

      const disabledDates = (event) => {
        return (event.isDisabled = event.date < cellDate);
      };

      const isOwner =
        modalType.current === "add"
          ? true
          : user_id === id && modalType.current === "edit";

      return (
        <div
          className={`flex flex-col space-y-4 ${
            modalType.current === "edit" && "mr-3 mt-5"
          }`}
        >
          <div className="flex flex-col space-y-2">
            <div>
              <TextBoxComponent
                placeholder="Title *"
                floatLabelType="Auto"
                cssClass="e-outline"
                maxLength="255"
                value={newSchedData.current.title}
                readonly={!isOwner}
                onChange={(e) => {
                  onChangeProps("title")(e);
                  if (errorMessage) setErrorMessage("");
                }}
              />
            </div>
            <div className="flex space-x-2">
              <DatePickerComponent
                cssClass="e-outline custom-date-picker"
                placeholder="Start Date"
                floatLabelType="Auto"
                value={newSchedData.current.start_date || start_time}
                readonly={!isOwner}
                onChange={onChangeProps("start_date")}
              />
              <DatePickerComponent
                cssClass="e-outline custom-date-picker"
                placeholder="End Date"
                floatLabelType="Auto"
                value={newSchedData.current.end_date}
                renderDayCell={disabledDates}
                readonly={!isOwner}
                onChange={onChangeProps("end_date")}
              />
            </div>
            <div className="flex space-x-2">
              <TimePickerComponent
                ref={startTimeRef}
                cssClass="e-outline"
                placeholder="Start Time"
                floatLabelType="Auto"
                value={newSchedData.current.start_time}
                readonly={is_all_day || !isOwner}
                onChange={(e) => {
                  onChangeProps("start_time")(e);
                  const endTimeMin = new Date(e.target.value);
                  const endTimeValue = new Date(e.target.value);
                  endTimeMin.setMinutes(endTimeMin.getMinutes() + 30);
                  endTimeRef.current.min = endTimeMin;
                  endTimeValue.setHours(endTimeValue.getHours() + 1);
                  endTimeRef.current.value = endTimeValue;
                  onChangeProps("end_time")({
                    target: { value: endTimeValue },
                  });
                  endTimeRef.current.refresh();
                }}
              />
              <TimePickerComponent
                ref={endTimeRef}
                cssClass="e-outline"
                placeholder="End Time"
                floatLabelType="Auto"
                value={newSchedData.current.end_time}
                readonly={is_all_day || !isOwner}
                onChange={onChangeProps("end_time")}
              />
            </div>
            <div className="py-2">
              <CheckBoxComponent
                checked={newSchedData.current.is_all_day}
                label="All day"
                disabled={!isOwner}
                change={(e) => {
                  const allDayStartTime = new Date();
                  const allDayEndTime = new Date();
                  allDayStartTime.setHours(0, 0, 0, 0);
                  allDayEndTime.setHours(23, 30, 0, 0);

                  onChangeProps("is_all_day")({
                    target: { value: e.checked },
                  });

                  if (e.checked) {
                    startTimeRef.current.value = allDayStartTime;
                    endTimeRef.current.value = allDayEndTime;

                    onChangeProps("start_time")({
                      target: { value: allDayStartTime },
                    });
                    onChangeProps("end_time")({
                      target: { value: allDayEndTime },
                    });

                    startTimeRef.current.readonly = true;
                    endTimeRef.current.readonly = true;
                  } else {
                    startTimeRef.current.readonly = false;
                    endTimeRef.current.readonly = false;
                  }

                  startTimeRef.current.refresh();
                  endTimeRef.current.refresh();
                }}
              />
            </div>
            <div className="flex flex-col">
              <TextBoxComponent
                placeholder="Location"
                floatLabelType="Auto"
                cssClass="e-outline"
                maxLength="255"
                value={newSchedData.current.location}
                readonly={!isOwner}
                onChange={onChangeProps("location")}
              />
            </div>
            <div className="flex flex-col">
              <DropDownListComponent
                cssClass="e-outline"
                placeholder="Reminder"
                floatLabelType="Auto"
                fields={dropdownFields}
                dataSource={remindersData}
                value={newSchedData.current.reminders}
                readonly={!isOwner}
                onChange={(e) => {
                  onChangeProps("reminders")({ target: { value: e.value } });
                }}
              />
            </div>
            <div className="flex flex-col">
              <TextAreaComponent
                placeholder="Description *"
                floatLabelType="Auto"
                cssClass="e-outline"
                maxLength="255"
                className="h-28 max-h-40"
                value={newSchedData.current.description}
                readonly={!isOwner}
                onChange={(e) => {
                  onChangeProps("description")(e);
                  if (errorMessage) setErrorMessage("");
                }}
              />
            </div>
          </div>
          <div className="flex space-x-3">
            <p className="text-[12px] text-[#828282]">Private</p>
            <SwitchComponent
              checked={newSchedData.current.private}
              disabled={!isOwner}
              onChange={(e) =>
                onChangeProps("private")({
                  target: { value: e.target.checked },
                })
              }
            />
          </div>
          {modalType.current === "edit" && isOwner && (
            <div className="mb-5 flex w-full justify-between space-x-2">
              <button
                onClick={onDeleteCalendarEvents}
                className="cursor-pointer rounded-md border border-solid border-red-500 bg-white px-3 py-2 text-red-500"
              >
                Delete
              </button>
              <div className="flex space-x-2">
                <button
                  onClick={() => onCloseQi(false)}
                  className="cursor-pointer btn-cancel px-3 py-2 "
                >
                  Cancel
                </button>
                <button
                    onClick={onUpdateCalendarEvent}
                    className="cursor-pointer btn-default px-3 py-2"
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      );
    },
    [modalType, schedDataSource, userData, newSchedData, errorMessage]
  );

  const onSaveEvent = useCallback(async () => {
    const {
      id: fid,
      private: fprivate,
      is_all_day: f_is_all_day,
      ...fRest
    } = newSchedData.current;

    if (!newSchedData.current.title || !newSchedData.current.description) {
      setErrorMessage("Title and Description are required fields");
      setIsErrorDialogOpen(true);
      return;
    }

    setErrorMessage("");

    if (Object.values(fRest).some((val) => !val)) {
      return;
    }
    const { id, start_date, end_date, start_time, end_time, ...rest } =
      newSchedData.current;
    const { center_id, id: user_id, user_role } = userData || {};
    await addDoc(collection(db, COLLECTION_ENUM.CALENDAR_EVENTS), {
      ...rest,
      start_date: new Date(start_date).toISOString(),
      end_date: new Date(end_date).toISOString(),
      start_time: new Date(start_time).toISOString(),
      end_time: new Date(end_time).toISOString(),
      center_id: !center_id
        ? []
        : typeof center_id === "object"
        ? [...center_id]
        : [center_id],
      user_id,
      is_deleted: false,
      user_type: user_role,
      theme: EVENTS_THEME[user_role],
    });

    onGetCalendarsEvent(user_id, user_role);

    onCloseQi();
  }, [userData]);

  const onUpdateCalendarEvent = useCallback(async () => {
    const { id: user_id, user_role } = userData || {};
    const {
      id: docId,
      start_date,
      end_date,
      start_time,
      end_time,
      ...rest
    } = newSchedData.current;
    const docRef = doc(db, COLLECTION_ENUM.CALENDAR_EVENTS, docId);
    try {
      await updateDoc(docRef, {
        ...rest,
        start_date: new Date(start_date).toISOString(),
        end_date: new Date(end_date).toISOString(),
        start_time: new Date(start_time).toISOString(),
        end_time: new Date(end_time).toISOString(),
      });

      onGetCalendarsEvent(user_id, user_role);
      onCloseQi();
    } catch (error) {
      console.log(error);
    }
  }, [userData]);

  const onDeleteCalendarEvents = useCallback(async () => {
    const { id: user_id, user_role } = userData || {};
    const { id: docId } = newSchedData.current;
    const docRef = doc(db, COLLECTION_ENUM.CALENDAR_EVENTS, docId);
    try {
      await updateDoc(docRef, {
        is_deleted: true,
      });

      onGetCalendarsEvent(user_id, user_role);
      onCloseQi();
    } catch (error) {
      console.log(error);
    }
  }, [userData]);

  const onGetUserData = async (id, cb) => {
    let collectionName = "users";
    if (id.user_role === "center") {
      collectionName = "centers";
    } else if (id.user_role === "partner") {
      collectionName = "partners";
    } else {
      collectionName = "users";
    }
    const userData = await citizenProfileService.getCitizenProfile(
      collectionName,
      id
    );
    cb?.(userData);
  };

  const onGetCalendarsEvent = async (id, role) => {
    if (!id) return;
    const eventsQuery = query(
      collection(db, COLLECTION_ENUM.CALENDAR_EVENTS),
      where("user_id", "==", id),
      where("is_deleted", "==", false)
    );

    const eventsQueryPublic = query(
      collection(db, COLLECTION_ENUM.CALENDAR_EVENTS),
      where("is_deleted", "==", false),
      where("private", "==", false)
    );

    const [eq, eqp] = await Promise.all([
      getDocs(eventsQuery),
      getDocs(eventsQueryPublic),
    ]);

    const formattedEQ = eq.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    let formattedEQP = [];

    if (role === "center") {
      formattedEQP = eqp.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .filter(
          (doc) =>
            doc.user_id !== id &&
            !!doc.center_id.find((cid) => cid === id) &&
            doc.user_type !== "citizen"
        );
    } else if (role === "partner") {
      formattedEQP = eqp.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .filter(
          (doc) =>
            doc.user_id !== id &&
            !!doc.center_id.find((cid) => cid === doc.user_id)
        );
    } else if (role === "citizen") {
      formattedEQP = eqp.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .filter(
          (doc) =>
            (doc.user_id !== id &&
              !!doc.center_id.find((cid) => cid === doc.user_id)) ||
            (doc.user_type === "partner" &&
              !!doc.center_id.find(
                (cid) => cid === doc.center_id.find((pcid) => pcid === cid)
              ))
        );
    }

    const formattedResponse = [...formattedEQ, ...formattedEQP].map((doc) => {
      const { start_time, end_time, start_date, end_date, ...rest } = doc;
      return {
        ...rest,
        start_time: new Date(start_time),
        end_time: new Date(end_time),
        start_date: new Date(start_date),
        end_date: new Date(end_date),
      };
    });

    setSchedDataSource(() => [...formattedResponse]);
  };

  const schedFooter = () => (
    <div className="mb-5 flex w-full justify-end space-x-2 pr-[13px]">
      <button
        onClick={() => onCloseQi(false)}
        className="cursor-pointer btn-cancel px-3 py-2"
      >
        Cancel
      </button>
      <button
        onClick={onSaveEvent}
        className="cursor-pointer btn-default px-3 py-2"
      >
        Save
      </button>
    </div>
  );

  const eventTemplate = useCallback(({ title, theme }) => {
    return (
      <div style={{ backgroundColor: theme || "#3F51B5" }}>
        <p>{title}</p>
      </div>
    );
  }, []);

  const onCellClick = (args) => {
    cellClickCount.current++;
    if (cellClickCount.current === 1) {
      cellClickCountTimer.current = setTimeout(() => {
        cellClickCount.current = 0;
      }, 200);
      args.cancel = true;
    } else if (cellClickCount.current === 2) {
      args.cancel = false;
      clearTimeout(cellClickCountTimer.current);
      cellClickCount.current = 0;
    }
  };

  const onPopupOpen = (args) => {
    if (args.type === "Editor") {
      args.cancel = true;
    }
  };

  const closeErrorDialog = () => {
    setIsErrorDialogOpen(false);
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        onGetUserData(currentUser.uid, (data) => {
          setUserData(() => data);
          const role = data.user_role;
          onGetCalendarsEvent(currentUser.uid, role);
          // console.log("User data: ", data);
        });
      } else {
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="relative flex h-full min-h-[94vh] flex-col space-y-2 py-5 pl-3 pr-5">
      {/* Legend */}
        <div className="mb-4 flex flex-row items-center justify-between w-full">
              <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
                  <a className="relative inline-block min-w-[80px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
                    Calendar
                  </a>
              </div>

              {/* Legend */}
              <div className="flex items-center space-x-4 text-xs">
                <div className="flex items-center">
                  <span className="w-6 h-6 rounded-full bg-green-500 mr-1 flex items-center justify-center text-white font-bold">C</span>
                  <span>Center</span>
                </div>
                <div className="flex items-center">
                  <span className="w-6 h-6 rounded-full bg-yellow-500 mr-1 flex items-center justify-center text-white font-bold">P</span>
                  <span>Partner</span>
                </div>
                <div className="flex items-center">
                  <span className="w-6 h-6 rounded-full bg-blue-500 mr-1 flex items-center justify-center text-white font-bold">S</span>
                  <span>Survivor</span>
                </div>
              </div>
            </div>
      <ScheduleComponent
        ref={schedRef}
        height="90vh"
        currentView="Month"
        eventSettings={{
          dataSource: schedDataSource,
          fields: {
            id: { name: "id" },
            subject: { name: "title" },
            start_date: { name: "start_date" },
            end_date: { name: "end_date" },
            startTime: { name: "start_time" },
            endTime: { name: "end_time" },
            description: { name: "description" },
            is_all_day: { name: "is_all_day" },
            location: { name: "location" },
            reminders: { name: "reminders" },
            theme: { name: "theme" },
          },
          template: eventTemplate,
        }}
        navigationComplete={(args) => {
            if (args.currentView === 'Yesterday') {
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              schedRef.current.selectedDate = yesterday;
            }
        }}
        enableAllDayScroll
        cellClick={(event) => {
          modalType.current = "add";
          onSchedDataToDefault();
          onCellClick(event);
        }}
        eventClick={({ event }) => {
          modalType.current = "edit";
          newSchedData.current.id = event.id;
          newSchedData.current.title = event.title;
          newSchedData.current.description = event.description;
          newSchedData.current.start_date = event.start_date;
          newSchedData.current.end_date = event.end_date;
          newSchedData.current.start_time = event.start_time;
          newSchedData.current.end_time = event.end_time;
          newSchedData.current.is_all_day = event.is_all_day;
          newSchedData.current.location = event.location;
          newSchedData.current.reminders = event.reminders;
          newSchedData.current.private = event.private;
          onCellClick(event);
        }}
        popupOpen={onPopupOpen}
        quickInfoTemplates={{
          header: ({ user_id }) => {
            const { id } = userData;
            const isOwner = user_id === id && modalType.current === "edit";
            return (
              <div className="bg-blue p-3">
                <label>
                  {modalType.current === "edit"
                    ? isOwner
                      ? "Edit Event"
                      : "Event Details"
                    : "Add New Event"}
                </label>
              </div>
            );
          },
          content: schedContent,
          footer: schedFooter,
        }}
        eventRendered={({ data, element }) => {
          element.style.backgroundColor = data.theme;
          element.style.color = "white";
        }}
      >
        <ViewsDirective>
          <ViewDirective
            option="Day"
            displayName="Yesterday"
            showWeekend={true}
            startHour="00:00"
            endHour="23:59"
            workDays={[0, 1, 2, 3, 4, 5, 6]}
            dateFormat="dd-MMM-yyyy"
          />
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
          <ViewDirective option="Month" />
          <ViewDirective option="Agenda" displayName="Events" />
        </ViewsDirective>
        <Inject services={[Day, Week, Month, Agenda]} />
      </ScheduleComponent>

      <DialogComponent
        visible={isErrorDialogOpen}
        header='Error'
        content={errorMessage}
        showCloseIcon={true}
        closeOnEscape={true}
        width='250px'
        target={dialogTargetRef.current}
        cssClass="popup-error-message"
        buttons={[
          {
            click: closeErrorDialog,
            buttonModel: { content: 'OK', isPrimary: true }
          }
        ]}
        close={closeErrorDialog}
      />
    </div>
  );
};

export default Calendar;
