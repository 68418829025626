import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext";
import Profile from "./Profile";
import Password from "./Password";
import Pin from "./Pin";
import ShowPin from "./ShowPin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const Menu = ({ isOpen, onClose, initialData }) => {
  const [userData, setUserData] = useState(initialData || {});
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [pinShow, setPinShow] = useState(false);
  const menuRef = useRef(null);
  const { logout, role, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [hasOrganizations, setHasOrganizations] = useState(false);
  const [organizations, setOrganizations] = useState(null);
  const [showOrgs, setShowOrgs] = useState(false);
  const { login } = useAuth();

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            if (role === "center") {
              const centerDocRef = doc(db, "centers", userData.center_id);
              console.log("Center");
              const centerDocSnap = await getDoc(centerDocRef);
              if (centerDocSnap.exists()) {
                setUserData(centerDocSnap.data());

                console.log("Center data:", centerDocSnap.data());
              }
            } else if (role === "partner") {
              const partnerDocRef = doc(db, "partners", userData.partner_id);
              const partnerDocSnap = await getDoc(partnerDocRef);
              if (partnerDocSnap.exists()) {
                setUserData(partnerDocSnap.data());

                console.log("Partner data:", partnerDocSnap.data());
              }
            } else {
              setUserData(userData);
              console.log("User data:", userData);
              setUserRole(userData.user_role);

              fetchUserOrganizations(user);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchUserOrganizations = async (user) => {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userDocData = userDocSnap.data();
        if (userDocData) {
          console.log("User exists");
          const organizationsCollectionRef = collection(
            db,
            "users",
            user.uid,
            "organizations"
          );
          const organizationsCollectionSnap = await getDocs(
            organizationsCollectionRef
          );
          //
          if (!organizationsCollectionSnap.empty) {
            setHasOrganizations(true);
            const orgDocs = organizationsCollectionSnap.docs.map((doc) =>
              doc.data()
            );
            setOrganizations(orgDocs);
            console.log("Organizations:", orgDocs);
          } else {
            console.log("User does not have organizations");
          }
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen || showProfile || showPassword || showPin || pinShow) {
      const fetchUserData = async () => {
        const auth = getAuth();
        const db = getFirestore();
        const user = auth.currentUser;

        if (user) {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              if (userData.user_role === "center") {
                const centerDocRef = doc(db, "centers", userData.center_id);
                const centerDocSnap = await getDoc(centerDocRef);
                if (centerDocSnap.exists()) {
                  setUserData(centerDocSnap.data());
                }
              } else if (userData.user_role === "partner") {
                const partnerDocRef = doc(db, "partners", userData.partner_id);
                const partnerDocSnap = await getDoc(partnerDocRef);
                if (partnerDocSnap.exists()) {
                  setUserData(partnerDocSnap.data());
                }
              } else {
                setUserData(userData);
              }
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        }
      };

      fetchUserData();
    }
  }, [isOpen, showProfile, showPassword, showPin, pinShow]);

  if (!isOpen && !showProfile && !showPassword && !showPin && !pinShow)
    return null;

  const handleProfileUpdate = (updatedData) => {
    setUserData(updatedData);
  };

  const handleProfileClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowProfile(true), 100); // Open the Profile popup after a short delay
  };

  const handlePasswordClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowPassword(true), 100); // Open the Password popup after a short delay
  };

  const handlePinShow = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setPinShow(true), 100); // Open the Pin popup after a short delay
  };

  const handlePinClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowPin(true), 100); // Open the Pin popup after a short delay
  };

  const logoutClicked = () => {
    logout();
    navigate("/login");
    onClose(); // Close the menu after logging out
  };

  const switchCenterCitizenPov = () => {
    if (role == "center") {
      setRoleBasedRoute("citizen");
    } else {
      setRoleBasedRoute("center");
    }
    navigate("/home");
    onClose();
  };

  const switchPartnerCitizenPov = () => {
    if (role == "partner") {
      setRoleBasedRoute("citizen");
    } else {
      setRoleBasedRoute("partner");
    }
    navigate("/home");
    onClose();
  };

  const switchToAnOrg = async (org) => {
    console.log("Org:", org);
    let password;

    const db = getFirestore();
    const q = query(collection(db, "users"), where("email", "==", org.email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      const userDocSnap = querySnapshot.docs[0];
      const userData = userDocSnap.data();
      console.log("User dataaa:", userData);
      password = userData.password;
    }

    const url = `/home`;
    sessionStorage.setItem(
      "login",
      JSON.stringify({
        email: org.email,
        password: password,
        orgType: org.org_type,
      })
    );
    console.log("url", url);
    window.open(url, "_blank"); // Open a new tab and redirect to login page with credentials
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-start justify-end overflow-auto bg-black bg-opacity-50 pt-14">
          <div
            ref={menuRef}
            className="mr-2 w-[400px] overflow-hidden rounded-b-lg bg-white shadow-xl"
          >
            <div>
              <h2 className="bg-blue px-4 pb-6 pt-6 text-base font-semibold">
                My Profile
              </h2>
            </div>
            <div className="flex items-center justify-center border-b p-4">
              {userData.profile_image ? (
                <img
                  src={userData.profile_image}
                  alt="Profile"
                  className="mr-4 h-16 w-16 rounded-full"
                />
              ) : (
                <div className="user-profile-thumbnail mr-4 flex h-16 w-16 items-center justify-center rounded-full">
                  {userData.first_name
                    ? userData.first_name.charAt(0).toUpperCase()
                    : "?"}
                </div>
              )}
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-lg pb-2 font-semibold text-black">
                  {userData.first_name
                    ? `${userData.first_name} ${userData.last_name || ""}`
                    : "User Name"}
                </h3>
                <p className="text-sm text-gray-800">
                  {userData.email || "user@example.com"}
                </p>
              </div>
            </div>
            <nav className="p-4">
              <ul className="space-y-2">
                <li>
                  <button
                    onClick={handleProfileClick}
                    className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                  >
                    Profile
                  </button>
                </li>
                <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                <li>
                  <button
                    onClick={handlePasswordClick}
                    className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                  >
                    Change Password
                  </button>
                </li>
                <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                {role === "center" && (
                  <li>
                    <button
                      onClick={switchCenterCitizenPov}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Switch to {role == "center" ? "Citizen" : "Center"} POV
                    </button>
                  </li>
                )}
                {role === "center" && <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />}
                
                {role === "partner" && (
                  <li>
                    <button
                      onClick={switchPartnerCitizenPov}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Switch to {role == "partner" ? "Citizen" : "Partner"} POV
                    </button>
                  </li>
                )}
                {role === "partner" && <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />}
                
                {role === "citizen" && hasOrganizations && (
                  <li>
                    <button
                      onClick={() => setShowOrgs(!showOrgs)}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Switch Account
                    </button>
                    {showOrgs && (
                      <ul className="ml-4">
                        {organizations.map((org) => (
                          <li key={org.id}>
                            <button
                              onClick={() => switchToAnOrg(org)}
                              className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                            >
                              {org.org_type === "partner"
                                ? org.name_of_org
                                : org.name_of_hub}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )}
                {role === "citizen" && hasOrganizations && <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />}

                {(role === "citizen" || role === "partner") && (
                  <li>
                    <button
                      onClick={handlePinShow}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Show Lockbox PIN
                    </button>
                  </li>
                )}
                {(role === "citizen" || role === "partner") && <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />}
              </ul>
              <div className="flex items-center justify-center pt-4">
                <button
                  onClick={logoutClicked}
                  className="btn-default block cursor-pointer px-4 py-2 hover:bg-blue-700"
                >
                  Sign Out
                </button>
              </div>
            </nav>
          </div>
        </div>
      )}
      {showProfile && userData && (
        <Profile
          isOpen={showProfile}
          onClose={() => setShowProfile(false)}
          data={userData}
          onUpdate={handleProfileUpdate}
        />
      )}
      {showPassword && (
        <Password
          isOpen={showPassword}
          onClose={() => setShowPassword(false)}
        />
      )}
      {(role === "citizen" || role === "partner") && showPin && (
        <Pin isOpen={showPin} onClose={() => setShowPin(false)} />
      )}
      {(role === "citizen" || role === "partner") && pinShow && (
        <ShowPin
          isOpen={pinShow}
          onClose={() => setPinShow(false)}
          firstTime={false}
        />
      )}
    </>
  );
};

export default Menu;
