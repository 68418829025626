import axios from "axios";
import environment from "./environment";
import getToken from "./token";

const mailer = {
  sendOtpEmail: async (toEmail, otp, firstName) => {
    try {
      const token = await getToken();
      const fname = firstName ? firstName : "";
      const data = JSON.stringify({
        to_email: toEmail,
        otp: otp,
        first_name: fname,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environment.apiUrl}/mailer/send_otp`,
        headers: {
          "X-API-Key": environment.apiKey,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response.status === 200) {
        console.log("OTP sent successfully");
        return response.data;
      } else {
        console.error("Failed to send OTP:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      return null;
    }
  },

  sendAccessEmail: async (
    toEmail,
    firstName,
    masterOtp,
    accessOtp,
    orgName,
    employeeName,
    employeeId,
    employeePhone,
    reason
  ) => {
    try {
      const token = await getToken();
      const fname = firstName ? firstName : "";
      const data = JSON.stringify({
        to_email: toEmail,
        first_name: fname,
        master_otp: masterOtp,
        access_otp: accessOtp,
        organization_name: orgName,
        employee_name: employeeName,
        employee_id: employeeId,
        employee_phone: employeePhone,
        reason_for_access: reason,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environment.apiUrl}/mailer/send_access_otp`,
        headers: {
          "X-API-Key": environment.apiKey,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response.status === 200) {
        console.log("OTP sent successfully");
        return response.data;
      } else {
        console.error("Failed to send OTP:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      return null;
    }
  },

  sendOtpDuplicateEmail: async (toEmail, otp, firstName, link) => {
    try {
      const token = await getToken();
      const fname = firstName ? firstName : "";
      const data = JSON.stringify({
        to_email: toEmail,
        otp: otp,
        first_name: fname,
        link: link,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environment.apiUrl}/mailer/send_otp_duplicate`,
        headers: {
          "X-API-Key": environment.apiKey,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response.status === 200) {
        console.log("Email sent successfully");
        return response.data;
      } else {
        console.error("Failed to send email:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error sending email:", error);
      return null;
    }
  },

  sendForgotPasswordEmail: async (toEmail, otp, firstName) => {
    try {
      const token = await getToken();
      const fname = firstName ? firstName : "";
      const data = JSON.stringify({
        to_email: toEmail,
        otp: otp,
        first_name: fname,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environment.apiUrl}/mailer/send_forgot_password_link`,
        headers: {
          "X-API-Key": environment.apiKey,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response.status === 200) {
        console.log("Email sent successfully");
        return response.data;
      } else {
        console.error("Failed to send email:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error sending email:", error);
      return null;
    }
  },

  sendHouseholdMemberInviteEmail: async (
    toEmail,
    firstName,
    link,
    senderName
  ) => {
    try {
      const token = await getToken();
      const fname = firstName ? firstName : "";
      const data = JSON.stringify({
        to_email: toEmail,
        first_name: fname,
        link: link,
        sender_name: senderName,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environment.apiUrl}/mailer/send_household_member_invite`,
        headers: {
          "X-API-Key": environment.apiKey,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response.status === 200) {
        console.log("Email sent successfully");
        return response.data;
      } else {
        console.error("Failed to send email:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error sending email:", error);
      return null;
    }
  },

  sendFriendInviteEmail: async (toEmail, firstName, link, senderName) => {
    try {
      const token = await getToken();
      const fname = firstName ? firstName : "";
      const data = JSON.stringify({
        to_email: toEmail,
        first_name: fname,
        link: link,
        sender_name: senderName,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environment.apiUrl}/mailer/send_friend_invite`,
        headers: {
          "X-API-Key": environment.apiKey,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response.status === 200) {
        console.log("Email sent successfully");
        return response.data;
      } else {
        console.error("Failed to send email:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error sending email:", error);
      return null;
    }
  },
};

export default mailer;
