import React, { useState, useEffect, useRef } from "react";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, getDocs, collection, addDoc,serverTimestamp, query, orderBy, onSnapshot, writeBatch, updateDoc, where, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { format } from 'date-fns';
import { MultiSelectComponent, CheckBoxSelection, Inject } from "@syncfusion/ej2-react-dropdowns";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { USStates } from "../datasource";
import countiesData from "../../counties_list.json"
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

import {
    HtmlEditor,
    Image,
    
    Link,
    QuickToolbar,
    RichTextEditorComponent,
    Toolbar,
  } from "@syncfusion/ej2-react-richtexteditor";


  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "|",
      "Undo",
      "Redo",
    ],
  };

  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
  };

const MessageCenter = () => {
  const [editorContent, setEditorContent] = useState("");
  const [userData, setUserData] = useState({});
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  const [selectedRecipient, setSelectedRecipient] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [counties, setCounties] = useState([]);
  const [subject, setSubject] = useState("");
  const [urgency, setUrgency] = useState("general");
  const [isUpdating, setIsUpdating] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
const [validationErrors, setValidationErrors] = useState({
    messageType: false,
    recipient: false,
    subject: false,
    content: false
});
  const handleMessageTypeChange = (e) => {
    setMessageType(e.value || '');
    setSelectedRecipient([]); 
};

const handleCancel = () => {
    setSubject('');
    setEditorContent('');
    setMessageType('');
    setSelectedRecipient([]); 
    setSelectedState('');
    setCounties([]);
    setUrgency('general');
    
    setValidationErrors({
        messageType: false,
        recipient: false,
        subject: false,
        content: false
    });
    
    setIsModalOpen(false);

    if (userData?.state) {
        const stateObject = USStates.find(
            (state) => state.abbreviation === userData.state
        );
        const stateName = stateObject ? stateObject.name : "";
        
        const filteredCounties = countiesData
            .filter(county => county.State === stateName)
            .map(county => ({ County: county.County }));
            
        setCounties(filteredCounties);
    }
};



useEffect(() => {
  const loadRecipients = async () => {
      if (!messageType) return;
      
      const db = getFirestore();
      try {
          let collectionName;
          let transformFunction;
          
          switch(messageType) {
                case 'county':
                return;
                
                case 'disaster':
                    collectionName = 'declared-disaster';
                    const querySnapshot = await getDocs(collection(db, collectionName));
                    
                    const userStateObj = USStates.find(state => state.abbreviation === userData?.state);
                    const userStateName = userStateObj ? userStateObj.name : '';
                    
                    console.log('User State (full):', userStateName);
                    
                    let recipientsList = querySnapshot.docs
                        .map(doc => ({
                            id: doc.id,
                            disaster_name: doc.data().disaster_name,
                            status: doc.data().disaster_status,
                            disaster_type: doc.data().disaster_type,
                            county: doc.data().disaster_county,
                            state: doc.data().disaster_state
                        }))
                        .filter(disaster => 
                            disaster.status === "active" && 
                            disaster.state === userStateName 
                        );
                    
                    setRecipients(recipientsList);
                return;

                case 'partner':
                    collectionName = 'users';
                    const auth = getAuth();
                    const currentUser = auth.currentUser;
                    try {
                        if (currentUser) {
                            const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                            const userData = userDoc.data();
                            const userCenterId = userData?.center_id; 
                
                            if (!userCenterId) {
                                console.log('No center_id found for current user');
                                setRecipients([]);
                                return;
                            }

                            const querySnapshot = await getDocs(collection(db, collectionName));
                            let recipientsList = querySnapshot.docs
                                .map(doc => {
                                    const data = doc.data();
                                    if (!data) return null;

                                    if (data.user_role === 'partner' && 
                                        data.center_id && 
                                        data.center_id.includes(userCenterId)) {
                                        return {
                                            id: doc.id || '',
                                            name: `${data.first_name || ''} ${data.last_name || ''}`.trim() || 'Unknown',
                                            email: data.email || '',
                                            organization: data.name_of_org || 'No Organization'
                                        };
                                    }
                                    return null;
                                })
                                .filter(item => item !== null);
                
                            setRecipients(recipientsList);
                                } else {
                                    console.log('No current user found');
                                    setRecipients([]);
                                }
                            } catch (error) {
                                console.error("Error in partner case:", error);
                                setRecipients([]);
                            }
                            break;
                    default:
            return;
          }
          
          if (messageType === 'partner') {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            try {
                if (currentUser) {
                    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                    const userData = userDoc.data();
                    const userCenterId = userData?.center_id;
        
                    if (!userCenterId) {
                        console.log('No center_id found for current user');
                        setRecipients([]);
                        return;
                    }
        
                    const querySnapshot = await getDocs(collection(db, collectionName));
                    let recipientsList = querySnapshot.docs
                        .map(doc => {
                            const data = doc.data();
                            if (!data) return null;
                            
                            if (data.user_role === 'partner' && 
                                data.center_id && 
                                data.center_id.includes(userCenterId)) {
                                return {
                                    id: doc.id || '',
                                    name: `${data.first_name || ''} ${data.last_name || ''}`.trim() || 'Unknown',
                                    email: data.email || '',
                                    organization: data.name_of_org || 'No Organization'
                                };
                            }
                            return null;
                        })
                        .filter(item => item !== null);
        
                    setRecipients(recipientsList);
                } else {
                    console.log('No current user found');
                    setRecipients([]);
                }
            } catch (error) {
                console.error("Error in partner case:", error);
                setRecipients([]);
            }
        }
    } catch (error) {
        console.error("Error loading recipients:", error);
    }
};

    loadRecipients();
    }, [messageType, userData?.state]);

    useEffect(() => {
        const fetchUserData = async () => {
            const auth = getAuth();
            const db = getFirestore();
            const user = auth.currentUser;

            if (user) {
                try {
                    const userDocRef = doc(db, "centers", user.uid);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        setUserData(userData);
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const db = getFirestore();
        const q = query(
            collection(db, 'message-center'),
            orderBy('timestamp', 'desc')
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedMessages = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(fetchedMessages);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userData?.state) {
            const stateObject = USStates.find(
                (state) => state.abbreviation === userData.state
            );
            const stateName = stateObject ? stateObject.name : "";
            
            const filteredCounties = countiesData
                .filter(county => county.State === stateName)
                .map(county => ({ County: county.County }));
                
            setCounties(filteredCounties);
        }
    }, [userData]);

    const getUrgencyDetails = (urgency) => {
        switch (urgency) {
            case 'emergency':
                return { letter: 'E', color: 'bg-red-500' };
            case 'warning':
                return { letter: 'W', color: 'bg-orange-500' };
            case 'updates':
                return { letter: 'U', color: 'bg-green-500' };
            default:
                return { letter: 'G', color: 'bg-blue-500' };
        }
    };

    const handleEditorChange = (args) => {
        setEditorContent(args.value);
      };
    
      const handleSend = async () => {
        setValidationErrors({
            messageType: !messageType,
            recipient: messageType === 'state' 
                ? (!selectedState || selectedRecipient.length === 0)
                : !selectedRecipient,
            subject: !subject.trim(),
            content: !editorContent.trim()
        });
    
        if (!messageType || 
            (messageType === 'state' ? (!selectedState || selectedRecipient.length === 0) : !selectedRecipient) ||
            !subject.trim() ||
            !editorContent.trim()) {
            return;
        }
    
        const db = getFirestore();
        const auth = getAuth();
        const user = auth.currentUser;
    
        if (!user) {
            alert("You must be logged in to send messages");
            return;
        }
    
        try {
            const messageId = doc(collection(db, 'message-center')).id;
        
            const messageData = {
                messageId: messageId,
                type: `${messageType}_message`,
                title: subject,
                content: editorContent,
                urgency,
                timestamp: serverTimestamp(),
                read: false,
                global_notification: true,
                sender: {
                    id: user.uid,
                    name: `${userData.first_name || ''} ${userData.last_name || ''}`.trim(),
                    email: user.email,
                    organization: userData.name_of_org || '',
                    role: userData.user_role || ''
                },
                active: true,
                expirationDate: expirationDate ? expirationDate.getTime() : null
            };
    
            if (messageType === 'county') {
                messageData.recipients = {
                    counties: selectedRecipient
                };
                messageData.county = selectedRecipient;
            } 
            else if (messageType === 'disaster') {
                const disasterDoc = await getDoc(doc(db, 'declared-disaster', selectedRecipient));
                const disasterData = disasterDoc.data();
                
                messageData.recipients = {
                    id: selectedRecipient,
                    type: messageType,
                    disaster_name: disasterData.disaster_name,
                    disaster_county: disasterData.disaster_county,
                    disaster_state: disasterData.disaster_state
                };
                messageData.disaster_county = disasterData.disaster_county;
                messageData.disaster_state = disasterData.disaster_state;
                messageData.disaster_name = disasterData.disaster_name;
            } 
            else if (messageType === 'partner') {
                const partnerDetails = recipients
                    .filter(partner => selectedRecipient.includes(partner.id))
                    .map(partner => ({
                        id: partner.id,
                        email: partner.email,
                        name: partner.name
                    }));
            
                messageData.recipients = {
                    ids: partnerDetails,
                    type: messageType
                };
                messageData.targetedPartners = selectedRecipient; 
            }
    
            await setDoc(doc(db, 'message-center', messageId), messageData);

        const batch = writeBatch(db);
        const createNotificationData = (additionalData = {}) => ({
            ...messageData,
            ...additionalData
        });

        if (messageType === 'county') {
            const usersSnapshot = await getDocs(collection(db, 'users'));
            
            usersSnapshot.forEach((userDoc) => {
                const userData = userDoc.data();
                if (selectedRecipient.includes(userData.county)) {
                    const notificationRef = doc(collection(db, 'notifications', userDoc.id, 'notifications'));
                    batch.set(notificationRef, createNotificationData({
                        county: userData.county
                    }));
                }
            });
        } 
        else if (messageType === 'partner') {
            for (const partnerId of selectedRecipient) {
                const notificationRef = doc(collection(db, 'notifications', partnerId, 'notifications'));
                batch.set(notificationRef, createNotificationData());
            }
        }
        else if (messageType === 'disaster') {
            const disasterDoc = await getDoc(doc(db, 'declared-disaster', selectedRecipient));
            const disasterData = disasterDoc.data();
            
            if (disasterData) {
                const usersSnapshot = await getDocs(collection(db, 'users'));
                
                usersSnapshot.forEach((userDoc) => {
                    const userData = userDoc.data();
                    if (userData.county === disasterData.disaster_county) {
                        const notificationRef = doc(collection(db, 'notifications', userDoc.id, 'notifications'));
                        batch.set(notificationRef, createNotificationData({
                            disaster_county: disasterData.disaster_county,
                            disaster_state: disasterData.disaster_state,
                            disaster_name: disasterData.disaster_name
                        }));
                    }
                });
            }
        }

        await setDoc(doc(db, 'message-center', messageId), messageData);
        
            setSubject('');
            setEditorContent('');
            setMessageType('');
            setSelectedRecipient([]);
            setUrgency('general');
            setIsModalOpen(false);
            setExpirationDate(null);
    
            const dropdownElement = document.querySelector('.e-dropdownlist');
            if (dropdownElement) {
                dropdownElement.ej2_instances[0].text = ''; 
                dropdownElement.ej2_instances[0].value = null;
            }
        } catch (error) {
            console.error("Error sending message:", error);
            alert('Failed to send message. Please try again.');
        }
    };

  return (
    <div className="m-0 box-border flex w-full h-screen">
      {/* Left Panel*/}
      <div className="w-1/3 border-r p-4 flex flex-col">
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-4">Message Center</h2>
          <button 
            onClick={() => setIsModalOpen(true)}
            className="w-full rounded-lg btn-default px-4 py-2 hover:bg-blue-500 cursor-pointer"
          >
            Compose Message
          </button>
        </div>
        
        {/* Inbox Section */}
        <div className="flex-1 overflow-y-auto">
            {messages.map((message) => {
                const urgencyDetails = getUrgencyDetails(message.urgency);
                return (
                    <div 
                        key={message.id}
                        className={`flex items-start gap-4 rounded-lg p-4 mb-2 cursor-pointer 
                            border-[1px] border-solid 
                            ${selectedMessage?.id === message.id 
                                ? 'bg-[#0072C6] text-white border-[#0072C6]' 
                                : 'hover:bg-gray-500 border-silver-100'
                            }`}
                        onClick={() => setSelectedMessage(message)}
                    >
                        {/* Urgency Avatar */}
                        <div className={`flex-shrink-0 w-10 h-10 ${urgencyDetails.color} rounded-full flex items-center justify-center`}>
                            <span className="text-white font-semibold">
                                {urgencyDetails.letter}
                            </span>
                        </div>

                        {/* Message Content */}
                        <div className="flex-1 min-w-0">
                            <div className="flex items-center justify-between">
                                <h3 className="font-semibold truncate">
                                    {message.title}
                                </h3>
                                <span className="text-xs text-gray-400">
                                    {message.timestamp ? 
                                        format(message.timestamp.toDate(), 'MMM d, h:mm a') 
                                        : 'Just now'}
                                </span>
                            </div>
                            <div className="text-sm text-gray-600 flex items-center gap-2">
                                <span className="font-medium">To:</span>
                                <span className="truncate">
                                    {message.recipients?.counties && (
                                        `${message.recipients.counties.length} ${
                                            message.recipients.counties.length === 1 ? 'County' : 'Counties'
                                        }`
                                    )}
                                    {message.recipients?.disaster_name && (
                                        `${message.recipients.disaster_name} - ${message.recipients.disaster_county}`
                                    )}
                                    {message.recipients?.ids && (
                                        <>
                                            {message.recipients.ids.length > 3 
                                                ? `${message.recipients.ids[0].name} +${message.recipients.ids.length - 1} more`
                                                : message.recipients.ids.map(partner => partner.name).join(', ')
                                            }
                                        </>
                                    )}
                                </span>
                            </div>
                            <div className="text-sm text-gray-500 truncate mt-1">
                                {message.content.replace(/<[^>]*>/g, '')}
                            </div>
                        </div>
                    </div>
                );
            })}
            {messages.length === 0 && (
                <div className="text-center text-gray-500 mt-4">
                    No messages yet
                </div>
            )}
        </div>
      </div>

      {/* Right Panel*/}
      <div className="w-2/3 p-4 border-[1px] border-solid border-silver-100 ">
      {selectedMessage ? (
        <div className="h-full flex flex-col rounded-lg border-[1px] border-solid border-silver-100">
            {/* Header*/}
            <div className="bg-[#467BA4] text-[#FDB841] p-4 rounded-t-lg">
                <div className="flex items-center justify-between mb-2">
                    <h2 className="text-xl font-semibold">{selectedMessage.title}</h2>
                    
                    <span className="text-white/80 text-sm">
                        {selectedMessage.timestamp ? 
                            format(selectedMessage.timestamp.toDate(), 'MMM d, yyyy h:mm a') 
                            : 'Just now'}
                    </span>
                </div>
                
        {/* Sender Info */}
        <div className="flex flex-col gap-2 text-sm text-white/90">
            <div className="flex items-start justify-between">
                <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                        <span className="font-medium">From: </span>
                        {selectedMessage.sender.name} ({selectedMessage.sender.organization})
                    </div>
                    <div className="flex items-start gap-2">
                        <span className="font-medium">To:</span>
                            <div className="flex-1">
                                {selectedMessage.recipients?.counties && (
                                    <>
                                        <span>
                                            {`${selectedMessage.recipients.counties.length} ${
                                                selectedMessage.recipients.counties.length === 1 ? 'County' : 'Counties'
                                            }`}
                                        </span>
                                        <div className="inline-flex flex-wrap gap-1 ml-1">
                                            {selectedMessage.recipients.counties.length > 3 ? (
                                                <>
                                                    {selectedMessage.recipients.counties.slice(0, 3).map((county, index) => (
                                                        <span 
                                                            key={index}
                                                            className="inline-flex items-center px-2 py-0.5 bg-gray-50 text-xs rounded border border-gray-200"
                                                        >
                                                            {county}
                                                        </span>
                                                    ))}
                                                    <span className="inline-flex items-center px-2 py-0.5 bg-gray-50 text-xs rounded border border-gray-200">
                                                        +{selectedMessage.recipients.counties.length - 3} more
                                                    </span>
                                                </>
                                            ) : (
                                                selectedMessage.recipients.counties.map((county, index) => (
                                                    <span 
                                                        key={index}
                                                        className="inline-flex items-center px-2 py-0.5 bg-gray-50 text-xs rounded border border-gray-200"
                                                    >
                                                        {county}
                                                    </span>
                                                ))
                                            )}
                                        </div>
                                    </>
                                )}

                                {selectedMessage.type === 'disaster_message' && (
                                    <span>
                                        {`${selectedMessage.recipients.disaster_name} - ${selectedMessage.recipients.disaster_county}`}
                                    </span>
                                )}

                                {selectedMessage.type === 'partner_message' && (
                                    <div className="flex flex-col gap-1">
                                        {selectedMessage.recipients.ids.length > 3 ? (
                                            <>
                                                {selectedMessage.recipients.ids.slice(0, 3).map((partner, index) => (
                                                    <span 
                                                        key={index}
                                                        className="text-sm text-gray-600"
                                                    >
                                                        {partner.email || partner.name} 
                                                    </span>
                                                ))}
                                                <span className="text-sm text-gray-500">
                                                    +{selectedMessage.recipients.ids.length - 3} more partners
                                                </span>
                                            </>
                                        ) : (
                                            selectedMessage.recipients.ids.map((partner, index) => (
                                                <span 
                                                    key={index}
                                                    className="text-sm text-gray-600"
                                                >
                                                    {partner.email || partner.name}  
                                                </span>
                                            ))
                                        )}
                                    </div>
                                )}
                            </div>
                    </div>
                </div>
                {selectedMessage.urgency && (
    <button
        onClick={async () => {
            try {
                setIsUpdating(true); 
                const messageRef = doc(db, 'message-center', selectedMessage.id);
                await updateDoc(messageRef, {
                    active: !selectedMessage.active
                });

                const usersSnapshot = await getDocs(collection(db, 'users'));
                const batch = writeBatch(db);
                const promises = [];
                
                usersSnapshot.forEach((userDoc) => {
                    const notificationsRef = collection(db, 'notifications', userDoc.id, 'notifications');
                    const q = query(notificationsRef, where('id', '==', selectedMessage.id));
                    promises.push(getDocs(q));
                });
                
                const notificationSnapshots = await Promise.all(promises);
                
                notificationSnapshots.forEach(notificationSnapshot => {
                    notificationSnapshot.forEach(notificationDoc => {
                        const notificationRef = doc(
                            db, 
                            'notifications', 
                            notificationDoc.ref.parent.parent.id, 
                            'notifications', 
                            selectedMessage.id
                        );
                        batch.update(notificationRef, { active: !selectedMessage.active });
                    });
                });
                
                await batch.commit();

                setSelectedMessage(prev => ({
                    ...prev,
                    active: !prev.active
                }));
        
            } catch (error) {
                console.error("Error toggling status:", error);
                alert('Failed to update status. Please try again.');
            } finally {
                setIsUpdating(false); 
            }
        }}
        disabled={isUpdating}
        className={`ml-4 px-3 py-1.5 text-sm rounded transition-colors duration-200 flex items-center gap-1.5 whitespace-nowrap cursor-pointer
            ${isUpdating ? 'opacity-75 cursor-not-allowed ' : ''}
            ${selectedMessage.active 
                ? 'bg-red-600 hover:bg-red-700 text-white' 
                : 'bg-green-600 hover:bg-green-700 text-white'}`}
    >
        {isUpdating ? (
            <svg 
                className="w-4 h-4 animate-spin" 
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle 
                    className="opacity-25" 
                    cx="12" 
                    cy="12" 
                    r="10" 
                    stroke="currentColor" 
                    strokeWidth="4"
                />
                <path 
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
            </svg>
        ) : (
            <svg 
                className="w-4 h-4" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
            >
                {selectedMessage.active ? (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                )}
            </svg>
        )}
        {isUpdating ? 'Updating...' : (selectedMessage.active ? 'Off' : 'On')}
    </button>
)}
            </div>
        </div>

                {/* Urgency Badge*/}
                <div className="mt-2">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                        ${selectedMessage.urgency === 'emergency' ? 'bg-red-100 text-red-800' :
                        selectedMessage.urgency === 'warning' ? 'bg-orange-100 text-orange-800' :
                        selectedMessage.urgency === 'updates' ? 'bg-green-100 text-green-800' :
                        'bg-white/10 text-white'}`}>
                        {selectedMessage.urgency.charAt(0).toUpperCase() + selectedMessage.urgency.slice(1)}
                    </span>
                </div>
            </div>

            {/* Message Content */}
            <div className="flex-1 overflow-y-auto p-6">
                <div 
                    className="prose max-w-none"
                    dangerouslySetInnerHTML={{ __html: selectedMessage.content }}
                />
            </div>
        </div>
    ) : (
        <div className="h-full flex items-center justify-center text-gray-500 border-2 border-dashed rounded-lg">
            Select a message to view its contents
        </div>
    )}
    </div>


        {/* Modal */}
        <DialogComponent
            isModal={true}
            visible={isModalOpen}
            close={handleCancel}
            header="New Message"
            closeOnEscape={true}
            showCloseIcon={true}
            target="#root"
            cssClass="message-dialog"
        >
            <div className="flex flex-col h-full">
                {/* Recipient Section */}
                <div className="flex items-center justify-between border-b p-4">
                    <div className="flex items-center flex-1 gap-2">
                        <div className="flex items-center gap-1">
                            To:
                            <span className="text-red-500">*</span>
                        </div>
                        <div className="flex items-center flex-1 gap-2">
                        <div>
                            <DropDownListComponent
                                dataSource={[
                                    { text: "County", value: "county" },
                                    { text: "Disaster", value: "disaster" },
                                    { text: "Partner", value: "partner" }
                                ]}
                                value={messageType || null}
                                change={(e) => {
                                    handleMessageTypeChange(e);
                                    setValidationErrors(prev => ({...prev, messageType: false}));
                                }}
                                cssClass={`e-outline ${validationErrors.messageType ? 'e-error' : ''}`}
                                floatLabelType="Auto"
                                placeholder="Select Recipient Type"
                                fields={{ text: 'text', value: 'value' }}
                                allowReset={true}  
                                showClearButton={true}
                            />
                            {validationErrors.messageType && (
                                <div className="text-red-500 text-xs mt-1">Please select a recipient type</div>
                            )}
                        </div>

                        {messageType === 'county' && counties.length > 0 && (
                            <div className="flex-1">
                                <MultiSelectComponent
                                    id="countySelect"
                                    dataSource={counties}
                                    fields={{ text: "County", value: "County" }}
                                    value={selectedRecipient}
                                    change={(e) => {
                                        setSelectedRecipient(Array.isArray(e.value) ? e.value : []);
                                        setValidationErrors(prev => ({...prev, recipient: false}));
                                    }}
                                    cssClass={`e-outline ${validationErrors.recipient ? 'e-error' : ''}`}
                                    floatLabelType="Auto"
                                    placeholder="Select County"
                                    mode="CheckBox"
                                    selectAllText="Select All"
                                    unSelectAllText="Unselect All"
                                    showSelectAll={true}
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                                {validationErrors.recipient && (
                                    <div className="text-red-500 text-xs mt-1">Please select a county</div>
                                )}
                            </div>
                        )}

                            {messageType && messageType !== 'county' && (
                                <div className="flex-1">
                                    {messageType === 'partner' ? (
                                        <MultiSelectComponent
                                            id="partnerSelect"
                                            dataSource={recipients || []}
                                            fields={{
                                                text: 'name',
                                                value: 'id'
                                            }}
                                            value={selectedRecipient}
                                            change={(e) => {
                                                setSelectedRecipient(Array.isArray(e.value) ? e.value : []);
                                                setValidationErrors(prev => ({...prev, recipient: false}));
                                            }}
                                            cssClass={`e-outline ${validationErrors.recipient ? 'e-error' : ''}`}
                                            floatLabelType="Auto"
                                            placeholder="Select Partners"
                                            mode="CheckBox"
                                            selectAllText="Select All"
                                            unSelectAllText="Unselect All"
                                            showSelectAll={true}
                                        >
                                            <Inject services={[CheckBoxSelection]} />
                                        </MultiSelectComponent>
                                    ) : (
                                        <DropDownListComponent
                                            dataSource={recipients || []}
                                            fields={{
                                                text: 'disaster_name',
                                                value: 'id'
                                            }}
                                            value={selectedRecipient}
                                            change={(e) => {
                                                setSelectedRecipient(e.value);
                                                setValidationErrors(prev => ({...prev, recipient: false}));
                                            }}
                                            cssClass={`e-outline ${validationErrors.recipient ? 'e-error' : ''}`}
                                            floatLabelType="Auto"
                                            placeholder="Select Disaster"
                                        />
                                    )}
                                    {validationErrors.recipient && (
                                        <div className="text-red-500 text-xs mt-1">Please select a recipient</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Urgency Section - remains unchanged */}
                <div className="flex items-center justify-between border-b p-4">
                  <div className="flex items-center gap-4">
                      <span className="font-medium">Urgency:</span>
                      <div className="flex gap-6">
                          <label className="flex items-center gap-2 cursor-pointer">
                              <input
                                  type="radio"
                                  name="urgency"
                                  value="emergency"
                                  checked={urgency === "emergency"}
                                  onChange={(e) => setUrgency(e.target.value)}
                                  className="text-red-500 focus:ring-red-500"
                              />
                              <span className="text-red-500 font-medium">Emergency</span>
                          </label>
                          
                          <label className="flex items-center gap-2 cursor-pointer">
                              <input
                                  type="radio"
                                  name="urgency"
                                  value="warning"
                                  checked={urgency === "warning"}
                                  onChange={(e) => setUrgency(e.target.value)}
                                  className="text-orange-500 focus:ring-orange-500"
                              />
                              <span className="text-orange-500 font-medium">Warning</span>
                          </label>
                          
                          <label className="flex items-center gap-2 cursor-pointer">
                              <input
                                  type="radio"
                                  name="urgency"
                                  value="general"
                                  checked={urgency === "general"}
                                  onChange={(e) => setUrgency(e.target.value)}
                                  className="text-blue-500 focus:ring-blue-500"
                              />
                              <span className="text-blue-500 font-medium">General News</span>
                          </label>
                          
                          <label className="flex items-center gap-2 cursor-pointer">
                              <input
                                  type="radio"
                                  name="urgency"
                                  value="updates"
                                  checked={urgency === "updates"}
                                  onChange={(e) => setUrgency(e.target.value)}
                                  className="text-green-500 focus:ring-green-500"
                              />
                              <span className="text-green-500 font-medium">Updates</span>
                          </label>
                      </div>
                  </div>
              </div>
              
              <div className="flex items-center justify-between border-b p-4">
                <div className="flex items-center gap-4">
                    <span className="font-medium">Expiration Date:</span>
                    <DatePickerComponent
                        value={expirationDate}
                        onChange={(e) => setExpirationDate(e.value)}
                        placeholder="Select expiration date"
                        format="MMMM d, yyyy"
                        cssClass="e-outline"
                    />
                </div>
            </div>

                {/* Subject Section */}
                <div className="flex items-center justify-between border-b p-4">
                    <div className="flex items-center flex-1">
                        <div className="w-full">
                            <TextBoxComponent
                                placeholder="Subject*"
                                value={subject}
                                change={(e) => {
                                    setSubject(e.value);
                                    setValidationErrors(prev => ({...prev, subject: false}));
                                }}
                                cssClass={`e-outline ${validationErrors.subject ? 'e-error' : ''}`}
                                floatLabelType="Auto"
                                width="100%"
                            />
                            {validationErrors.subject && (
                                <div className="text-red-500 text-xs mt-1">Subject is required</div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Editor Section */}
                <div className="flex-1 overflow-y-auto p-4">
                    <div className="flex items-center gap-1 mb-1">
                        <span className="text-red-500">*</span>
                        <span className="text-sm text-gray-600">Message Content</span>
                    </div>
                    <div className={validationErrors.content ? 'border-2 border-red-500 rounded-lg' : ''}>
                        <RichTextEditorComponent
                            height={350}
                            toolbarSettings={toolbarSettings}
                            quickToolbarSettings={quickToolbarSettings}
                            value={editorContent}
                            change={(e) => {
                                handleEditorChange(e);
                                setValidationErrors(prev => ({...prev, content: false}));
                            }}
                        >
                            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                    </div>
                    {validationErrors.content && (
                        <div className="text-red-500 text-xs mt-1">Message content is required</div>
                    )}
                </div>

                {/* Footer */}
                <div className="border-t p-4">
                    <div className="flex justify-between items-center">
                        <div className="text-sm text-gray-500">
                            * Required fields
                        </div>
                        <div className="flex gap-2">
                        <button 
                            onClick={handleCancel}
                            className="btn-cancel flex cursor-pointer gap-1 px-3 py-1.5"
                        >
                            Cancel
                        </button>
                            <button 
                                onClick={handleSend}
                                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </DialogComponent>
    </div>
    
  );
};

export default MessageCenter;