import React, { useState, useEffect, useRef } from "react";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import ContactProfilePopup from "./Communication/ContactProfilePopup";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-richtexteditor/styles/material.css";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  doc,
  addDoc,
  serverTimestamp,
  orderBy,
  onSnapshot,
  Timestamp,
  writeBatch,
  limit,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import CreateGroupChatModal from "../components/CreateGroupChatModal";

const Communication = ({ className = "" }) => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentChatId, setCurrentChatId] = useState("");
  const [chats, setChats] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [isCreateGroupChatModalOpen, setIsCreateGroupChatModalOpen] =
    useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  
  const handleContactClick = () => {
    setShowProfilePopup(true);
  };

  const handleEditorChange = (args) => {
    setEditorContent(args.value);
  };

  const handleSend = () => {
    if (editorContent.trim() !== "") {
      sendMessage(currentChatId, auth.currentUser.uid, editorContent);
      setEditorContent("");
    }
  };

  // Function to strip HTML tags for preview in contacts list
  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "|",
      "Undo",
      "Redo",
    ],
  };

  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
  };

  const ContactAvatar = ({ contact }) => {
    if (contact.image) {
      return (
        <img
          src={contact.image}
          alt={`${contact.first_name}'s profile`}
          className="h-12 w-12 flex-shrink-0 rounded-full object-cover"
        />
      );
    } else {
      const initials = (contact.first_name + " " + contact.last_name)
        .split(" ")
        .map((name) => name[0])
        .join("")
        .toUpperCase()
        .slice(0, 2);

      return (
        <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full chat-user-thumbnail">
          {initials}
        </div>
      );
    }
  };

  useEffect(() => {
    const fetchChats = () => {
      if (auth && auth.currentUser && auth.currentUser.uid) {
        const chatsRef = collection(db, "chats");
        const q = query(
          chatsRef,
          where("users", "array-contains", auth.currentUser.uid)
        );

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const chats = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const contactsWithLastMessage = [];

          for (const chat of chats) {
            // Get the last message for this chat
            const messagesRef = collection(db, "chats", chat.id, "messages");
            const messagesQuery = query(messagesRef, orderBy("timestamp", "desc"), limit(1));
            const messageSnapshot = await getDocs(messagesQuery);
            const lastMessage = messageSnapshot.docs[0]?.data()?.timestamp || new Timestamp(0, 0);

            if (chat.groupName) {
              const groupUsers = await Promise.all(chat.users.map(async (userId) => {
                const userDocRef = doc(db, "users", userId);
                const userDoc = await getDoc(userDocRef);
                return userDoc.exists() ? { id: userDoc.id, ...userDoc.data() } : null;
              }));

              const groupChatUser = {
                id: chat.id,
                first_name: chat.groupName,
                last_name: "",
                email: groupUsers.map(user => user.email).join(", "),
                groupName: chat.groupName,
                selectedUsers: chat.users,
                lastMessageTime: lastMessage
              };
              contactsWithLastMessage.push(groupChatUser);
            } else {
              let otherUserId = chat.users.find(
                (uid) => uid !== auth.currentUser.uid
              );
              if (!otherUserId) {
                otherUserId = auth.currentUser.uid;
              }
              const userDocRef = doc(db, "users", otherUserId);
              const userDoc = await getDoc(userDocRef);

              if (userDoc.exists()) {
                contactsWithLastMessage.push({ 
                  id: userDoc.id, 
                  ...userDoc.data(),
                  lastMessageTime: lastMessage 
                });
              }
            }
          }

          // Sort contacts by last message timestamp
          const sortedContacts = contactsWithLastMessage.sort((a, b) => {
            return b.lastMessageTime.seconds - a.lastMessageTime.seconds;
          });

          setChats(chats);
          setContacts(sortedContacts);
        });

        return () => unsubscribe();
      }
    };

    fetchChats();
  }, [auth.currentUser]);

  const searchUsers = async (term) => {
    try {
      // First, get the current user's details
      const currentUserDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      const currentUser = currentUserDoc.data();

      // If current user is a citizen, implement restrictions
      if (currentUser.user_role === 'citizen') {
        const usersRef = collection(db, "users");
        
        // Create a query that:
        // 1. Matches the same center_id as the current user
        // 2. Only returns partners or center staff (explicitly check roles)
        // 3. Matches the search term (email)
        const query1 = query(usersRef, 
          where("center_id", "==", currentUser.center_id),
          where("user_role", "in", ["partner", "center"]), // Only search for partners and center staff
          where("email", "==", term)
        );

        const emailSnapshot = await getDocs(query1);
        const users = emailSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Check for name matches if no user found by email
        if (users.length === 0) {
          const [firstName, lastName] = term.split(" ").map(name => name.trim());

          if (firstName && lastName) {
            const nameQuery = query(usersRef,
              where("center_id", "==", currentUser.center_id),
              where("user_role", "in", ["partner", "center"]), // Only search for partners and center staff
              where("first_name", "==", firstName),
              where("last_name", "==", lastName)
            );

            const nameSnapshot = await getDocs(nameQuery);
            const nameUsers = nameSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            return nameUsers.length > 0 ? nameUsers[0] : null;
          }
        }

        return users[0];
      } else {
        // For non-citizen users, keep the original search logic
        const usersRef = collection(db, "users");
        const emailQuery = query(usersRef, where("email", "==", term));

        const emailSnapshot = await getDocs(emailQuery);
        const users = emailSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (users.length === 0) {
          const [firstName, lastName] = term.split(" ").map(name => name.trim());

          if (firstName && lastName) {
            const nameQuery = query(usersRef,
              where("first_name", "==", firstName),
              where("last_name", "==", lastName)
            );
            
            const nameSnapshot = await getDocs(nameQuery);
            const nameUsers = nameSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            return nameUsers.length > 0 ? nameUsers[0] : null;
          }
        }

        return users[0];
      }
    } catch (error) {
      console.error("Error searching for users:", error);
      return null;
    }
  };

  const initializeChat = async (currentUser, otherUser) => {
    try {
      const chatId = [currentUser.uid, otherUser.id].sort().join("_");
      setCurrentChatId(chatId);

      const chatsRef = collection(db, "chats");
      const chatDocRef = doc(chatsRef, chatId);
      const chatDoc = await getDoc(chatDocRef);

      let chat = null;

      if (chatDoc.exists()) {
        chat = { id: chatDoc.id, ...chatDoc.data() };
      } else {
        const newChat = {
          users: [currentUser.uid, otherUser.id],
          createdAt: serverTimestamp(),
        };
        await setDoc(chatDocRef, newChat);
        chat = { id: chatDocRef.id, ...newChat };
      }

      return chat;
    } catch (error) {
      console.error("Error initializing chat:", error);
      return null;
    }
  };

  const sendMessage = async (chatId, senderId, messageContent) => {
    try {
      const messagesRef = collection(db, "chats", chatId, "messages");
      const newMessage = {
        senderId,
        message: messageContent,
        timestamp: serverTimestamp(),
        seen: false,
      };
      await addDoc(messagesRef, newMessage);

      // Update the contacts order
      setContacts(prevContacts => {
        const updatedContacts = prevContacts.map(contact => {
          if ((contact.groupName && contact.id === chatId) || 
              (!contact.groupName && [auth.currentUser.uid, contact.id].sort().join('_') === chatId)) {
            return {
              ...contact,
              lastMessageTime: Timestamp.now() // Use current timestamp for immediate sorting
            };
          }
          return contact;
        });

        return updatedContacts.sort((a, b) => 
          (b.lastMessageTime?.seconds || 0) - (a.lastMessageTime?.seconds || 0)
        );
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    if (!currentChatId) return;

    const messagesRef = collection(db, "chats", currentChatId, "messages");
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const msgs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(msgs);

      // Update the contacts order when new messages arrive
      if (msgs.length > 0) {
        const lastMessage = msgs[msgs.length - 1];
        setContacts(prevContacts => {
          const updatedContacts = prevContacts.map(contact => {
            if ((contact.groupName && contact.id === currentChatId) || 
                (!contact.groupName && [auth.currentUser.uid, contact.id].sort().join('_') === currentChatId)) {
              return {
                ...contact,
                lastMessageTime: lastMessage.timestamp || Timestamp.now()
              };
            }
            return contact;
          });

          return updatedContacts.sort((a, b) => 
            (b.lastMessageTime?.seconds || 0) - (a.lastMessageTime?.seconds || 0)
          );
        });
      }
    });

    return () => unsubscribe();
  }, [currentChatId]);

  const onSearchClick = async () => {
    const user = await searchUsers(searchTerm);

    if (user) {
      const chat = await initializeChat(auth.currentUser, user);
      const chatExists = chats.some(
        (existingChat) => existingChat.id === chat.id
      );
      const contactExists = contacts.some(
        (existingContact) => existingContact.id === user.id
      );

      if (!chatExists) {
        setChats((prevChats) => [...prevChats, chat]);
      }

      if (!contactExists) {
        setContacts((prevContacts) => [...prevContacts, user]);
      }

      if (chat) {
        selectContact(user);
      }
    } else {
      alert("User not found!");
    }
  };

  const selectContact = async (contact) => {
    try {
      let chat;
  
      if (contact.groupName) {
        setSelectedContact(contact);
        chat = await initializeGroupChat(
          auth.currentUser,
          contact.selectedUsers,
          contact.groupName
        );
      } else {
        setSelectedContact(contact);
        chat = await initializeChat(auth.currentUser, contact);
      }
  
      if (chat) {
        setCurrentChatId(chat.id);
  
        await markMessagesAsSeen(chat.id);
      }
    } catch (error) {
      console.error("Error selecting contact:", error);
    }
  };
  
  const markMessagesAsSeen = async (chatId) => {
    try {
      const messagesRef = collection(db, "chats", chatId, "messages");
      const q = query(messagesRef, where("seen", "==", false)); 
      const querySnapshot = await getDocs(q);
  
      const batch = writeBatch(db); 
      querySnapshot.forEach((doc) => {
        const messageData = doc.data();
        
        if (messageData.senderId !== auth.currentUser.uid) {
          batch.update(doc.ref, { seen: true });
        }
      });
  
      await batch.commit(); 
      console.log(`Marked messages in chat ${chatId} as seen.`);
    } catch (error) {
      console.error("Error marking messages as seen:", error);
    }
  };
  
  const [unreadMessages, setUnreadMessages] = useState({});

  // Add this useEffect to track unread messages
  useEffect(() => {
    if (!auth.currentUser) return;
  
    const trackUnreadMessages = async () => {
      contacts.forEach(async (contact) => {
        const chatId = contact.groupName 
          ? contact.id 
          : [auth.currentUser.uid, contact.id].sort().join('_');
  
        const messagesRef = collection(db, "chats", chatId, "messages");
        const q = query(
          messagesRef,
          where("seen", "==", false),
          where("senderId", "!=", auth.currentUser.uid)
        );
  
        onSnapshot(q, (snapshot) => {
          setUnreadMessages(prev => ({
            ...prev,
            [contact.id]: snapshot.docs.length
          }));
        });
      });
    };
  
    trackUnreadMessages();
  }, [contacts]);

  const convertTimestampToDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      const formattedDate = date.toLocaleString();
      return formattedDate;
    } else {
      return "";
    }
  };

  const now = new Date().toLocaleString();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const initializeGroupChat = async (currentUser, otherUsers, groupName) => {
    try {
      const chatsRef = collection(db, "chats");

      // Check if a group chat with the same name already exists
      const q = query(chatsRef, where("groupName", "==", groupName));
      const querySnapshot = await getDocs(q);

      let chat = null;

      if (!querySnapshot.empty) {
        // Group chat with the same name already exists
        const existingChatDoc = querySnapshot.docs[0]; // Assuming you take the first match if there are multiple
        chat = { id: existingChatDoc.id, ...existingChatDoc.data() };
      } else {
        // Create a new group chat if it doesn't exist
        const chatDocRef = doc(chatsRef); // Generates a random ID
        const chatId = chatDocRef.id;
        const newChat = {
          groupName: groupName,
          users: [currentUser.uid, ...otherUsers.map((user) => user.id)],
          createdAt: serverTimestamp(),
        };
        await setDoc(chatDocRef, newChat);
        chat = { id: chatDocRef.id, ...newChat };
      }

      setCurrentChatId(chat.id);
      return chat;
    } catch (error) {
      console.error("Error initializing group chat:", error);
      return null;
    }
  };

  const handleCreateGroup = async (groupName, selectedUsers) => {
    const chat = initializeGroupChat(
      auth.currentUser,
      selectedUsers,
      groupName
    );
    const chatExists = chats.some(
      (existingChat) => existingChat.id === chat.id
    );
    const contactExists = contacts.some(
      (existingContact) => existingContact.id === chat.id
    );
    let groupChatUser = {};

    if (!chatExists) {
      setChats((prevChats) => [...prevChats, chat]);
    }

    if (!contactExists) {
      groupChatUser = {
        id: chat.id,
        first_name: groupName,
        last_name: "",
        email: groupName,
        groupName: groupName,
        selectedUsers: selectedUsers,
      };
      setContacts((prevContacts) => [...prevContacts, groupChatUser]);
    }

    if (chat) {
      setSelectedContact(groupChatUser);

      if (chat) {
        setCurrentChatId(chat.id);
      }
    }
  };

  const searchUsersByEmail = async (email) => {
    try {
      const user = await searchUsers(email);

      return [user].filter((user) => user.email.includes(email));
    } catch (err) {
      alert("User not found.");
    }
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        setCurrentUser(userDoc.data());
      }
    };

    fetchCurrentUser();
  }, [auth.currentUser]);

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start px-2.5 pb-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <CreateGroupChatModal
        isOpen={isCreateGroupChatModalOpen}
        onClose={() => setIsCreateGroupChatModalOpen(false)}
        onCreateGroup={handleCreateGroup}
        searchUsersByEmail={searchUsersByEmail}
      />
      <div id="dialog-container"></div>
      <section className="flex max-w-full flex-row items-start justify-start self-stretch text-left text-sm text-darkslateblue">
        {/* Sidebar */}
        <div className="rounded-lg pr-2 pl-0 commu-left-panel">
          <h2 className="commu-title mb-2 mt-4">
            Communication
          </h2>
          <button
            className="btn-default w-full mb-2 px-4 py-2 mt-2"
            onClick={() => setIsCreateGroupChatModalOpen(true)}
          >
            Create Group Chat
          </button>
          <input
            type="text"
            placeholder="Search email or name..."
            className="mb-4 w-full rounded border p-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                const user = await searchUsers(searchTerm); // Trigger search on Enter key
                if (user) {
                  const chat = await initializeChat(auth.currentUser, user);
                  const chatExists = chats.some(
                    (existingChat) => existingChat.id === chat.id
                  );
                  const contactExists = contacts.some(
                    (existingContact) => existingContact.id === user.id
                  );

                  if (!chatExists) {
                    setChats((prevChats) => [...prevChats, chat]);
                  }

                  if (!contactExists) {
                    setContacts((prevContacts) => [...prevContacts, user]);
                  }

                  if (chat) {
                    selectContact(user);
                  }
                } else {
                  alert("User not found!"); // Alert if user is not found
                }
              }
            }}
          />
          <div className="max-h-[calc(100vh-200px)] space-y-4 overflow-y-auto ">
          {contacts.map((contact) => (
  <div
    key={contact.id + "qwerty"}
    className={`flex cursor-pointer items-center rounded transition-colors duration-200  ${
      selectedContact?.id === contact.id
        ? "bg-blue-100"
        : "hover:bg-gray-200"
    }`}
    onClick={() => {
      selectContact(contact);
    }}
  >
    <ContactAvatar contact={contact} />
    <div className="ml-4 min-w-0 flex-grow ">
      <h3 className={`truncate convo-title ${
        unreadMessages[contact.id] > 0 ? 'convo-title-bold' : 'convo-title'
      }`}>
        {contact.first_name + " " + contact.last_name}
      </h3>
      <p className={`text-gray-600 truncate text-sm ${
        unreadMessages[contact.id] > 0 ? 'convo-title-bold' : 'convo-title'
      }`}>
        {contact.email}
      </p>
      <div className="flex justify-between items-center">
        <span className="text-xs text-gray-400">{now}</span>
        {unreadMessages[contact.id] > 0 && (
          <span className="bg-blue-500 text-white rounded-full px-2 py-1 text-xs">
            {unreadMessages[contact.id]}
          </span>
        )}
      </div>
    </div>
  </div>
))}
          </div>
        </div>

        {/* Messages */}
        <div className="flex h-[calc(100vh-70px)] flex-1 flex-col self-stretch overflow-hidden commu-right-panel">
          {selectedContact ? (
            <div className="flex h-full w-full flex-col">
              <div className="flex items-center justify-between border-[1px] border-b border-solid border-silver-100 p-4">
                <div className="flex items-center space-x-4">
                  {/* <button className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                  </button>
                  <button className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </button> */}
                </div>
                <div className="flex items-center">
                  <ContactAvatar contact={selectedContact} />
                  <div
                    className="hover:bg-gray-100 cursor-pointer rounded p-2 transition-all duration-200"
                    onClick={handleContactClick}
                  >
                    <h2 className="text-xl font-bold">
                      {selectedContact.first_name}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="flex-1 overflow-y-auto p-4">
                <div className="mb-4">
                  <span className="text-sm text-gray-500">Today</span>
                </div>
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`mb-4 ${
                      message.senderId === auth.currentUser.uid
                        ? "text-right"
                        : ""
                    }`}
                  >
                    <div
                      className={`inline-block rounded-lg p-3 ${
                        message.senderId === auth.currentUser.uid
                          ? "bg-blue-100"
                          : "bg-gray-200"
                      }`}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: message.message }}
                      ></div>
                      <span className="text-xs text-gray-500">
                        {convertTimestampToDate(message.timestamp)}
                      </span>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div className="border-t border-gray-300 p-4 flex items-center">
                    <textarea
                      rows={3}
                      placeholder="Type your message..."
                      className="flex-grow chat-textarea p-2 resize-none" // Adjust styles as needed
                      value={editorContent}
                      onChange={(e) => setEditorContent(e.target.value)} // Update editorContent on input change
                    />
                    <button
                      onClick={handleSend}
                      className="ml-2 p-2 rounded-full transition duration-150 hover:bg-blue-600" // Add padding and hover effect
                      aria-label="Send Message" // Accessibility label
                    >
                      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.8173 21.8081C29.8173 21.8081 29.8107 21.8147 29.8074 21.818L13.2253 30.6838C12.9602 30.8321 12.6561 30.8957 12.3539 30.8662C12.0516 30.8367 11.7656 30.7155 11.5342 30.5187C11.501 30.4909 11.4692 30.4617 11.4387 30.4312C11.2375 30.2312 11.0977 29.9779 11.0358 29.701C10.9739 29.4242 10.9925 29.1354 11.0894 28.8687L13.7198 21.5038C13.7459 21.431 13.7938 21.368 13.8571 21.3235C13.9203 21.279 13.9958 21.2551 14.0731 21.2552L21.6655 21.2545C21.861 21.2495 22.0468 21.1683 22.1833 21.0283C22.3199 20.8882 22.3963 20.7004 22.3963 20.5048C22.3963 20.3092 22.3199 20.1213 22.1833 19.9813C22.0468 19.8412 21.861 19.76 21.6655 19.755L14.0678 19.7491C13.9905 19.7491 13.915 19.7253 13.8518 19.6807C13.7885 19.6362 13.7406 19.5733 13.7145 19.5005L11.098 12.1733C10.9922 11.8886 10.9732 11.5788 11.0436 11.2833C11.1139 10.9879 11.2703 10.7199 11.4931 10.5134C11.7245 10.3036 12.016 10.1718 12.3263 10.1367C12.6367 10.1016 12.9502 10.165 13.2226 10.3178L29.8047 19.1836L29.8147 19.1935C30.0467 19.3238 30.2399 19.5135 30.3745 19.743C30.5091 19.9726 30.5801 20.2339 30.5804 20.5C30.5807 20.7661 30.5101 21.0275 30.376 21.2574C30.2419 21.4873 30.0491 21.6773 29.8173 21.8081Z" fill="#003461"/>
                      </svg>
                    </button>
                  </div>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <p className="text-center text-gray-500">
                Select a contact to start messaging
              </p>
            </div>
          )}
        </div>
      </section>
      {showProfilePopup && (
        <ContactProfilePopup
          contact={selectedContact}
          onClose={() => setShowProfilePopup(false)}
        />
      )}
    </div>
  );
};

export default Communication;
