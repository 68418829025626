import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import React, { useState, useEffect, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort, Edit } from "@syncfusion/ej2-react-grids";
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { doc, getDoc, Query, updateDoc, setDoc } from "firebase/firestore";
import DisasterDashboard from "./center-pov/DisasterDeclaration/DisasterDashboard.js";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const Home = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [editing, setEditing] = useState({
    allowDeleting: true,
    allowAdding: true,
    allowEditing: true,
    mode: "Dialog",
    showDeleteConfirmDialog: true,
    dialog: { cssClass: "custom-dialog" },
  });
  const [toolbarOptions, setToolbarOptions] = useState([
    "Add",
    "Edit",
    "Delete",
  ]);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [disasterNames, setDisasterNames] = useState([]);
  const [selectedDisaster, setSelectedDisaster] = useState("");
  const [selectedDisasterId, setSelectedDisasterId] = useState("");
  const [userId, setUserId] = useState(null);
  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const datepickerparams = {
    params: {
      value: new Date(),
      format: "MM/dd/yyyy",
      showClearButton: false,
      showTodayButton: true,
    },
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];
  const pageSettings = { pageSize: 10 };
  const [checkboxStates, setCheckboxStates] = useState({
    show_disaster_impact_graph: false,
    show_injury_graph: false,
    show_home_damage_graph: false,
    show_homes_destroyed_graph: false,
    show_home_damage_typed_graph: false,
  });

  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          setUserRole(role);
          if (role === "center") {
            setEditing({
              allowEditing: false,
              allowAdding: false,
              allowDeleting: false,
              mode: "Dialog",
              dialog: { cssClass: "custom-dialog" },
            });
            setToolbarOptions([]);
          }
        }
      }
    };

    const fetchData = async () => {
      try {
        // Fetch declared disaster data
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const fetchedData = querySnapshot.docs.map((doc) => {
          const activeData = doc.data();
          return {
            ...activeData,
            disaster_id: doc.id,
            disaster_date: activeData.disaster_date
              ? activeData.disaster_date.toDate().toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : null,
          };
        });
        setActiveData(fetchedData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchUserRole();
    fetchData();
  }, []);

  useEffect(() => {
    const fetchUserCheckboxStates = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        setUserId(user.uid);
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCheckboxStates({
            ...checkboxStates,
            ...userData,
          });
        }
      }
    };

    fetchUserCheckboxStates();
  }, []);

  useEffect(() => {
    const fetchDisasterNames = async () => {
      try {
        const q = query(
          collection(db, "declared-disaster"),
          where("disaster_status", "==", "active")
        );
        const querySnapshot = await getDocs(q);
        const disasters = querySnapshot.docs
          .filter((doc) => !doc.data().is_deleted)
          .map((doc) => ({
            id: doc.id,
            name: doc.data().disaster_name,
          }));

        setDisasterNames(disasters);

        if (disasters.length > 0) {
          setSelectedDisaster(disasters[0].name);
          setSelectedDisasterId(disasters[0].id);
        }
      } catch (error) {
        console.error("Error fetching disaster names:", error);
      }
    };

    fetchDisasterNames();
  }, []);

  const handleDisasterChange = (e) => {
    const selectedName = e.target.value;
    setSelectedDisaster(selectedName);

    const selectedDisaster = disasterNames.find(
      (disaster) => disaster.name === selectedName
    );
    if (selectedDisaster) {
      setSelectedDisasterId(selectedDisaster.diasater_id);
    }
  };

  const actionComplete = async (args) => {
    if (args.requestType === "delete") {
      const deletedItems = Array.isArray(args.data) ? args.data : [args.data];
      const deletedIds = deletedItems.map((item) => item.disaster_id);
      console.log("Deleting IDs:", deletedIds);
      const updatedData = data.filter(
        (item) => !deletedIds.includes(item.disaster_id)
      );
      setData(updatedData);
    } else if (args.requestType === "save") {
      console.log("Save action triggered", args.data);
      const updatedItem = args.data;
      const updatedData = data.map((item) =>
        item.disaster_id === updatedItem.disaster_id ? updatedItem : item
      );
      setData(updatedData);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      if (dialog) {
        // Check if dialog is not null
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Record of " + args.rowData["disasterID"]
            : "New Disaster";

        // Set the header styles
        const headerContent = dialog.element.querySelector(
          ".e-dlg-header-content"
        );
        const header = dialog.element.querySelector(".e-dlg-header");
        if (headerContent && header) {
          // Ensure elements exist before accessing
          headerContent.style.backgroundColor = "#348BFF";
          headerContent.style.color = "#FFFFFF";
          header.style.color = "#FFFFFF";
        }

        // Style the save and cancel buttons
        setTimeout(() => {
          const saveButton = dialog.element.querySelector(".e-primary");
          const cancelButton = dialog.element.querySelector(
            ".e-btn:not(.e-primary)"
          );

          if (saveButton) {
            saveButton.style.backgroundColor = "#FFFFFF";
            saveButton.style.color = "#348BFF";
            saveButton.style.border = "none";
          }

          if (cancelButton) {
            cancelButton.style.backgroundColor = "#FFFFFF";
            cancelButton.style.color = "#348BFF";
            cancelButton.style.border = "1px solid #348BFF";
          }
        }, 0);
      }
    } else if (args.requestType === "add") {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const newDisaster = {
          ...args.data,
          center_id: user.uid,
        };
        await addDoc(collection(db, "declared-disaster"), newDisaster);
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const fetchedData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          disaster_id: doc.id,
        }));
        setData(fetchedData);
      }
    }
  };

  const toolbarClick = (args) => {
    if (args.item.id === "Grid_add") {
    } else if (args.item.id === "Grid_edit") {
    } else if (args.item.id === "Grid_delete") {
    } else if (args.item.id === "Grid_update") {
    } else if (args.item.id === "Grid_cancel") {
    }
  };

  const queryCellInfo = (args) => {
    if (args.column.field === "disaster_name") {
      let bgColor, textColor;
      switch (args.data.disasterName) {
        case "Fire":
          bgColor = "#FFF6E6";
          textColor = "#FDA502";
          break;
        case "Typhoon":
          bgColor = "#DCF2FF";
          textColor = "#1565C0";
          break;
        case "Flood":
          bgColor = "#BED8FF";
          textColor = "#0E47A1";
          break;
        case "Earthquake":
          bgColor = "#F4EDE8";
          textColor = "#8B4514";
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.disaster_name}</div>`;
      }
    }

    if (args.column.field === "disaster_status") {
      let bgColor, textColor;
      switch (args.data.disaster_status) {
        case "Active":
          bgColor = "#DEF7EC"; // Green
          textColor = "#299D91"; // White
          break;
        case "Inactive":
          bgColor = "#FDE8E8"; // Red
          textColor = "#E4626F"; // White
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.disaster_status}</div>`;
      }
    }

    if (args.column.field === "alert_level") {
      let bgColor, textColor;
      switch (args.data.alert_level) {
        case "Level 1":
          bgColor = "#FFEDBC"; // Light Yellow
          textColor = "#F4B000"; // Dark Orange
          break;
        case "Level 2":
          bgColor = "#FAE2CC"; // Light Orange
          textColor = "#E46E00"; // Dark Orange
          break;
        case "Level 3":
          bgColor = "#F1D5D5"; // Light Red
          textColor = "#CD1A1A"; // Dark Red
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
          background-color: ${bgColor};
          color: ${textColor};
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${args.data.alert_level}</div>`;
      }
    }
  };

  if (loading) {
    return <div className=""></div>;
  }

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Dashboard
        </a>
      </div>
      {/* Report Generator Start */}
      <div className="w-full flex-1 rounded-mini bg-white">
        <div className="flex w-full items-center justify-between gap-2.5">
          <select
            value={selectedDisaster}
            onChange={handleDisasterChange}
            className="relative text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]"
          >
            {disasterNames.map((disaster) => (
              <option key={disaster.id} value={disaster.name}>
                {disaster.name}
              </option>
            ))}
          </select>
        </div>
        <div className="-mt-10">
          <DisasterDashboard propId={selectedDisasterId} />
        </div>
      </div>
      {/* Report Generator End */}

      {/* Active Disaster Grid Start */}
      <section className="mt-4 w-full rounded-md border border-gray-200 bg-white pr-4">
        <h1 className="mb-3 text-base font-bold text-blue-500">
          ACTIVE DISASTERS
        </h1>

        <GridComponent
          dataSource={activeData}
          allowSorting={true}
          sortSettings={sortSettings}
          actionComplete={actionComplete}
          toolbarClick={toolbarClick}
          filterSettings={filterSettings}
          allowFiltering={true}
          queryCellInfo={queryCellInfo}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="disaster_id"
              headerText="Disaster ID"
              isPrimaryKey={true}
              visible={false}
            />
            <ColumnDirective
              field="disaster_name"
              headerText="Disaster"
              width="150"
              textAlign="Left"
              editType="stringedit"
            />
            <ColumnDirective
              field="disaster_status"
              headerText="Status"
              width="100"
              textAlign="Left"
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: [
                    { text: "Active", value: "Active" },
                    { text: "Inactive", value: "Inactive" },
                  ],
                  fields: { text: "text", value: "value" },
                  query: new Query(),
                },
              }}
            />
            <ColumnDirective
              field="disaster_date"
              headerText="Date"
              width="100"
              format="MM/dd/yyyy"
              editType="datepickeredit"
              edit={datepickerparams}
            />
            <ColumnDirective
              field="location"
              headerText="Location"
              width="100"
              editType="stringedit"
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Edit,
              CommandColumn,
              Toolbar,
              Filter,
              ColumnMenu,
            ]}
          />
        </GridComponent>
      </section>
      {/* Active Disaster Grid End */}
    </div>
  );
};

export default Home;
