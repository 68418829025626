import React, { useState, useEffect, useRef, use } from "react";
import PropTypes from "prop-types";
import { db, auth } from "../firebase";
import Logo from "../assets/bluesky-logo-navbar.svg"
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import Menu from "../pages/Profile-Popup/Menu";
import { useAuth } from "./AuthContext";
import ContactSupport from "../pages/Profile-Popup/ContactSupport";
import Popup from "./Popup";
import { useSearchParams } from "react-router-dom";
import EmergencyBanner from './Notifications/EmergencyBanner';
import EmergencyPopup from './Notifications/EmergencyPopup';
import NotificationPanel from './Notifications/NotificationPanel';
import { useNotifications } from './Notifications/useNotifications';
import NotificationButton from './Notifications/NotificationButton';

const Navbar = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const { role } = useAuth();
  const [searchParams] = useSearchParams();
  const isDupAddress = searchParams.get("dupAddress") === "true";
  const [showPopup, setShowPopup] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const {
      notifications,
      unreadNotifications,
      emergencyNotification,
      showEmergencyPopup,
      markEmergencyAsRead
  } = useNotifications();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
      console.log("Rolee: " + role);
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        // if duplicate address, show the popup message
        if (isDupAddress) setShowPopup(true);

        if (userData.user_role === "center") {
          console.log("Center ID: " + userData.center_id);
          const centerDocRef = doc(db, "centers", userData.center_id);
          const centerDocSnap = await getDoc(centerDocRef);
          if (centerDocSnap.exists()) {
            setData({ id: centerDocSnap.id, ...centerDocSnap.data() });
          }
        } else if (userData.user_role === "partner") {
          const partnerDocRef = doc(db, "partners", userData.partner_id);
          const partnerDocSnap = await getDoc(partnerDocRef);
          if (partnerDocSnap.exists()) {
            setData({ id: partnerDocSnap.id, ...partnerDocSnap.data() });
          }
        } else {
          setData({ id: userDocSnap.id, ...userDocSnap.data() });
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [showSupport, setShowSupport] = useState(false);

  const toggleSupport = () => {
    setShowSupport(!showSupport);
  };

  return (
    <nav
      className={`bg-header-panel h-[6vh] border-none text-white shadow-none`}
    >
      <div
        className={`bg-header-panel fixed left-0 right-0 top-0 z-10 flex h-[6vh] w-full flex-row items-center justify-between text-left font-poppins text-base leading-[normal] tracking-[normal] text-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)]`}
      >
        {/* Blue Sky Logo */}
        <div className="flex h-[6vh] flex-row items-center justify-center gap-[11px] pl-7">
          <img src={Logo} alt="Blue Sky Logo" width="41" height="45" />
          <div className="flex flex-col items-start font-semibold text-[inherit]">
            <div className="flex flex-col items-baseline text-white">
              <p className="header-logo-label">DARCI</p>
              <p className="header-powered-by -my-1 text-[8px] font-normal">
                Disaster Aware & Ready Communities
              </p>
            </div>
          </div>
        </div>

        <EmergencyBanner notification={emergencyNotification} />
            <NotificationPanel 
                isOpen={showNotifications}
                onClose={() => setShowNotifications(false)}
                notifications={notifications}
            />
            {/* {showEmergencyPopup && emergencyNotification && (
                <EmergencyPopup 
                    notification={emergencyNotification}
                    onClose={markEmergencyAsRead}
                />
            )} */}
        {/* User Nav */}
        <div className="flex max-h-[6vh] flex-col items-center justify-center pr-7 text-right text-xs text-honeydew">
          <div className="flex flex-row items-center justify-center gap-[14px]">
            <svg
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={toggleSupport}
            >
              <path
                d="M19.6104 1H6.61035C3.29664 1 0.610352 3.68629 0.610352 7V20C0.610352 23.3137 3.29664 26 6.61035 26H19.6104C22.9241 26 25.6104 23.3137 25.6104 20V7C25.6104 3.68629 22.9241 1 19.6104 1Z"
                fill="#FBBB4C"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.86035 11.0208C7.86035 9.55658 8.41347 8.15235 9.39804 7.117C10.3826 6.08165 11.718 5.5 13.1104 5.5C14.5028 5.5 15.8381 6.08165 16.8227 7.117C17.8073 8.15235 18.3604 9.55658 18.3604 11.0208V11.8371C19.6324 12.0287 20.6104 13.1794 20.6104 14.5698V14.767C20.6103 15.109 20.5451 15.4475 20.4187 15.7626C20.2923 16.0777 20.1072 16.363 19.8743 16.6017C19.6415 16.8404 19.3655 17.0277 19.0627 17.1526C18.76 17.2774 18.4365 17.3373 18.1114 17.3287C17.5564 19.0007 16.1449 20.1277 14.6217 20.4132C14.2692 20.5489 13.8521 20.485 13.4854 20.485C13.187 20.485 12.9009 20.3603 12.6899 20.1385C12.4789 19.9166 12.3604 19.6157 12.3604 19.3019C12.3604 18.9882 12.4789 18.6873 12.6899 18.4654C12.9009 18.2436 13.187 18.1189 13.4854 18.1189H14.2354C14.4115 18.1189 14.5852 18.1623 14.7424 18.2457C14.8996 18.3292 15.036 18.4502 15.1406 18.5992C16.1126 18.1126 16.8604 17.085 16.8604 15.7529V11.0208C16.8604 9.97492 16.4653 8.9719 15.7621 8.23236C15.0588 7.49283 14.105 7.07737 13.1104 7.07737C12.1158 7.07737 11.162 7.49283 10.4587 8.23236C9.75544 8.9719 9.36035 9.97492 9.36035 11.0208V15.95C9.36035 16.3161 9.22207 16.6672 8.97593 16.926C8.72979 17.1848 8.39595 17.3302 8.04785 17.3302C7.40138 17.3302 6.7814 17.0602 6.32428 16.5795C5.86716 16.0988 5.61035 15.4468 5.61035 14.767V14.5698C5.61019 13.9059 5.83757 13.2642 6.25082 12.7623C6.66406 12.2604 7.23549 11.9319 7.86035 11.8371V11.0208ZM7.86035 13.4539C7.64092 13.5354 7.45095 13.6866 7.31662 13.8865C7.1823 14.0863 7.11023 14.3251 7.11035 14.5698V14.767C7.11035 15.2442 7.43285 15.6425 7.86035 15.7332V13.4539ZM18.3604 13.4539V15.7332C18.7879 15.6417 19.1104 15.2442 19.1104 14.767V14.5698C19.1105 14.3251 19.0385 14.0863 18.9041 13.8865C18.7698 13.6866 18.5798 13.5354 18.3604 13.4539Z"
                fill="#9C1717"
              />
            </svg>

            <NotificationButton 
                unreadNotifications={unreadNotifications}
                onClick={() => setShowNotifications(true)}
              />

            <div className="flex flex-col items-end justify-start">
              <p
                className="relative inline-block min-w-[86px] cursor-pointer font-semibold text-[inherit] [text-decoration:none]"
                onClick={toggleMenu}
              >
                {data.first_name
                  ? data.first_name
                  : // eslint-disable-next-line no-useless-concat
                  "First Name" + " " + data.last_name
                  ? data.last_name
                  : "Last Name"}
              </p>
              {/* <p className="relative inline-block min-w-[40px] font-light text-white [text-decoration:none]">
                {role === "admin"
                  ? "Admin"
                  : role === "citizen"
                  ? "Citizen"
                  : role === "center"
                  ? "Center"
                  : "Partner"}
              </p> */}
            </div>
            <div
              className="box-border flex h-[25px] w-[25px] cursor-pointer flex-col items-start justify-start px-0 pb-0  text-center"
              onClick={toggleMenu}
            >
              <div className="bg-gold flex h-[25px]  w-[25px] flex-row items-center justify-center self-stretch rounded-md">
                <div className="relative hidden h-[25px] w-[25px] rounded-md bg-white " />
                <p className="relative z-[1] font-medium text-[inherit] [text-decoration:none]">
                  {data.first_name
                    ? data.first_name.charAt(0)
                    : role === "partner"
                    ? "P"
                    : role === "center"
                    ? "C"
                    : role === "admin"
                    ? "A"
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu isOpen={showMenu} onClose={toggleMenu} data={data} />
      <ContactSupport
        isOpen={showSupport}
        onClose={toggleSupport}
        data={data}
      />
      {showPopup && (
        <Popup
          title="Duplicate Address Notification"
          message="The duplicate address registration has been recorded. Thank you for reporting this activity."
          onClose={() => setShowPopup(false)}
        />
      )}
    </nav>
  );
};

export default Navbar;
