import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { USStates } from "./../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import "./../style.css";

const Journals = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [data, setData] = useState([]);
  const [disasterJournals, setDisasterJournals] = useState([]);
  const gridRef = useRef(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const now = new Date();
  const [editing, setEditing] = useState({
      allowEditing: false,
      allowAdding: true,
      allowDeleting: false,
      mode: "Dialog",
      dialog: { cssClass: "custom-dialog" },
      allowEditOnDblClick: false,
  });

  const sortSettings = {
      allowSorting: true,
    };
    const filterSettings = {
      type: "CheckBox",
    };
    const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

    const pageSettings = { pageSize: 10 };

  const fetchJournals = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "disaster-journals"));
        const journalsData = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((journal) => !journal.is_deleted && journal.userId === userId);

        setDisasterJournals(journalsData);

        // Set allowAdding to false if no journals are present
        setEditing((prevEditing) => ({
          ...prevEditing,
          allowAdding: journalsData.length > 0,
        }));
      } catch (error) {
        console.error("Error fetching Journals:", error);
      }
  };

  useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          setUserId(currentUser.uid);
          fetchUserRole(currentUser.uid);
        } else {
          setData(null);
          setUserRole(null);
        }
      });

      return () => unsubscribe();
  }, []);

  useEffect(() => {
      if (userId) {
        fetchJournals(); // Call to fetch journals when userId is set
      }
  }, [userId]);

  const fetchUserRole = async (uid) => {
      try {
        const userDoc = await getDoc(doc(db, "users", uid));
        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          setUserRole(role);

          // Set editing permissions based on role
          if (role === "citizen" || role === "partner") {
            setEditing(prevEditing => ({
              ...prevEditing,
              allowEditing: true,  // Allow editing for citizens and partners
              allowAdding: true,   // Always allow adding
              allowDeleting: false // Disallow deleting
            }));
          } else if (role === "center") {
            setEditing(prevEditing => ({
              ...prevEditing,
              allowEditing: true,  // Allow editing for center role
              allowAdding: true,   // Always allow adding
              allowDeleting: true  // Allow deleting for center role
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
  };

  const formattedTime = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = now.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
  });

  const generateJournalId = () => {
     return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleAddJournal = () => {
    if (gridRef.current) {
      gridRef.current.addRecord(); // This will open the add dialog for the grid
    }
  };




  const getEditorTypeColor = (editorType) => {
      switch (editorType) {
        case 'center': return 'green-500';
        case 'partner': return 'yellow-500';
        case 'citizen': return 'blue-500';
        default: return 'gray-500';
      }
  };

  const getEditorTypeLetter = (editorType) => {
      switch (editorType) {
        case 'center': return 'C';
        case 'partner': return 'P';
        case 'citizen': return 'S';
        default: return '?';
      }
  };

  const getEditorTypeLabel = (editorType) => {
      switch (editorType) {
        case 'center': return 'Center';
        case 'partner': return 'Partner';
        case 'citizen': return 'Survivor';
        default: return 'Unknown';
      }
  };

  const privacyOptions = [
    { value: "Public" },
    { value: "Private" },
    { value: "Restricted" },
  ];

  const showPopup = (message) => {
      setPopupMessage(message);
      setIsPopupOpen(true);
  };

  const actionBegin = async (args) => {
      if (args.requestType === "add") {
          // Set today's date and time when adding a new journal
          const currentDate = new Date();
          const formattedDate = `${
              currentDate.getMonth() + 1
          }/${currentDate.getDate()}/${currentDate.getFullYear()}`; // Format date as 'MM/DD/YYYY'
          const formattedTime = currentDate.toTimeString().split(' ')[0]; // Format time as 'HH:MM:SS'

          // Prepopulate date and time in the args.data when user clicks 'Add New'
          args.data.journalDate = formattedDate;
          args.data.journalTime = formattedTime;
      }

      if (args.requestType === "save") {
          const data = args.data;

          // Assign values before validation
          const disaster = data.disaster || ""; // Ensure disaster is correctly populated
          const description = data.description || ""; // Ensure description is correctly populated
          const date = data.journalDate || formattedDate; // Use formatted date if not already assigned
          const time = data.journalTime || formattedTime; // Use formatted time if not already assigned

          // Validate required fields
          if (!disaster.trim() || !description.trim()) {
              args.cancel = true;
              alert("Please fill in disaster and description before saving.");
              return;
          }

          // Generate and set the necessary fields
          data.journalId = generateJournalId();
          data.userId = userId; // User ID from the state
          data.userRole = userRole; // User role from the state
          data.editorId = userId; // Editor ID, usually the same as the user ID
          data.editorType = userRole; // Editor type, usually the same as the user role
          data.journalDate = date; // Use the pre-filled or formatted date
          data.journalTime = time; // Use the pre-filled or formatted time

          try {
              if (args.action === "add") {
                  data.inserted_at = serverTimestamp(); // Set the timestamp for the new record
                  const docRef = await addDoc(
                      collection(db, "disaster-journals"),
                      data
                  );
                  data.id = docRef.id; // Add the new document ID to the data
              } else if (args.action === "edit") {
                  if (userRole === "citizen" || userRole === "partner") {
                    if (args.rowData.editor_ID !== userId || args.rowData.editor_type !== userRole) {
                      args.cancel = true;
                      showPopup("You don't have permission to edit this record.");
                      return;
                    }
                  }
                  args.rowData = { ...args.rowData };
              }

              // Update the local state
              setDisasterJournals((prevJournals) => {
                  const index = prevJournals.findIndex(
                      (journal) => journal.id === data.id
                  );
                  if (index !== -1) {
                      // Update existing journal
                      return prevJournals.map((journal) =>
                          journal.id === data.id ? { ...journal, ...data } : journal
                      );
                  } else {
                      // Add new journal
                      return [...prevJournals, data];
                  }
              });
          } catch (error) {
              console.error("Error saving document:", error);
          }
      }
      // Handle delete request
      else if (args.requestType === "delete") {
          try {
              const deleteData = args.data[0]; // Get the data of the selected row(s) to be deleted
              const docRef = doc(db, "disaster-journals", deleteData.id); // Reference to the document in Firestore

              // Confirm delete operation
              const confirmDelete = window.confirm(
                  `Are you sure you want to delete the journal entry for disaster "${deleteData.disaster}"?`
              );

              if (confirmDelete) {
                  await deleteDoc(docRef); // Delete the document from Firestore

                  // Update local state to remove the deleted journal
                  setDisasterJournals((prevJournals) =>
                      prevJournals.filter((journal) => journal.id !== deleteData.id)
                  );

                  alert("Journal entry successfully deleted.");
              } else {
                  args.cancel = true; // Cancel the delete operation if user declines
              }
          } catch (error) {
              console.error("Error deleting document:", error);
              alert("An error occurred while deleting the journal entry.");
          }
      }
  };

  const handleSaveJournal = async (e) => {
    if (e.key === "Enter") {
      const description = e.target.value.trim();
      if (!description) return;

      const now = new Date();

      const journalEntry = {
        description,
        journalId: generateJournalId(),
        userId,
        userRole,
        editorId: userId,
        editorType: userRole,
        timestamp: serverTimestamp(),
        inserted_at: serverTimestamp(),
      };

      try {
        const docRef = await addDoc(collection(db, "disaster-journals"), journalEntry);
        setDisasterJournals((prevJournals) => [...prevJournals, { ...journalEntry, id: docRef.id }]);
        e.target.value = ""; // Clear input after saving
      } catch (error) {
        console.error("Error saving journal entry:", error);
      }
    }
  };

  const dateTemplate = (props) => {
    if (!props.timestamp) return null;
    
    const dateObj = props.timestamp.toDate(); // Convert Firestore timestamp to Date object
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' }).toUpperCase();
    const year = dateObj.getFullYear();

    return (
        <div className="flex">
            <div className="flex items-center space-x-1">
                <span
                    className={`w-6 h-6 rounded-full bg-${getEditorTypeColor(props.editorType)} flex items-center justify-center text-white text-xs font-bold`}
                    title={`Editor Type: ${getEditorTypeLabel(props.editorType)}`}
                >
                    {getEditorTypeLetter(props.editorType)}
                </span>
            </div>
            <div className="flex w-[35px] flex-col items-start justify-start ml-2">
                <a className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]">
                    {day}
                </a>
                <b className="relative flex w-[34px] items-center text-xs">
                    {month}
                </b>
                <a className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]" style={{ fontSize: '10px' }}>
                    {year}
                </a>
            </div>
        </div>
    );
};


  return (
    <div
        className={` lex w-[1628px] max-w-full flex-col items-start justify-start bg-white text-left leading-[normal] tracking-[normal] text-heading-title-color ${className}`}
    >
        <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
           <p>
              The Disaster Journal provides a space for you to share your personal
              experiences and reflections throughout the recovery process. Your
              story is a vital part of understanding the full impact of the disaster
              and will help shape future recovery efforts. Please take the time to
              document your journey and insights.
           </p>
        </div>
        <div>
           <h2 className="mb-1 text-sm font-bold text-blue-500 mb-4 mt-2">
              JOURNAL
           </h2>
        </div>
        <DisasterJournalList disasterJournals={disasterJournals} />
        <div className="w-full rounded-lg p-3 bg-blue-100 mt-auto">
          <input
            type="text"
            placeholder="Recording your experiences can be a powerful way to document what you’ve been through. Write it here."
            className="w-full h-full bg-blue-100 border-none outline-none"
            onKeyDown={handleSaveJournal}
          />
        </div>


    </div>
  );
};

const DisasterJournalList = ({ disasterJournals }) => {
  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return '';
    
    const date = timestamp.toDate();
      return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
    };
    const sortedJournals = [...disasterJournals].sort((a, b) => {
      if (!a.timestamp || !b.timestamp) return 0;
      return a.timestamp.seconds - b.timestamp.seconds;
    });
    return (
      <div className="disaster-journals">
        {sortedJournals.map((entry, index) => (
          <div key={index} className="mb-4">
            <div className="w-full rounded-lg p-3 bg-gray-200">
              <div className="text-left mb-2">
                <p style={{ fontSize: '12px' }}>{entry.description}</p>
              </div>
              <div className="text-right">
                <span className="text-xs text-gray-500">
                  {formatTimestamp(entry.timestamp)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
export default Journals;