import React, { useState, useEffect, useRef } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  Edit,
  CommandColumn,
  Toolbar,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { Query } from "@syncfusion/ej2-data";
import { USStates } from "../../datasource";
import { Counties } from "../../datasource";
import DisasterDetail from "./DisasterDetail";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";

const DisasterDeclaration = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState({
    allowDeleting: true,
    allowAdding: true,
    allowEditing: true,
    mode: "Dialog",
    showDeleteConfirmDialog: true,
    dialog: { cssClass: "custom-dialog" },
  });
  const [toolbarOptions, setToolbarOptions] = useState([
    "Add",
    "Edit",
    "Delete",
  ]);
  const pageSettings = { pageSize: 10 };

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];
  const [userRole, setUserRole] = useState("");
  const [userData, setUserData] = useState("");
  const [canDeclareDisaster, setCanDeclareDisaster] = useState(false);
  const [canApproveDisaster, setCanApproveDisaster] = useState(false);
  const navigate = useNavigate();
  const activeDisasterGridRef = useRef(null);
  const [userName, setUserName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showActiveGrid, setShowActiveGrid] = useState(true);
  const [selectedDisasterId, setSelectedDisasterId] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.user_role);
          setUserData(userData);
          setCanDeclareDisaster(userData.can_declare_disaster || false);
          setCanApproveDisaster(userData.can_approve_disaster || false);
          setUserName(`${userData.first_name} ${userData.last_name}`);
          if (userData.user_role === "center") {
            setEditing({
              allowEditing: false,
              allowAdding: false,
              allowDeleting: false,
              mode: "Dialog",
              dialog: { cssClass: "custom-dialog" },
            });
            setToolbarOptions([]);
          }
        }
      }
    };
    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "declared-disaster"));
      const fetchedData = querySnapshot.docs
        .map((doc) => {
          const data = doc.data();
          const formatDate = (date) =>
            date
              ? new Date(date.seconds * 1000).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : null;

          return {
            ...data,
            disaster_id: doc.id,
            disaster_date: formatDate(data.disaster_date),
            start_date: formatDate(data.start_date),
            end_date: formatDate(data.end_date),
            date_of_disaster: formatDate(data.date_of_disaster),
            date_declared: formatDate(data.date_declared),
            approved_date: formatDate(data.approved_date),
          };
        })
        .filter((item) => item.is_deleted != true);

      console.log("fetchedData", fetchedData);
      setData(fetchedData);
      setFilteredData(
        fetchedData.filter((item) => item.disaster_status === "active")
      );
    };
    fetchData();
  }, []);

  const toLocaleDateString = (date) => {
    return date
      ? new Date(date).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
      : null;
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      let sanitizedData = sanitizeData(args.data);

      if (args.action === "add" || args.action === "edit") {
        switch (sanitizedData.disaster_stage) {
          case "Response":
            sanitizedData.response_start_date = args.data.start_date;
            sanitizedData.response_end_date = args.data.end_date;
            break;
          case "Recovery":
            sanitizedData.recovery_start_date = args.data.start_date;
            sanitizedData.recovery_end_date = args.data.end_date;
            break;
          case "Relief":
            sanitizedData.relief_start_date = args.data.start_date;
            sanitizedData.relief_end_date = args.data.end_date;
            break;
          default:
        }

        if (args.action === "add") {
          await addDisaster(sanitizedData);
        } else if (args.action === "edit") {
          await editDisaster(sanitizedData);
        }
      }
      const querySnapshot = await getDocs(collection(db, "declared-disaster"));
      const fetchedData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          disaster_id: doc.id,
          disaster_date: toLocaleDateString(data.disaster_date),
        };
      });
      setData(fetchedData);
      handleGridToggle("Active");
    } else if (args.requestType === "delete") {
      await deleteDisaster(args.data[0]);
    }
  };

  const addDisaster = async (data) => {
    const disasterId = generateUniqueID();
    await setDoc(doc(db, "declared-disaster", disasterId), {
      ...data,
      disaster_id: disasterId,
    });
  };

  const editDisaster = async (data) => {
    await updateDoc(doc(db, "declared-disaster", data.disaster_id), data);
  };

  const deleteDisaster = async (data) => {
    await updateDoc(doc(db, "declared-disaster", data.disaster_id), {
      is_deleted: true,
    });
    console.log("deleted", data);
  };

  const handleGridToggle = (status) => {
    setShowActiveGrid(status === "Active");

    const filtered = data.filter(
      (item) =>
        item.is_deleted !== true &&
        item.disaster_status.toLowerCase() === status.toLowerCase()
    );

    setFilteredData(filtered);
  };

  const approveDisaster = async (rowData) => {
    try {
      await updateDoc(doc(db, "declared-disaster", rowData.disaster_id), {
        disaster_status: "active",
        approved_by: userName,
        approved_date: new Date(),
      });
      const updatedData = data.map((item) =>
        item.disaster_id === rowData.disaster_id
          ? {
              ...item,
              disaster_status: "active",
              approved_by: userName,
              approved_date: new Date(),
            }
          : item
      );
      setData(updatedData);
      handleGridToggle("Inactive");
      handleGridToggle("Active");
    } catch (error) {
      console.error("Error updating disaster status:", error);
    }
  };

  const [clickedCommand, setClickedCommand] = useState(false);
  const commandClick = (args) => {
    setClickedCommand(true);

    const selectedData = args.rowData;

    if (args.commandColumn.type === "Delete") {
      updateDoc(doc(db, "declared-disaster", selectedData.disaster_id), {
        is_deleted: true,
      });
    } else if (args.commandColumn.type === "Approve") {
      console.log("approveDisaster", selectedData);
      updateDoc(doc(db, "declared-disaster", selectedData.disaster_id), {
        disaster_status: "active",
      });
      approveDisaster(selectedData);
    }
  };

  const rowSelected = (args) => {
    if (clickedCommand) {
      setClickedCommand(false);
      return;
    }

    const selectedData = args.data;
    navigate(`/disasterdeclaration/list`, {
      state: { selectedDisasterId: selectedData.disaster_id },
    });
  };

  const sanitizeData = (data) => {
    const sanitizedData = {
      ...data,
      disaster_id: data.disaster_id,
      disaster_county: data.disaster_county || "",
      disaster_date: data.disaster_date || "",
      disaster_name: data.disaster_name || "",
      description: data.description || "",
      disaster_state: data.disaster_state || "",
      disaster_status: data.disaster_status || "inactive",
      disaster_stage: data.disaster_stage || "",
      start_date: data.start_date || "",
      end_date: data.end_date || "",
      response_end_date: data.response_end_date || "",
      response_start_date: data.response_start_date || "",
      relief_start_date: data.relief_start_date || "",
      relief_end_date: data.relief_end_date || "",
      recovery_start_date: data.recovery_start_date || "",
      recovery_end_date: data.recovery_end_date || "",
      date_of_disaster: data.date_of_disaster || "",
      date_declared: data.date_declared || "",
      is_federal: data.is_federal || "",
      declared_by: data.declared_by || "",
      approved_by: data.approved_by || "",
      approved_date: data.approved_date || "",
    };
    Object.keys(sanitizedData).forEach(
      (key) => sanitizedData[key] === undefined && delete sanitizedData[key]
    );

    return sanitizedData;
  };
  const generateUniqueID = () => {
    return (
      Math.random().toString(36).substr(2, 10).toUpperCase() +
      Math.random().toString(36).substr(2, 10).toLowerCase()
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "declared-disaster"));
      const fetchedData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        disaster_id: doc.id,
      }));
      setData(fetchedData);
    };
    fetchData();
  }, []);

  const handleDeclareDisasterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDisasterSubmit = async (disasterData) => {
    try {
      const disasterId = generateUniqueID();
      await setDoc(doc(db, "declared-disaster", disasterId), {
        ...disasterData,
        disaster_id: disasterId,
        disaster_status: "inactive",
      });
      setData((prevData) => [
        ...prevData,
        { ...disasterData, disaster_id: disasterId },
      ]);
    } catch (error) {
      console.error("Error declaring disaster:", error);
    }
    setIsModalOpen(false);
  };

  const DeclareDisasterModal = ({ isOpen, onClose, onSubmit }) => {
    const [disasterData, setDisasterData] = useState({
      disaster_name: "",
      description: "",
      disaster_type: "",
      disaster_stage: "Response",
      disaster_status: "inactive",
      disaster_state: [],
      affected_counties: [],
      declared: [],
      declared_by: userName,
      approved_by: "",
      date_declared: new Date(),
      approved_date: "",
      start_date: "",
      end_date: "",
    });

    const handleChange = (field, value) => {
      setDisasterData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    };

    const handleFormSubmit = (e) => {
      e.preventDefault();
      onSubmit(disasterData);
      setDisasterData({
        disaster_name: "",
        description: "",
        disaster_type: "",
        disaster_stage: "Response",
        disaster_status: "inactive",
        disaster_state: [],
        affected_counties: [],
        declared: [],
        declared_by: userName,
        approved_by: "",
        date_declared: new Date(),
        approved_date: "",
        start_date: "",
        end_date: "",
      });
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
        <div className="animate-fade-in">
          <div className="w-[1200px] rounded-t-lg bg-gradient-to-r from-red-700 to-red-600 p-4 shadow-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="text-2xl mr-2 animate-pulse">⚠️</span>
                <p className="text-2xl font-bold tracking-wide text-white">
                  DECLARE DISASTER EMERGENCY
                </p>
              </div>
              <div
                onClick={onClose}
                className=" text-black hover:cursor-pointer focus:outline-none"
              >
                <span className="text-3xl h-10 w-10">&times;</span>
              </div>
            </div>
          </div>
          <div className="w-[1200px] rounded-b-lg bg-white p-8 shadow-xl">
            <form onSubmit={handleFormSubmit}>
              {/* Basic Disaster Info */}
              <div className="mb-2 grid grid-cols-2 gap-2">
                <div>
                  <label className="mb-2 block text-sm font-bold text-gray-800">
                    Disaster Name
                  </label>
                  <TextBoxComponent
                    value={disasterData.disaster_name}
                    change={(e) => handleChange("disaster_name", e.value)}
                    placeholder="Enter disaster name"
                    required
                    cssClass="e-outline border-red-200 hover:border-red-400"
                  />
                </div>
                <div>
                  <label className="mb-2 block text-sm font-bold text-gray-800">
                    Type of Disaster
                  </label>
                  <DropDownListComponent
                    dataSource={[
                      "Fire",
                      "Flood",
                      "Tornado",
                      "Hurricane",
                      "Earthquake",
                    ]}
                    placeholder="Select disaster type"
                    value={disasterData.disaster_type}
                    change={(e) => handleChange("disaster_type", e.value)}
                    cssClass="e-outline border-red-200 hover:border-red-400"
                    required
                  />
                </div>
              </div>

              <div className="mb-2 w-full">
                <label className="mb-2 block text-sm font-bold text-gray-800">
                  Description
                </label>
                <div className="relative w-full">
                  <TextAreaComponent
                    value={disasterData.description}
                    change={(e) => handleChange("description", e.value)}
                    placeholder="Enter disaster description"
                    required
                    cssClass="e-outline border-red-200 hover:border-red-400"
                    width="1130px"
                    height="auto"
                    multiline={true}
                    resizeMode="Vertical"
                  />
                </div>
              </div>

              {/* Location Information */}
              <div className="mb-2 grid grid-cols-2 gap-6">
                <div>
                  <label className="mb-2 block text-sm font-bold text-gray-800">
                    Affected States
                  </label>
                  <MultiSelectComponent
                    dataSource={USStates}
                    fields={{ text: "name", value: "name" }}
                    placeholder="Select affected states"
                    value={disasterData.disaster_state}
                    change={(e) => handleChange("disaster_state", e.value)}
                    selectAllText="Select All"
                    unSelectAllText="Unselect All"
                    showSelectAll={true}
                    cssClass="e-outline border-red-200 hover:border-red-400"
                    mode="Box"
                    required
                  />
                </div>
                <div>
                  <label className="mb-2 block text-sm font-bold text-gray-800">
                    Affected Counties
                  </label>
                  <MultiSelectComponent
                    dataSource={Counties}
                    placeholder="Select affected counties"
                    value={disasterData.affected_counties}
                    change={(e) => handleChange("affected_counties", e.value)}
                    mode="CheckBox"
                    selectAllText="Select All"
                    unSelectAllText="Unselect All"
                    showSelectAll={true}
                    cssClass="e-outline border-red-200 hover:border-red-400"
                    required
                  >
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </div>
              </div>

              {/* Timeline */}
              <div className="mb-2 grid grid-cols-2 gap-6">
                <div>
                  <label className="mb-2 block text-sm font-bold text-gray-800">
                    Start Date
                  </label>
                  <DatePickerComponent
                    placeholder="Select start date"
                    value={disasterData.start_date}
                    change={(e) => handleChange("start_date", e.value)}
                    cssClass="e-outline border-red-200 hover:border-red-400"
                    required
                  />
                </div>
                <div>
                  <label className="mb-2 block text-sm font-bold text-gray-800">
                    End Date
                  </label>
                  <DatePickerComponent
                    placeholder="Select end date"
                    value={disasterData.end_date}
                    change={(e) => handleChange("end_date", e.value)}
                    cssClass="e-outline border-red-200 hover:border-red-400"
                    required
                  />
                </div>
              </div>

              {/* Response Organizations */}
              <div className="mb-2">
                <label className="mb-2 block text-sm font-bold text-gray-800">
                  Disaster Response and Relief Organizations
                </label>
                <MultiSelectComponent
                  dataSource={["FEMA", "Red Cross", "State"]}
                  placeholder="Select response and relief organizations"
                  value={disasterData.declared}
                  change={(e) => handleChange("declared", e.value)}
                  showSelectAll={true}
                  allowFiltering={true}
                  mode="Box"
                  cssClass="e-outline border-red-200 hover:border-red-400"
                />
              </div>

              <div className="mt-8 flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="btn-cancel mr-3 px-6 py-2 hover:cursor-pointer"
                >
                  Cancel
                </button>
                <div
                  type="submit"
                  onClick={handleFormSubmit}
                  className="animate-pulse rounded-md bg-gradient-to-r from-red-700 to-red-600 px-8 py-2 font-bold text-white shadow-lg transition-all hover:cursor-pointer hover:from-red-800 hover:to-red-700 hover:shadow-red-400/50"
                >
                  DECLARE EMERGENCY
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-2 !font-inter">
      <DeclareDisasterModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleDisasterSubmit}
      />

      <nav className="mt-4 flex justify-between space-x-2 !font-inter">
        <div className="flex w-24 flex-row items-start justify-start gap-1.5 text-xs text-dodgerblue">
          <p className="relative font-medium  [text-decoration:none]">
            Disasters
          </p>
          <div className="relative font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <span className="relative font-medium leading-[16px]">
            {showActiveGrid ? "ACTIVE" : "INACTIVE"}
          </span>
        </div>
      </nav>
      <div className="relative mb-2 mt-2 inline-block max-w-full font-[Inter] font-medium leading-[21px] text-darkslategray-200">
        <p>
          Declaring and Approving Disaster can only be done by authorized users.
        </p>
      </div>
      {/* Grid for Active Disasters */}
      {showActiveGrid && (
        <section className="bg-white pb-5 text-left text-xs text-darkslateblue">
          <div>
            <div className="mb-1 flex flex-row items-center justify-between">
              <p className="text-left text-sm font-bold text-blue-600">
                ACTIVE DISASTERS
              </p>
              <div className="flex items-center gap-2">
                {canDeclareDisaster && (
                  <button
                    type="button"
                    className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-red-600 px-[9px] py-[5px] [border:none]"
                    onClick={handleDeclareDisasterClick}
                  >
                    <div className="flex flex-col items-center justify-start px-0 pb-0 pt-px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z"
                          fill="white"
                        />
                        <path
                          d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z"
                          fill="white"
                        />
                        <path
                          opacity="0.4"
                          d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p
                      className="relative my-auto inline-block text-left !font-inter text-xs font-semibold text-white [text-decoration:none]"
                      style={{ fontFamily: "Inter" }}
                    >
                      Declare Disaster
                    </p>
                  </button>
                )}
                <div className="flex gap-2 text-xs">
                  <button
                    className={
                      showActiveGrid
                        ? "btn-default rounded-full bg-[#467BA4] px-[9px] py-[5px] text-white"
                        : "btn-default-border rounded-full border border-dodgerblue px-[9px] py-[5px] text-[#467BA4] hover:bg-blue-100"
                    }
                    onClick={() => handleGridToggle("Active")}
                  >
                    Active
                  </button>
                  <button
                    className={
                      !showActiveGrid
                        ? "btn-default rounded-full bg-[#467BA4] px-[9px] py-[5px] text-white"
                        : "btn-default-border rounded-full border border-dodgerblue px-[9px] py-[5px] text-[#467BA4] hover:bg-blue-100"
                    }
                    onClick={() => handleGridToggle("Inactive")}
                  >
                    Inactive
                  </button>
                </div>
              </div>
            </div>
            <GridComponent
              dataSource={filteredData}
              allowSorting={true}
              sortSettings={sortSettings}
              actionBegin={actionBegin}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              editSettings={{
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog",
              }}
              ref={activeDisasterGridRef}
              commandClick={commandClick}
              rowSelected={rowSelected}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="disaster_id"
                  headerText="Disaster ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="disaster_name"
                  headerText="Name"
                  textAlign="Left"
                  editType="stringedit"
                />
                <ColumnDirective
                  field="description"
                  headerText="Description"
                  textAlign="Left"
                  editType="stringedit"
                />
                <ColumnDirective
                  field="disaster_type"
                  headerText="Type"
                  width="150"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: [
                        { text: "Fire", value: "Fire" },
                        { text: "Flood", value: "Flood" },
                        { text: "Tornado", value: "Tornado" },
                        { text: "Hurricane", value: "Hurricane" },
                        { text: "Earthquake", value: "Earthquake" },
                      ],
                      fields: { text: "text", value: "value" },
                      query: new Query(),
                    },
                  }}
                />
                <ColumnDirective
                  field="disaster_state"
                  headerText="State"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: USStates,
                      fields: { text: "name", value: "name" },
                      query: new Query(),
                    },
                  }}
                />
                <ColumnDirective
                  field="affected_counties"
                  headerText="Affected"
                  textAlign="Left"
                  editType="multiselectedit"
                  edit={{
                    params: {
                      dataSource: [],
                      mode: "Box",
                    },
                  }}
                />
                <ColumnDirective
                  field="date_of_disaster"
                  headerText="Date of Disaster"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="date_declared"
                  headerText="Date Declared"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="declared_by"
                  headerText="Declared By"
                  editType="stringedit"
                  defaultValue={userName}
                />
                <ColumnDirective
                  field="approved_date"
                  headerText="Approved Date"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="approved_by"
                  headerText="Approved By"
                  editType="stringedit"
                  visible={false}
                />
                <ColumnDirective
                  field="disaster_stage"
                  headerText="Disaster Stage"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: [
                        { text: "Response", value: "Response" },
                        { text: "Recovery", value: "Recovery" },
                        { text: "Relief", value: "Relief" },
                      ],
                      fields: { text: "text", value: "value" },
                      query: new Query(),
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="start_date"
                  headerText="Start Date"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="end_date"
                  headerText="End Date"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="declared"
                  headerText="Declared"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: [
                        { text: "FEMA", value: "FEMA" },
                        { text: "Red Cross", value: "Red Cross" },
                        { text: "State", value: "State" },
                      ],
                      fields: { text: "text", value: "value" },
                      query: new Query(),
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="added_type"
                  headerText="Added Type"
                  visible={false}
                  defaultValue="Entered"
                />
                <ColumnDirective
                  field="disaster_status"
                  headerText="Status"
                  textAlign="Left"
                  defaultValue="inactive"
                  visible={false}
                />

                <ColumnDirective
                  headerText="Actions"
                  width="80"
                  commands={[
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Sort,
                  Edit,
                  CommandColumn,
                  Toolbar,
                  Filter,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        </section>
      )}

      {/* Grid for Inactive Disasters */}
      {!showActiveGrid && (
        <section className="bg-white pb-5 text-left text-xs text-darkslateblue">
          <div>
            <div className="mb-1 flex flex-row items-center justify-between">
              <p className="text-left text-sm font-bold text-blue-600">
                INACTIVE DISASTERS
              </p>
              <div className="flex items-center gap-2">
                {canDeclareDisaster && (
                  <button
                    type="button"
                    className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-red-600 px-[9px] py-[5px] [border:none]"
                    onClick={handleDeclareDisasterClick}
                  >
                    <div className="flex flex-col items-center justify-start px-0 pb-0 pt-px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z"
                          fill="white"
                        />
                        <path
                          d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z"
                          fill="white"
                        />
                        <path
                          opacity="0.4"
                          d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p className="relative my-auto inline-block text-left text-xs font-semibold text-white [text-decoration:none]">
                      Declare Disaster
                    </p>
                  </button>
                )}
                <div className="flex gap-2 text-xs">
                  <button
                    className={
                      showActiveGrid
                        ? "btn-default rounded-full bg-[#467BA4] px-[9px] py-[5px] text-white"
                        : "btn-default-border rounded-full border border-dodgerblue px-[9px] py-[5px] text-[#467BA4] hover:bg-blue-100"
                    }
                    onClick={() => handleGridToggle("Active")}
                  >
                    Active
                  </button>
                  <button
                    className={
                      !showActiveGrid
                        ? "btn-default rounded-full bg-[#467BA4] px-[9px] py-[5px] text-white"
                        : "btn-default-border rounded-full border border-dodgerblue px-[9px] py-[5px] text-[#467BA4] hover:bg-blue-100"
                    }
                    onClick={() => handleGridToggle("Inactive")}
                  >
                    Inactive
                  </button>
                </div>
              </div>
            </div>
            <GridComponent
              dataSource={filteredData}
              allowSorting={true}
              sortSettings={sortSettings}
              actionBegin={actionBegin}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              editSettings={{
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog",
              }}
              ref={activeDisasterGridRef}
              commandClick={commandClick}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="disaster_id"
                  headerText="Disaster ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="disaster_name"
                  headerText="Name"
                  textAlign="Left"
                  editType="stringedit"
                />
                <ColumnDirective
                  field="description"
                  headerText="Description"
                  textAlign="Left"
                  editType="stringedit"
                />
                <ColumnDirective
                  field="disaster_type"
                  headerText="Type"
                  width="150"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: [
                        { text: "Fire", value: "Fire" },
                        { text: "Flood", value: "Flood" },
                        { text: "Tornado", value: "Tornado" },
                        { text: "Hurricane", value: "Hurricane" },
                        { text: "Earthquake", value: "Earthquake" },
                      ],
                      fields: { text: "text", value: "value" },
                      query: new Query(),
                    },
                  }}
                />
                <ColumnDirective
                  field="disaster_state"
                  headerText="State"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: USStates,
                      fields: { text: "name", value: "name" },
                      query: new Query(),
                    },
                  }}
                />
                <ColumnDirective
                  field="affected_counties"
                  headerText="Affected"
                  textAlign="Left"
                  editType="multiselectedit"
                  edit={{
                    params: {
                      dataSource: [],
                      mode: "Box",
                    },
                  }}
                />
                <ColumnDirective
                  field="date_of_disaster"
                  headerText="Date of Disaster"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="date_declared"
                  headerText="Date Declared"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="declared_by"
                  headerText="Declared By"
                  editType="stringedit"
                  defaultValue={userName}
                />
                <ColumnDirective
                  field="approved_date"
                  headerText="Approved Date"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="approved_by"
                  headerText="Approved By"
                  editType="stringedit"
                  visible={false}
                />
                <ColumnDirective
                  field="disaster_stage"
                  headerText="Disaster Stage"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: [
                        { text: "Response", value: "Response" },
                        { text: "Recovery", value: "Recovery" },
                        { text: "Relief", value: "Relief" },
                      ],
                      fields: { text: "text", value: "value" },
                      query: new Query(),
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="start_date"
                  headerText="Start Date"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="end_date"
                  headerText="End Date"
                  editType="datepickeredit"
                  format="MM/dd/yyyy"
                  edit={{
                    params: {
                      format: "MM/dd/yyyy",
                      showClearButton: true,
                      showTodayButton: true,
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="declared"
                  headerText="Declared"
                  textAlign="Left"
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: [
                        { text: "FEMA", value: "FEMA" },
                        { text: "Red Cross", value: "Red Cross" },
                        { text: "State", value: "State" },
                      ],
                      fields: { text: "text", value: "value" },
                      query: new Query(),
                    },
                  }}
                  visible={false}
                />
                <ColumnDirective
                  field="added_type"
                  headerText="Added Type"
                  visible={false}
                  defaultValue="Entered"
                />
                <ColumnDirective
                  field="disaster_status"
                  headerText="Status"
                  textAlign="Left"
                  defaultValue="inactive"
                  visible={false}
                />

                <ColumnDirective
                  headerText="Actions"
                  width="170"
                  commands={[
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    ...(canApproveDisaster
                      ? [
                          {
                            buttonOption: {
                              content: "Approve",
                              cssClass: "e-outline custom-button",
                            },
                            type: "Approve",
                          },
                        ]
                      : []),
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Page,
                  Sort,
                  Edit,
                  CommandColumn,
                  Toolbar,
                  Filter,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        </section>
      )}
    </div>
  );
};

export default DisasterDeclaration;
