import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import placeholderImage from "../../icons/Opportunities/opportunities.png";
import { useEffect } from "react";
import { db } from "../../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  updateDoc,
  where,
  query,
  getDocs,
  doc,
  getFirestore,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const PartnerJobOpportunities = ({ className = "", onNavigateBack }) => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [currentJobId, setCurrentJobId] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");

  const [modalState, setModalState] = useState({
    isOpen: false,
    mode: null,
    job: null,
  });

  const filteredJobs = jobs.filter((job) =>
    job.job.title.toLowerCase().includes(searchTitle.toLowerCase())
  );

  const handleJobSubmit = (jobData) => {
    if (modalState.mode === "add") {
      setJobs((prevJobs) => [...prevJobs, { job: jobData }]);
    } else if (modalState.mode === "edit") {
      setJobs((prevJobs) =>
        prevJobs.map((j) => (j.job.id === jobData.id ? { job: jobData } : j))
      );
    }
    setModalState({ isOpen: false, mode: null, job: null });
  };

  const openConfirmationModal = (type, jobId) => {
    setConfirmationType(type);
    setCurrentJobId(jobId);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setConfirmationType("");
    setCurrentJobId(null);
  };

  const handleDeleteJob = async () => {
    try {
      await updateDoc(doc(db, "opportunities", currentJobId), {
        is_deleted: true,
      });
      setJobs(jobs.filter((job) => job.job.id !== currentJobId));
      console.log(`Job with ID ${currentJobId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting job:", error);
    } finally {
      closeConfirmationModal();
    }
  };

  const handleToggleClose = async (jobId) => {
    try {
      const jobToUpdate = jobs.find((job) => job.job.id === jobId);
      if (!jobToUpdate) {
        throw new Error("Job not found");
      }
      const updatedCloseStatus = !jobToUpdate.job.closed;
      const jobDocRef = doc(db, "opportunities", jobId);
      await updateDoc(jobDocRef, { closed: updatedCloseStatus });

      fetchJobs();
    } catch (error) {
      console.error("Error toggling job close status:", error);
    }
  };

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return "$0.00";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const convertToDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "N/A";

    const date = timestamp.toDate();
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const db = getFirestore();

  const fetchJobs = async () => {
    if (!userData.id) {
      setIsLoading(false);
    }

    try {
      setIsLoading(true);
      const userIds = [userData.id, userData.citizen_id].filter((id) => id);

      if (userIds.length > 0) {
        const jobsQuery = query(
          collection(db, "opportunities"),
          where("user_id", "in", userIds),
          where("type", "==", "job"),
          where("is_deleted", "==", false)
        );

        const querySnapshot = await getDocs(jobsQuery);
        const fetchedJobs = querySnapshot.docs.map((doc) => ({
          job: doc.data(),
        }));

        console.log("fetchedJobs: ", fetchedJobs);
        setJobs(fetchedJobs);
      } else {
        console.log("No valid user IDs found");
        setJobs([]);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [userData]);

  useEffect(() => {
    if (jobs.length > 0 && !selectedJob) {
      setSelectedJob(jobs[0].job);
    }
  }, [jobs]);

  const JobCard = ({ job }) => {
    const [isPublished, setIsPublished] = useState(job.published || false);
    const [applicationsCount, setApplicationsCount] = useState(0);
    const isSelected = selectedJob && selectedJob.id === job.id;

    const fetchApplicationsCount = async () => {
      try {
        const applicationsRef = collection(
          db,
          "opportunities",
          job.id,
          "applications"
        );
        const applicationsSnapshot = await getDocs(applicationsRef);
        setApplicationsCount(applicationsSnapshot.size);
      } catch (error) {
        console.error("Error fetching applications count:", error);
      }
    };

    useEffect(() => {
      fetchApplicationsCount();
    }, [job.id]);

    const handleTogglePublished = async (checked) => {
      setIsPublished(checked);

      try {
        const jobDocRef = doc(db, "opportunities", job.id);
        await updateDoc(jobDocRef, { published: checked });
        console.log(
          `Job ${checked ? "published" : "unpublished"} successfully.`
        );
      } catch (error) {
        console.error("Error updating published status:", error);
      }
    };

    return (
      <div
        className={`flex w-full flex-col items-end justify-start gap-[5px] rounded-mini border bg-white ${
          isSelected ? "job-card-selected" : "job-card"
        }`}
        onClick={() => setSelectedJob(job)}
      >
        <div className="w-full px-4 py-2">
          <div className="flex flex-col gap-1">
            <div className="flex w-full justify-between">
              <div className="w-2/3 space-y-1">
                <b className="block truncate text-base">{job.title}</b>
                <div className="flex items-center gap-1">
                  <span className="text-xs font-medium">
                    Application Deadline:
                  </span>
                  <span className="text-xs">
                    {convertToDate(job.applicationDeadline)}
                  </span>
                </div>
                <p className="text-xs">{job.location}</p>

                <div className="flex w-fit rounded bg-gray-300 p-2 text-xs font-semibold">
                  {job.jobType}
                </div>
              </div>
              <img
                className="z-[1] h-[75px] w-[75px] object-cover"
                alt={job.title}
                src={job.backgroundImage || placeholderImage}
              />
            </div>
            <div className="text-sm">
              {job.jobDescription.length > 220
                ? `${job.jobDescription.substring(0, 220)}...`
                : job.jobDescription}
            </div>
          </div>
        </div>

        <div className="w-full px-4 pb-2">
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <span className="text-xs font-medium">Applicants:</span>
                <span className="text-xs">{applicationsCount}</span>
              </div>
              <div className="flex items-center gap-1">
                <p className="text-xs">Publish</p>
                <SwitchComponent
                  cssClass="e-switch-toggle"
                  checked={isPublished}
                  change={(e) => handleTogglePublished(e.checked)}
                />
              </div>
            </div>

            <button
              onClick={(e) => {
                e.stopPropagation();
                handleToggleClose(job.id);
              }}
              className="btn-default h-10 w-full hover:bg-opacity-90"
            >
              {job.closed ? "Open Job" : "Close Job"}
            </button>

            <div className="flex gap-2">
              <button
                className="btn-cancel flex flex-1 items-center justify-center gap-1 px-3 py-1.5"
                onClick={(e) => {
                  e.stopPropagation();
                  openConfirmationModal("delete", job.id);
                }}
              >
                Delete
              </button>
              <button
                className="btn-default-border flex flex-1 items-center justify-center gap-1 px-3 py-1.5"
                onClick={() =>
                  setModalState({
                    isOpen: true,
                    mode: "edit",
                    job: selectedJob,
                  })
                }
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const JobModal = ({ isOpen, onClose, onSubmit, job = null, mode }) => {
    const isEditMode = mode === "edit";

    const [formData, setFormData] = useState(
      job || {
        title: "",
        companyName: "",
        location: "",
        jobType: "Full-time",
        jobDescription: "",
        qualifications: "",
        educationRequirements: "",
        experienceRequired: null,
        salary: null,
        benefits: "",
        applicationDeadline: new Date(),
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        center_id: userData?.center_id || "",
        user_id: userData?.id || userData?.citizen_id || "",
        date: new Date(),
        is_deleted: false,
        published: false,
        closed: false,
        type: "job",
        backgroundImage: "",
      }
    );

    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
      if (isEditMode && job) {
        setFormData({
          ...job,
          applicationDeadline: job.applicationDeadline?.toDate
            ? job.applicationDeadline.toDate()
            : new Date(),
        });
      }
    }, [job, isEditMode]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleDropDownChange = (e) => {
      setFormData({ ...formData, jobType: e.value });
    };

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (!file) return;

      setIsUploading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `backgroundImages/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("File upload error:", error);
          setIsUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData({ ...formData, backgroundImage: downloadURL });
          setIsUploading(false);
        }
      );
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        const jobData = {
          ...formData,
          center_id: userData?.center_id || "",
          user_id: userData?.id || userData?.citizen_id || "",
          type: "job",
        };

        if (isEditMode) {
          const jobDocRef = doc(db, "opportunities", formData.id);
          await updateDoc(jobDocRef, jobData);
        } else {
          const jobDocRef = doc(collection(db, "opportunities"));
          jobData.id = jobDocRef.id;
          await setDoc(jobDocRef, jobData);
        }

        onSubmit(jobData);
        fetchJobs();
        onClose();
      } catch (error) {
        console.error("Error saving job:", error);
      }
    };

    if (!isOpen) return null;

    const jobTypeOptions = [
      { text: "Full-time", value: "Full-time" },
      { text: "Part-time", value: "Part-time" },
      { text: "Contract", value: "Contract" },
      { text: "Temporary", value: "Temporary" },
      { text: "Internship", value: "Internship" },
    ];

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="w-[35%] rounded-lg bg-white shadow-lg">
          <div className="flex items-center justify-between bg-[#467BA4] p-4">
            <h2 className="font-Inter text-[#FDB841]">
              {isEditMode ? "Edit Job Opportunity" : "Add Job Opportunity"}
            </h2>
            <button
              className="font-Inter cursor-pointer bg-[#467BA4] text-[25px] text-[#FDB841]"
              onClick={onClose}
            >
              ×
            </button>
          </div>
          <div className="max-h-[80vh] overflow-y-auto p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div>
                  <TextBoxComponent
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    name="title"
                    placeholder="Job Title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <TextBoxComponent
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    name="companyName"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Location and Salary in same line */}
                <div className="flex gap-2">
                  <div className="w-1/2">
                    <TextBoxComponent
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      name="location"
                      placeholder="Location"
                      value={formData.location}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="w-1/2">
                    <NumericTextBoxComponent
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      name="salary"
                      placeholder="Salary"
                      value={formData.salary}
                      format="C2"
                      max={1000000}
                      min={0}
                      decimals={2}
                      change={(e) =>
                        setFormData({ ...formData, salary: e.value })
                      }
                      required
                      showSpinButton={false}
                    />
                  </div>
                </div>

                {/* Experience Required and Job Type in same line */}
                <div className="flex gap-2">
                  <div className="w-1/2">
                    <NumericTextBoxComponent
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      name="experienceRequired"
                      placeholder="Experience Required (Years)"
                      value={formData.experienceRequired}
                      change={(e) =>
                        setFormData({
                          ...formData,
                          experienceRequired: e.value,
                        })
                      }
                      format="##.##"
                      max={99}
                      min={0}
                      decimals={0}
                      required
                      showSpinButton={false}
                    />
                  </div>
                  <div className="w-1/2">
                    <DropDownListComponent
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      dataSource={jobTypeOptions}
                      fields={{ text: "text", value: "value" }}
                      placeholder="Job Type"
                      value={formData.jobType}
                      change={handleDropDownChange}
                      required
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="w-1/2">
                    <TextBoxComponent
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      name="educationRequirements"
                      placeholder="Education Requirements"
                      value={formData.educationRequirements}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="w-1/2">
                    <DatePickerComponent
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      name="applicationDeadline"
                      placeholder="Application Deadline"
                      value={formData.applicationDeadline}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          applicationDeadline: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>

                {/* Job Description - Full width */}
                <div className="w-full">
                  <TextAreaComponent
                    cssClass="e-outline "
                    floatLabelType="Auto"
                    name="jobDescription"
                    placeholder="Job Description"
                    value={formData.jobDescription}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      height: "100px",
                      boxSizing: "border-box",
                    }}
                    resizeMode="Vertical"
                  />
                </div>

                {/* Qualifications - Full width */}
                <div className="w-full">
                  <TextAreaComponent
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    name="qualifications"
                    placeholder="Qualifications"
                    value={formData.qualifications}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      height: "100px",
                      boxSizing: "border-box",
                    }}
                    resizeMode="Vertical"
                  />
                </div>

                {/* Qualifications - Full width */}
                <div className="w-full">
                  <TextAreaComponent
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    name="benefits"
                    placeholder="Benefits"
                    value={formData.benefits}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      height: "100px",
                      boxSizing: "border-box",
                    }}
                    resizeMode="Vertical"
                  />
                </div>

                {/* Contact Information Section */}
                <div className="space-y-4">
                  <p className="font-Inter text-sm font-semibold text-[#467BA4]">
                    Contact Information
                  </p>
                  <div>
                    <TextBoxComponent
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      name="contactName"
                      placeholder="Contact Name"
                      value={formData.contactName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="flex gap-2">
                    <div className="w-1/2">
                      <TextBoxComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="contactEmail"
                        placeholder="Contact Email"
                        value={formData.contactEmail}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="w-1/2">
                      <TextBoxComponent
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="contactPhone"
                        placeholder="Contact Phone"
                        value={formData.contactPhone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Logo Upload Section */}
                <div>
                  <p className="font-Inter mb-2 text-sm font-semibold text-[#467BA4]">
                    Company Logo
                  </p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    className="text-gray-900 font-Inter block w-full cursor-pointer rounded-lg border border-gray-300 text-sm focus:outline-none"
                  />
                  {isUploading && (
                    <p className="font-Inter mt-2 text-sm text-blue-500">
                      Uploading image...
                    </p>
                  )}
                  {formData.backgroundImage && (
                    <img
                      src={formData.backgroundImage}
                      alt="Background Preview"
                      className="mt-2 h-40 w-full rounded object-cover"
                    />
                  )}
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="btn-cancel font-Inter flex cursor-pointer gap-1 px-4 py-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-default font-Inter flex cursor-pointer gap-1 px-4 py-2"
                >
                  {isEditMode ? "Save Changes" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const ConfirmationModal = ({ type, onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div>
          <div className="bg-red-800 flex w-[800px] justify-between rounded-t-lg p-4">
            <p className="font-Inter font-semibold text-[#FDB841]">
              {type === "delete"
                ? "Delete Job Opportunity"
                : "Close Job Opportunity"}
            </p>
          </div>
          <div className="max-h-[80vh] w-[800px] overflow-y-auto rounded-b-lg bg-white p-5 shadow-lg">
            <div>
              <p className="font-Inter">
                Are you sure you want to{" "}
                {type === "delete"
                  ? "delete this job opportunity?"
                  : "close this job opportunity?"}
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  className="btn-cancel font-Inter flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn-default font-Inter flex cursor-pointer gap-1 px-3 py-1.5"
                  onClick={onConfirm}
                >
                  {type === "delete" ? "Delete" : "Close"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-Inter font-bold text-blue-500">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      {isConfirmationModalOpen && (
        <ConfirmationModal
          type={confirmationType}
          onConfirm={
            confirmationType === "delete" ? handleDeleteJob : handleToggleClose
          }
          onCancel={closeConfirmationModal}
        />
      )}

      <JobModal
        isOpen={modalState.isOpen}
        mode={modalState.mode}
        job={modalState.job}
        onClose={() => setModalState({ isOpen: false, mode: null, job: null })}
        onSubmit={handleJobSubmit}
      />
      <div className="flex w-full flex-col">
        <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <p className="font-Inter relative inline-block min-w-[80px] text-left text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Opportunities
          </p>
          <div className="font-Inter relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <p className="font-Inter relative inline-block min-w-[77px] text-left text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Job Opportunities
          </p>
        </div>

        <div className="flex w-full justify-end">
          <button
            type="button"
            className="btn-default font-Inter flex cursor-pointer gap-1 px-3 py-1.5"
            onClick={() =>
              setModalState({ isOpen: true, mode: "add", job: null })
            }
          >
            <FaPlus className="h-3 w-3" />
            Add New Job
          </button>
        </div>
      </div>
      <div className="flex h-[calc(100vh-150px)] w-full">
        <div className="job-card-cont w-[400px] min-w-[400px] overflow-y-auto border-r border-solid border-gray-300">
          <div className="border-b border-solid border-gray-300 pb-2">
            <input
              type="text"
              placeholder="Search a Job"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              className="font-Inter w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-[#467BA4] focus:outline-none"
            />
          </div>
          <div className="job-card-cont grid grid-cols-1 gap-2">
            {filteredJobs.map((job) => (
              <JobCard key={job.job.id} job={job.job} />
            ))}
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          {selectedJob ? (
            <div className="h-full">
              <div className="w-full">
                <div className="flex h-[150px] items-center justify-between border-b border-solid border-gray-300 p-2 mb-5">
                  <div className="w-full space-y-1">
                    <b className="block truncate text-[25px] text-[#FDB841]">
                      {selectedJob.title}
                    </b>
                    <div className="flex items-center gap-1">
                      <span className="text-base font-medium">
                        Application Deadline:
                      </span>
                      <span className="text-base">
                        {convertToDate(selectedJob.applicationDeadline)}
                      </span>
                    </div>
                    <p className="text-base">{selectedJob.location}</p>

                    <div className="flex w-fit rounded bg-gray-300 p-2 text-base font-semibold">
                      {selectedJob.jobType}
                    </div>
                  </div>
                  <img
                    className="h-[150px] w-auto"
                    alt={selectedJob.title}
                    src={selectedJob.backgroundImage || placeholderImage}
                  />
                </div>
                <div className="mb-5">
                  <div className="font-Inter mb-1 flex items-center gap-1 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter mt-2 font-bold">
                      JOB DESCRIPTION
                    </p>
                  </div>

                  <div className="rounded-lg bg-white px-4">
                    <p className="font-Inter text-left text-black">
                      {selectedJob.jobDescription || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="font-Inter mb-1 flex items-center gap-2 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter font-bold">
                      QUALIFICATIONS
                    </p>
                  </div>

                  <div className="rounded-lg bg-white px-4">
                    <p className="font-Inter text-left text-black">
                      {selectedJob.qualifications || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div className="mb-1 flex justify-between px-4">
                    <div className="flex items-center gap-2">
                      <p className="font-Inter text-sm font-bold">
                        EXPERIENCE REQUIRED:
                      </p>
                      <p className="font-Inter text-black">
                        {selectedJob.experienceRequired || "N/A"} years
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <p className="font-Inter text-sm font-bold">SALARY:</p>
                      <p className="font-Inter text-black">
                        {formatCurrency(selectedJob.salary || "N/A")}
                      </p>
                    </div>
                  </div>

                  <div className="mb-1 flex justify-between px-4">
                    <div className="flex items-center gap-2">
                      <p className="font-Inter text-sm font-bold">
                        EDUCATION REQUIREMENTS:
                      </p>
                      <p className="font-Inter text-black">
                        {selectedJob.educationRequirements || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div className="font-Inter mb-1 flex items-center gap-2 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter font-bold">BENEFITS</p>
                  </div>
                  <div className="rounded-lg bg-white px-6">
                    <p className="font-Inter text-left text-black">
                      {selectedJob.benefits || "N/A"}
                    </p>
                  </div>
                </div>

                <div className="flex w-full gap-2">
                  {/* Contact Information */}
                  <div className="w-1/2">
                    <div className="mb-2 flex items-center gap-2 text-[#467ba4]">
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 7V3H2v18h20V7H12z" />
                      </svg>
                      <p className="text-xl font-Inter font-bold">
                        CONTACT INFORMATION
                      </p>
                    </div>
                    <div className="rounded-lg bg-white px-4">
                      <div className="font-Inter space-y-1 text-left text-black">
                        {[
                          {
                            label: "Contact Name",
                            value: selectedJob.contactName,
                          },
                          {
                            label: "Contact Email",
                            value: selectedJob.contactEmail,
                          },
                          {
                            label: "Contact Phone",
                            value: selectedJob.contactPhone,
                          },
                        ].map((item, index) => (
                          <div key={index} className="flex items-center gap-2">
                            <p className="font-bold">{item.label}:</p>
                            <p>{item.value || "N/A"}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Employer Information */}
                  <div className="w-1/2">
                    <div className="mb-2 flex items-center gap-2 text-[#467ba4]">
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 7V3H2v18h20V7H12z" />
                      </svg>
                      <p className="text-xl font-Inter font-bold">
                        EMPLOYER INFORMATION
                      </p>
                    </div>
                    <div className="rounded-lg bg-white px-4">
                      <div className="font-Inter space-y-1 text-left text-black">
                        {[
                          {
                            label: "Company Name",
                            value: selectedJob.companyName,
                          },
                          { label: "Location", value: selectedJob.location },
                          { label: "Job Type", value: selectedJob.jobType },
                        ].map((item, index) => (
                          <div key={index} className="flex items-center gap-2">
                            <p className="font-bold">{item.label}:</p>
                            <p>{item.value || "N/A"}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="font-Inter flex h-full items-center justify-center text-gray-500">
              Select a job to view details
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PartnerJobOpportunities.propTypes = {
  className: PropTypes.string,
};

export default PartnerJobOpportunities;
