import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  doc,
  setDoc,
  addDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useAuth } from "../components/AuthContext";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import mailer from "../service/api/mailer";
import { countries } from "./datasource";
import { USStates } from "./datasource";
import countiesData from "../counties_list.json";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import bxcheck from "../../src/assets/bx-check.svg";
import bxclose from "../../src/assets/bx-close.svg";
import userIcon from "../../src/icons/Profile/userIcon.png";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject,
} from "@syncfusion/ej2-react-dropdowns";
import { useGeneralContext } from "../context/GeneralContext";
import { encrypt, decrypt } from "../service/encryption/Encryption";

const userType = [
  { value: "Citizen" },
  { value: "Center" },
  { value: "Partner" },
];

const RegisterV2 = () => {
  const { setDupAddress, dupAddressData, setEmailfunc } = useGeneralContext();
  const location = useLocation();
  const [inviteId, setInviteId] = useState(null);
  const [inviteData, setInviteData] = useState(null);
  const [isHouseholdMember, setIsHouseholdMember] = useState(false);
  const [inviterData, setInviterData] = useState(null);
  // Add this useEffect to check for invited user
  useEffect(() => {
    const getInviteIdFromUrl = () => {
      // Split the pathname by '/'
      const pathParts = location.pathname.split("/");

      // Find the index of "registration"
      const registrationIndex = pathParts.findIndex(
        (part) => part === "registration"
      );

      // Get the part immediately after "registration"
      if (
        registrationIndex !== -1 &&
        registrationIndex + 1 < pathParts.length
      ) {
        const id = pathParts[registrationIndex + 1];
        if (id) {
          setInviteId(id);
          return id;
        }
      }

      return null;
    };

    const fetchInviteData = async (id) => {
      try {
        const inviteRef = doc(db, "invited_users", id);
        const inviteSnap = await getDoc(inviteRef);

        if (inviteSnap.exists()) {
          const data = inviteSnap.data();
          console.log("Invite data found:", data);
          setInviteData(data);

          if (data.type === "household_member") {
            setIsHouseholdMember(true);

            // Get the inviter's user document
            const inviterRef = doc(db, "users", data.invited_by);
            const inviterSnap = await getDoc(inviterRef);

            if (inviterSnap.exists()) {
              const inviterData = inviterSnap.data();
              console.log("Inviter data found:", inviterData);
              setInviterData(inviterData);
            }
          }

          // Pre-fill the form with invite data
          console.log("inviteData", inviteData);
        } else {
          console.log("No invitation found with ID:", id);
        }
      } catch (error) {
        console.error("Error fetching invitation:", error);
      }
    };

    const id = getInviteIdFromUrl();
    if (id) {
      console.log("Invitation ID found:", id);
      fetchInviteData(id);
    }
  }, [location]);
  const [formData, setFormData] = useState({
    userType: "Citizen",
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    mobile_number: "",
    password: "",
    country: "US",
    county: "",
    name_of_org: "",
    name_of_hub: "",
    state_or_national_nonprofit: "",
    a501c3: "",
    ein: "",
    area_serviced_county: "",
  });
  const [selectedState, setSelectedState] = useState("");
  const [missingFields, setMissingFields] = useState([]);
  const [counties, setCounties] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  //Partner
  //const [a501c3, seta501c3] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPW, setConfirmPW] = useState("");
  const [agree, setAgree] = useState(false);

  const [isValidLength, setIsValidLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumberOrSpecialChar, setHasNumberOrSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonDisabled2, setButtonDisabled2] = useState(false);

  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalMessage, setModalMessage] = useState(""); // State for modal message
  const [invalidEmail, setInvalidEmail] = useState(false); // State for invalid email
  const [isEmailExistsModal, setIsEmailExistsModal] = useState(false); // State for email exists modal

  const [popupMessage, setPopupMessage] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [isDuplicateAddress, setIsDuplicateAddress] = useState(false);
  const [dupAddressBackupData, setDupAddressBackupData] = useState({
    dup_address_otp: "",
    dup_address_token: "",
    dup_address_original_email: "",
    dup_address_fraud_email: "",
  });

  const { login, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();

  const yesNoOptions = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  useEffect(() => {
    if (missingFields.length > 0) {
      const timer = setTimeout(() => {
        setMissingFields([]);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [missingFields]);

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleStateChange = (e) => {
    const stateAbbreviation = e.target.value;
    const stateObject = USStates.find(
      (state) => state.abbreviation === stateAbbreviation
    ); // Find the state object
    const stateName = stateObject ? stateObject.name : "";

    setSelectedState(stateAbbreviation);
    setFormData((prevFormData) => ({
      ...prevFormData,
      state: stateAbbreviation,
    }));

    // Filter counties based on selected state
    const filteredCounties = countiesData.filter(
      (county) => county.State === stateName
    );
    setCounties(filteredCounties);
  };

  const handleCountyChange = (e, isPartner) => {
    if (isPartner) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        area_serviced_county: e.target.value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        county: e.target.value,
      }));
    }
    if (isPartner && !e.target.value) {
      setMissingFields([...missingFields, "area_serviced_county"]);
    }
  };

  const checkEmailExistence = async () => {
    const email = formData.email;
    const snapshot = await getDocs(
      query(collection(db, "users"), where("email", "==", email))
    );
    if (snapshot.empty) {
      setEmailExists(false);
    } else {
      setPopupMessage(
        "This email address is already registered. Please use a different email address or log in to your existing account."
      );
      setEmailExists(true);
      return true; // Return true to indicate email exists and registration should not proceed
    }
    return false; // Return false to indicate email does not exist and registration can proceed
  };

  const checkDupAddress = async () => {
    if (formData.userType === "Citizen" && !isHouseholdMember) {
      // Get all users and convert their addresses to lowercase for comparison
      const usersRef = collection(db, "users");
      const citizenQuery = query(usersRef, where("user_role", "==", "citizen"));
      const snapshot = await getDocs(citizenQuery);

      // Check each document manually with case-insensitive comparison
      const matchingDoc = snapshot.docs.find((doc) => {
        const userData = doc.data();
        return (
          userData.address1?.toLowerCase() ===
            formData.address1.trim().toLowerCase() &&
          userData.city?.toLowerCase() === formData.city.trim().toLowerCase() &&
          userData.state?.toLowerCase() === formData.state.toLowerCase() &&
          userData.zip?.toLowerCase() === formData.zip.trim().toLowerCase()
        );
      });

      if (matchingDoc) {
        // Set the duplicate address flag
        setIsDuplicateAddress(true);
        try {
          setDupAddress(true);
          const existingUser = matchingDoc.data();
          const firstName = existingUser.first_name;
          const email = existingUser.email;
          const otp = generateOtp();

          console.log("existingUser: ", existingUser);

          // Generate a secure verification token
          const verificationToken =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);

          setDupAddressBackupData({
            dup_address_otp: otp,
            dup_address_token: verificationToken,
            dup_address_original_email: email,
            dup_address_fraud_email: formData.email,
          });

          // Store the verification data in Firebase
          const verificationData = {
            original_email: email,
            fraud_email: formData.email,
            created_at: new Date().toISOString(),
            otp: otp,
            token: verificationToken,
          };

          setDupAddress(verificationData);

          // Create a base64 encoded string of the verification data
          const encodedData = btoa(JSON.stringify(verificationData));
          const loginLink = `${window.location.origin}/login?dupAddress=true&token=${verificationToken}&data=${encodedData}`;

          console.log("Generated login link:", loginLink);

          await mailer.sendOtpDuplicateEmail(email, otp, firstName, loginLink);
          return true;
        } catch (error) {
          console.error("Error in checkDupAddress:", error);
          return false;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    console.log("RegisterV2 dupAddressData updated:", dupAddressData);
  }, [dupAddressData]);

  const handleContinue = async (e) => {
    e.preventDefault();

    // Prevent multiple clicks while processing
    if (buttonDisabled2) {
      return;
    }
    setButtonDisabled2(true);

    try {
      const emailExists = await checkEmailExistence();

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email) && formData.email) {
        setInvalidEmail(true);
        setMissingFields((prev) => [...prev, "email"]);
        //setPopupMessage("Please enter a valid email address.");
        setButtonDisabled2(false);
        return;
      }

      if (emailExists) {
        setButtonDisabled2(false);
        return;
      }

      let requiredFields = [];
      switch (formData.userType) {
        case "Center":
          requiredFields = [
            "email",
            "first_name",
            "last_name",
            "address1",
            "city",
            "state",
            "zip",
            "county",
            "country",
            "mobile_number",
            "name_of_hub",
          ];
          break;
        case "Partner":
          requiredFields = [
            "name_of_org",
            "email",
            "first_name",
            "last_name",
            "mobile_number",
            "state",
            "area_serviced_county",
            // "county",
            "state_or_national_nonprofit",
            "a501c3",
          ];
          break;
        case "Citizen":
          requiredFields = [
            "email",
            "first_name",
            "last_name",
            "address1",
            "city",
            "state",
            "zip",
            "county",
            "country",
            "mobile_number",
          ];
          break;
        default:
          requiredFields = [
            "email",
            "first_name",
            "last_name",
            "address1",
            "city",
            "state",
            "zip",
            "county",
            "country",
            "mobile_number",
          ];
      }

      const missing = requiredFields.filter((field) => !formData[field]);

      // Check if EIN is required when a501c3 is "Yes"
      if (formData.a501c3 === "Yes" && !formData.ein) {
        missing.push("ein");
      }
      setMissingFields(missing);

      if (missing.length > 0) {
        setButtonDisabled2(false);
        return;
      }

      setCurrentStep(2);

      await checkDupAddress();
    } catch (error) {
      console.error("Error in handleContinue:", error);
      setButtonDisabled2(false);
    }
  };

  const handleContinue2 = async (e) => {
    e.preventDefault();

    // Check password validation conditions
    if (!formData.password || !confirmPW) {
      setModalMessage("Password fields cannot be blank.");
      setShowModal(true);
      setButtonDisabled(false); // Reset button state
      return;
    }

    if (formData.password !== confirmPW) {
      setModalMessage("Password does not match!");
      setShowModal(true);
      setButtonDisabled(false); // Reset button state
      return;
    }

    if (!agree) {
      setModalMessage(
        "Please review and accept our Privacy Policy and Terms & Conditions to continue."
      );
      setShowModal(true);
      setButtonDisabled(false); // Reset button state
      return;
    }

    // Check password strength conditions
    if (!isValidLength || !hasUpperCase || !hasNumberOrSpecialChar) {
      setModalMessage("Password does not meet the required criteria.");
      setShowModal(true);
      setButtonDisabled(false); // Reset button state
      return;
    }

    // Only set button to disabled/loading if all validations pass
    setButtonDisabled(true);

    try {
      await handleSignup();
    } catch (error) {
      // Re-enable the button if there's an error
      setButtonDisabled(false);
      // Show error modal if needed
      setModalMessage(error.message);
      setShowModal(true);
    }
  };

  const generateOtp = () => {
    return Math.floor(Math.random() * 900000 + 100000).toString();
  };

  const generatePin = () => {
    return Math.floor(Math.random() * 9000 + 1000).toString();
  };

  const checkIfEmailExists = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    // window.location.reload();
    return !querySnapshot.empty; // Returns true if email exists
  };

  const getCenterIdByCounty = async (county) => {
    const centersRef = collection(db, "centers");
    const q = query(centersRef, where("county", "==", county));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      return snapshot.docs[0].id;
    }

    return "";
  };

  const checkUserRegistrationStatus = async (email) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        return userData.registered === true;
      }
      return false;
    } catch (error) {
      console.error("Error checking registration status:", error);
      return false;
    }
  };

  const handleSignup = async (e) => {
    try {
      // if (!formData.password || !confirmPW) {
      //   setModalMessage("Password fields cannot be blank."); // Set modal message
      //   setShowModal(true); // Show the modal
      //   return;
      // }
      // if (formData.password !== confirmPW) {
      //   setModalMessage("Password does not match!"); // Set modal message
      //   setShowModal(true); // Show the modal
      //   return;
      // }

      // if (!agree) {
      //   setModalMessage("Check Privacy Policy and Terms and Conditions."); // Set modal message
      //   setShowModal(true); // Show the modal
      //   return;
      // }

      const emailExists = await checkIfEmailExists(formData.email);

      if (emailExists) {
        console.log("emailExists", emailExists);
        setModalMessage(
          "An account with this email already exists. Please use a different email or log in to your existing account."
        ); // Set modal message
        setIsEmailExistsModal(true);
        return;
      }
      setButtonDisabled(true);

      const { email, password, first_name, last_name, ...otherData } = formData;

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      let centerId = null;
      const otp = generateOtp();
      const status = "inactive";
      let user_role = "citizen";
      let centersRef;
      let centerDocRef;
      let employeeSubcollectionRef;
      let organizationSubcollectionRef;
      let partnersRef;
      let partnersDofRef;
      let partnerId = null;

      switch (formData.userType) {
        case "Center":
          user_role = "center";
          // create center
          centersRef = collection(db, "centers");
          centerDocRef = await addDoc(centersRef, {
            ...otherData,
            email,
            first_name,
            last_name,
            profile_completed: false,
          });
          centerId = centerDocRef.id;
          // create user
          await setDoc(doc(db, "users", userCredential.user.uid), {
            email,
            password: encrypt(password),
            first_name,
            last_name,
            address1: formData.address1,
            address2: formData.address2,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            county: formData.county,
            country: formData.country,
            mobile_number: formData.mobile_number,
            otp: encrypt(otp),
            status,
            user_role,
            center_id: centerId,
            citizen_id: userCredential.user.uid,
            profile_completed: false,
          });
          // create employee under center
          employeeSubcollectionRef = collection(
            db,
            `centers/${centerId}/employees`
          );
          await setDoc(doc(employeeSubcollectionRef, userCredential.user.uid), {
            user_id: userCredential.user.uid,
            email,
            first_name,
            last_name,
            is_admin: true,
          });
          // create organization under users
          organizationSubcollectionRef = collection(
            db,
            `users/${userCredential.user.uid}/organizations`
          );
          await setDoc(doc(organizationSubcollectionRef, centerId), {
            org_type: "center",
            org_id: centerId,
            role: "center",
            is_admin: true,
            email,
            first_name,
            last_name,
            ...otherData,
          });
          break;
        case "Partner":
          user_role = "partner";
          centerId = await getCenterIdByCounty(formData.county);
          // create partner
          partnersRef = collection(db, "partners");
          partnersDofRef = await addDoc(partnersRef, {
            ...otherData,
            pin: encrypt("7777"),
            email,
            first_name,
            last_name,
            profile_completed: false,
          });
          partnerId = partnersDofRef.id;
          // create user
          await setDoc(doc(db, "users", userCredential.user.uid), {
            email,
            password: encrypt(password),
            first_name,
            last_name,
            address1: formData.address1,
            address2: formData.address2,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            county: formData.county,
            country: formData.country,
            mobile_number: formData.mobile_number,
            otp: encrypt(otp),
            status,
            user_role,
            center_id: [],
            citizen_id: userCredential.user.uid,
            partner_id: partnerId,
            profile_completed: false,
          });
          // create employee under partner
          employeeSubcollectionRef = collection(
            db,
            `partners/${partnerId}/employees`
          );
          await setDoc(doc(employeeSubcollectionRef, userCredential.user.uid), {
            user_id: userCredential.user.uid,
            email,
            first_name,
            last_name,
            is_admin: true,
          });
          // create organization under users
          organizationSubcollectionRef = collection(
            db,
            `users/${userCredential.user.uid}/organizations`
          );
          await setDoc(doc(organizationSubcollectionRef, partnerId), {
            org_type: "partner",
            org_id: partnerId,
            role: "partner",
            is_admin: true,
            email,
            first_name,
            last_name,
            ...otherData,
          });
          break;
        case "Citizen":
          user_role = "citizen";
          centerId = await getCenterIdByCounty(formData.county);
          const householdId =
            isHouseholdMember && inviterData
              ? inviterData.household_id
              : generateRandomString(28);
          const householdMemberId = generateRandomString(28);
          await setDoc(doc(db, "users", userCredential.user.uid), {
            email,
            password: encrypt(password),
            first_name,
            last_name,
            otp: encrypt(otp),
            pin: encrypt("7777"),
            status,
            user_role,
            center_id: centerId,
            citizen_id: userCredential.user.uid,
            profile_completed: false,
            household_id: householdId,
            household_member_id: householdMemberId,
            ...otherData,
          });

          // Add user to household_members subcollection
          const householdMemberRef = doc(
            collection(db, "household", householdId, "household_members")
          );
          await setDoc(householdMemberRef, {
            date_of_birth: "",
            disability: "",
            first_name: first_name,
            last_name: last_name,
            household_member_id: householdMemberId,
            military_vet: "",
            relationship: "",
            acc_created: true,
            email: email,
          });
          break;
        default:
          user_role = "citizen";
          centerId = await getCenterIdByCounty(formData.county);
          const householdId2 =
            isHouseholdMember && inviterData
              ? inviterData.household_id
              : generateRandomString(28);
          const householdMemberId2 = generateRandomString(28);
          await setDoc(doc(db, "users", userCredential.user.uid), {
            email,
            first_name,
            last_name,
            otp: encrypt(otp),
            pin: encrypt("7777"),
            status,
            user_role,
            center_id: centerId,
            citizen_id: userCredential.user.uid,
            household_id: householdId2,
            household_member_id: householdMemberId2,
            ...otherData,
          });
          // Add user to household_members subcollection
          const householdMemberRef2 = doc(
            collection(db, "household", householdId2, "household_members")
          );
          await setDoc(householdMemberRef2, {
            date_of_birth: "",
            disability: "",
            first_name: first_name,
            last_name: last_name,
            household_member_id: householdMemberId2,
            email: email,
          });
      }

      await mailer.sendOtpEmail(email, otp, first_name);

      const creds = await signInWithEmailAndPassword(auth, email, password);

      setRoleBasedRoute(user_role);
      login(creds.user.accessToken);

      // If the address is duplicate, set the user to inactive before redirecting to register2
      if (isDuplicateAddress && !isHouseholdMember) {
        const userRef = doc(db, "users", userCredential.user.uid);
        await updateDoc(userRef, {
          dup_address: true,
          dup_address_owner: false,
          dup_address_otp: dupAddressBackupData.dup_address_otp,
          dup_address_token: dupAddressBackupData.dup_address_token,
        });

        // Update original user's document with duplicate address fields
        const originalUserQuery = query(
          collection(db, "users"),
          where("email", "==", dupAddressBackupData.dup_address_original_email)
        );
        const originalUserSnapshot = await getDocs(originalUserQuery);
        if (!originalUserSnapshot.empty) {
          const originalUserDoc = originalUserSnapshot.docs[0];
          await updateDoc(doc(db, "users", originalUserDoc.id), {
            dup_address: true,
            dup_address_owner: true,
            dup_address_token: dupAddressBackupData.dup_address_token,
          });
        }
      }

      localStorage.setItem("newlyRegistered", "true");
      setEmailfunc(email);
      navigate("/register2", { state: { inviteId: inviteId } });
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setModalMessage("This email is already registered."); // Set modal message
        setShowModal(true); // Show the modal
      } else if (err.code === "auth/invalid-email") {
        setModalMessage("Invalid email format."); // Set modal message
        setShowModal(true); // Show the modal
      } else if (err.code === "auth/weak-password") {
        setModalMessage("Password is too weak."); // Set modal message
        setShowModal(true); // Show the modal
      } else {
        setModalMessage(err.message); // Set modal message
        setShowModal(true); // Show the modal
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleEmailExistsOkay = () => {
    setIsEmailExistsModal(false);
    window.location.reload();
  };

  const isPasswordValid = () => {
    return (
      isValidLength &&
      hasUpperCase &&
      hasNumberOrSpecialChar &&
      passwordsMatch &&
      formData.password &&
      confirmPW
    );
  };

  return (
    <div className="viewport h-auto w-full">
      {popupMessage && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[350px] rounded-lg bg-white shadow-lg">
            <div className="text-lg bg-blue rounded-t-lg p-4 font-bold text-white">
              {emailExists
                ? "Duplicate Email"
                : invalidEmail
                ? "Invalid Email"
                : "Notificationb"}
            </div>
            <div className="flex flex-col justify-between bg-white p-4">
              <p>{popupMessage}</p>
              <div className={`mt-4 flex w-full justify-center`}>
                <button
                  onClick={() => setPopupMessage("")}
                  className="btn-default mt-2 w-full rounded px-4 py-2 hover:cursor-pointer"
                >
                  Try another email
                </button>
              </div>

              <div className="mt-4 flex justify-center">
                <p className="mt-2 text-sm">
                  Already have an account?
                  <Link
                    to="/login"
                    className="text-[#348BFF] hover:cursor-pointer"
                  >
                    {" "}
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="viewport-bg flex min-h-screen w-full items-center justify-center pb-2 pt-2">
        <div className="flex rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)] ">
          <div className="w-[450px] rounded-l-lg bg-white pb-2">
            <h5 className="flex flex-col items-center justify-center">
              <img
                loading="lazy"
                src={blueSkyLogo}
                className="mt-2 aspect-[0.99] w-[90px] object-contain"
                alt="DARCI logo"
              />
              <label className="login-logo-label mt-2.5">DARCI</label>
            </h5>
            <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
              <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                <div className="text-xl leading-none">
                  {/* <span className="text-base">Powered by </span> */}
                  <span className="text-sm text-blue-500">
                    Disaster Aware & Ready Communities
                  </span>
                </div>
                <div className="mt-1.5 text-xs">Step {currentStep} of 3</div>
                <div className="ml-1 mt-1.5 text-base leading-none">
                  Create account as a
                  <span className="ml-1 text-blue-500">{`${formData.userType}`}</span>
                </div>
              </div>
            </div>

            <div className="z-0 flex w-[483px] max-w-full flex-col items-center justify-center px-5">
              <div className="flex w-full flex-col gap-[5px] py-3">
                {currentStep === 1 && (
                  <form className="z-0 flex w-[483px] max-w-full flex-col items-center justify-center">
                    <div className="flex w-full flex-col gap-[5px]">
                      <div>
                        <DropDownListComponent
                          floatLabelType="Auto"
                          dataSource={userType}
                          fields={{ text: "value", value: "value" }}
                          value={formData.userType}
                          name="userType"
                          change={(e) =>
                            handleChange({
                              target: { name: "userType", value: e.value }, // Ensure name is "userType"
                            })
                          }
                          cssClass="user-type-dropdown e-outline"
                        />
                      </div>
                      {formData.userType === "Citizen" && (
                        <div className="flex w-full flex-col gap-[5px]">
                          <div className="flex gap-2">
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="First Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="first_name"
                                value={formData.first_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "first_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("first_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Last Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="last_name"
                                value={formData.last_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "last_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("last_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Email Address"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="email"
                              value={formData.email}
                              change={(e) =>
                                handleChange({
                                  target: { name: "email", value: e.value },
                                })
                              }
                            />
                            {missingFields.includes("email") && (
                              <span className="text-xs text-red-500">
                                {invalidEmail && formData.email !== ""
                                  ? "Invalid email format"
                                  : "Required"}
                              </span>
                            )}
                          </div>
                          <div>
                            <TextBoxComponent
                              placeholder="Mobile Number"
                              floatLabelType="Auto"
                              name="mobile_number"
                              cssClass="e-outline"
                              value={formData.mobile_number}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "mobile_number",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("mobile_number") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>

                          <div>
                            <DropDownListComponent
                              placeholder="Select a country"
                              floatLabelType="Auto"
                              dataSource={countries}
                              fields={{ text: "name", value: "abbreviation" }}
                              value={formData.country}
                              name="country"
                              change={(e) =>
                                handleChange({
                                  target: { name: "country", value: e.value },
                                })
                              }
                              cssClass="e-outline"
                            />
                            {missingFields.includes("country") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Address 1"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="address1"
                              value={
                                isHouseholdMember && inviterData
                                  ? inviterData.address1
                                  : formData.address1
                              }
                              change={(e) =>
                                handleChange({
                                  target: { name: "address1", value: e.value },
                                })
                              }
                              readonly={isHouseholdMember}
                            />
                            {missingFields.includes("address1") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Address 2"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="address2"
                              value={
                                isHouseholdMember && inviterData
                                  ? inviterData.address2
                                  : formData.address2
                              }
                              change={(e) =>
                                handleChange({
                                  target: { name: "address2", value: e.value },
                                })
                              }
                              readonly={isHouseholdMember}
                            />
                          </div>
                          <div className="flex gap-2">
                            <div className="w-[40%]">
                              <TextBoxComponent
                                placeholder="City"
                                floatLabelType="Auto"
                                name="city"
                                cssClass="e-outline"
                                value={
                                  isHouseholdMember && inviterData
                                    ? inviterData.city
                                    : formData.city
                                }
                                change={(e) =>
                                  handleChange({
                                    target: { name: "city", value: e.value },
                                  })
                                }
                                readonly={isHouseholdMember}
                              />
                              {missingFields.includes("city") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="flex-1">
                              <DropDownListComponent
                                dataSource={USStates}
                                fields={{
                                  text: "abbreviation",
                                  value: "abbreviation",
                                }}
                                value={
                                  isHouseholdMember && inviterData
                                    ? inviterData.state
                                    : selectedState
                                }
                                change={(e) =>
                                  handleStateChange({
                                    target: { value: e.value },
                                  })
                                }
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="State"
                                readonly={isHouseholdMember}
                              />
                              {missingFields.includes("state") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-[25%]">
                              <TextBoxComponent
                                placeholder="Zip"
                                floatLabelType="Auto"
                                name="zip"
                                cssClass="e-outline"
                                value={
                                  isHouseholdMember && inviterData
                                    ? inviterData.zip
                                    : formData.zip
                                }
                                change={(e) =>
                                  handleChange({
                                    target: { name: "zip", value: e.value },
                                  })
                                }
                                readonly={isHouseholdMember}
                              />
                              {missingFields.includes("zip") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="">
                            <DropDownListComponent
                              dataSource={counties}
                              fields={{ text: "County", value: "County" }}
                              value={
                                isHouseholdMember && inviterData
                                  ? inviterData.county
                                  : formData.county
                              }
                              change={(e) =>
                                handleCountyChange({
                                  target: { value: e.value },
                                })
                              }
                              enabled={!!selectedState}
                              cssClass="e-outline"
                              floatLabelType="Auto"
                              placeholder="County"
                              //readonly={isHouseholdMember}
                            />
                            {missingFields.includes("county") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {formData.userType === "Partner" && (
                        <div className="flex w-full flex-col gap-[5px]">
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Name of Organization"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="name_of_org"
                              value={formData.name_of_org}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "name_of_org",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("name_of_org") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="flex gap-2">
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="First Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="first_name"
                                value={formData.first_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "first_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("first_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Last Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="last_name"
                                value={formData.last_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "last_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("last_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Email Address"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="email"
                              value={formData.email}
                              change={(e) =>
                                handleChange({
                                  target: { name: "email", value: e.value },
                                })
                              }
                            />
                            {missingFields.includes("email") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div>
                            <TextBoxComponent
                              placeholder="Contact Number"
                              floatLabelType="Auto"
                              name="mobile_number"
                              cssClass="e-outline"
                              value={formData.mobile_number}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "mobile_number",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("mobile_number") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>

                          <div className="flex gap-2">
                            <div className="w-full">
                              <DropDownListComponent
                                dataSource={USStates}
                                fields={{
                                  text: "abbreviation",
                                  value: "abbreviation",
                                }}
                                value={selectedState}
                                change={(e) =>
                                  handleStateChange({
                                    target: { value: e.value },
                                  })
                                }
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="Area Serviced (State)"
                              />
                              {missingFields.includes("state") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <MultiSelectComponent
                                dataSource={counties}
                                fields={{ text: "County", value: "County" }}
                                value={formData.area_serviced_county}
                                change={(e) =>
                                  handleCountyChange(
                                    { target: { value: e.value } },
                                    true
                                  )
                                }
                                enabled={!!selectedState}
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="Area Serviced (County)"
                                mode="CheckBox"
                                selectAllText="Select All"
                                unSelectAllText="Unselect All"
                                showSelectAll={true}
                              >
                                <Inject services={[CheckBoxSelection]} />
                              </MultiSelectComponent>
                              {missingFields.includes(
                                "area_serviced_county"
                              ) && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="w-full">
                            <DropDownListComponent
                              dataSource={yesNoOptions}
                              fields={{ text: "text", value: "value" }}
                              placeholder="Are you a state or a national nonprofit"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "state_or_national_nonprofit",
                                    value: e.value,
                                  },
                                })
                              }
                              value={formData.state_or_national_nonprofit}
                            />
                            {missingFields.includes(
                              "state_or_national_nonprofit"
                            ) && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <DropDownListComponent
                              dataSource={yesNoOptions}
                              fields={{ text: "text", value: "value" }}
                              placeholder="Are you a 501c3"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              change={(e) =>
                                handleChange({
                                  target: { name: "a501c3", value: e.value },
                                })
                              }
                              value={formData.a501c3}
                            />
                            {missingFields.includes("a501c3") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          {formData.a501c3 === "Yes" && (
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="EIN (Numbers Only)"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                type="number"
                                name="ein"
                                value={formData.ein}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "ein", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("ein") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {formData.userType === "Center" && (
                        <div className="flex w-full flex-col gap-[5px]">
                          <div>
                            <TextBoxComponent
                              placeholder="Name of Organization"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="name_of_hub"
                              value={formData.name_of_hub}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "name_of_hub",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("name_of_hub") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="flex gap-2">
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="First Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="first_name"
                                value={formData.first_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "first_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("first_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Last Name"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="last_name"
                                value={formData.last_name}
                                change={(e) =>
                                  handleChange({
                                    target: {
                                      name: "last_name",
                                      value: e.value,
                                    },
                                  })
                                }
                              />
                              {missingFields.includes("last_name") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex gap-2">
                            <div className="w-full">
                              <TextBoxComponent
                                placeholder="Email Address"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                name="email"
                                value={formData.email}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "email", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("email") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Contact Number"
                              floatLabelType="Auto"
                              name="mobile_number"
                              cssClass="e-outline"
                              value={formData.mobile_number}
                              change={(e) =>
                                handleChange({
                                  target: {
                                    name: "mobile_number",
                                    value: e.value,
                                  },
                                })
                              }
                            />
                            {missingFields.includes("mobile_number") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div>
                            <DropDownListComponent
                              placeholder="Select a country"
                              floatLabelType="Auto"
                              dataSource={countries}
                              fields={{ text: "name", value: "abbreviation" }}
                              value={formData.country}
                              name="country"
                              change={(e) =>
                                handleChange({
                                  target: { name: "country", value: e.value },
                                })
                              }
                              cssClass="e-outline"
                            />
                            {missingFields.includes("country") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Address 1"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="address1"
                              value={formData.address1}
                              change={(e) =>
                                handleChange({
                                  target: { name: "address1", value: e.value },
                                })
                              }
                            />
                            {missingFields.includes("address1") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                          <div className="w-full">
                            <TextBoxComponent
                              placeholder="Address 2"
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              name="address2"
                              value={formData.address2}
                              change={(e) =>
                                handleChange({
                                  target: { name: "address2", value: e.value },
                                })
                              }
                            />
                          </div>
                          <div className="flex gap-2">
                            <div className="w-[40%]">
                              <TextBoxComponent
                                placeholder="City"
                                floatLabelType="Auto"
                                name="city"
                                cssClass="e-outline"
                                value={formData.city}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "city", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("city") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="flex-1">
                              <DropDownListComponent
                                dataSource={USStates}
                                fields={{
                                  text: "abbreviation",
                                  value: "abbreviation",
                                }}
                                value={selectedState}
                                change={(e) =>
                                  handleStateChange({
                                    target: { value: e.value },
                                  })
                                }
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder="State"
                              />
                              {missingFields.includes("state") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                            <div className="w-[25%]">
                              <TextBoxComponent
                                placeholder="Zip"
                                floatLabelType="Auto"
                                name="zip"
                                cssClass="e-outline"
                                value={formData.zip}
                                change={(e) =>
                                  handleChange({
                                    target: { name: "zip", value: e.value },
                                  })
                                }
                              />
                              {missingFields.includes("zip") && (
                                <span className="text-xs text-red-500">
                                  Required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="w-full">
                            <DropDownListComponent
                              dataSource={counties}
                              fields={{ text: "County", value: "County" }}
                              value={formData.county}
                              change={(e) =>
                                handleCountyChange({
                                  target: { value: e.value },
                                })
                              }
                              enabled={!!selectedState}
                              cssClass="e-outline"
                              floatLabelType="Auto"
                              placeholder="County"
                            />
                            {missingFields.includes("county") && (
                              <span className="text-xs text-red-500">
                                Required
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="flex max-w-[483px] flex-col justify-center tracking-wide">
                        <div className="max-md:max-w-full flex w-full flex-col justify-center text-center">
                          <button
                            className="max-md:max-w-full bg-login-btn w-full gap-2.5 self-stretch whitespace-nowrap rounded-md p-2.5 text-xs font-bold text-white hover:cursor-pointer"
                            onClick={handleContinue}
                            disabled={buttonDisabled2}
                          >
                            {buttonDisabled2 ? "Please Wait..." : "Continue"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                {currentStep === 3 && (
                  <div className="max-md:px-5 flex max-w-[483px] flex-col justify-center rounded-2xl bg-white p-8">
                    <h2 className="text-xl max-md:max-w-full font-semibold leading-tight text-slate-600">
                      Enter Your Confirmation Code
                    </h2>
                    <section className="max-md:max-w-full mt-2  flex w-full flex-col justify-center">
                      <p className="font-small max-md:max-w-full text-slate-600">
                        Please enter the confirmation code sent to your email.
                      </p>
                      <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-7">
                        <input
                          type="text"
                          aria-label="Confirmation code"
                          className="max-md:max-w-full my-auto flex min-h-[40px] w-full min-w-[240px] flex-1 shrink basis-0 self-stretch rounded-lg border-2 border-solid border-gray-300 bg-white"
                        />
                      </div>
                      <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-2.5 text-base font-medium">
                        <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                          You have entered an invalid code!
                        </div>
                        <button className="my-auto w-[121px] self-stretch text-right text-blue-600">
                          Resend Code
                        </button>
                      </div>
                      <div
                        className="flex max-w-[483px] flex-col justify-center tracking-wide"
                        style={{ marginTop: "40px" }}
                      >
                        <div className="text-gray-700 max-md:max-w-full flex w-full flex-col items-start text-base leading-none">
                          <div className="flex w-full gap-0.5">
                            <input
                              type="checkbox"
                              id="privacyPolicyCheckbox"
                              className="h-5 min-h-[20px] w-5 rounded border border-solid border-zinc-300 hover:cursor-pointer"
                            />
                            <label
                              htmlFor="privacyPolicyCheckbox"
                              className="text-gray-700 z-10 mr-0 min-h-[21px] flex-auto gap-2.5 self-stretch px-2.5 py-0.5 text-base leading-none"
                            >
                              I agree with{" "}
                              <a
                                className="text-[inherit] text-blue-500 [text-decoration:none]"
                                href="/privacy"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a
                                className="text-[inherit] text-blue-500 [text-decoration:none]"
                                href="/terms"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Terms & Conditions
                              </a>
                            </label>
                          </div>
                        </div>
                        <div className="max-md:max-w-full mt-5 flex w-full flex-col justify-center text-center">
                          <button className="max-md:max-w-full bg-login-btn w-full gap-2.5 self-stretch whitespace-nowrap rounded-md p-2.5 text-xs font-bold text-white">
                            Create an Account
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                )}

                {currentStep === 2 && (
                  <form className="z-0  flex w-[483px] max-w-full flex-col items-center justify-center">
                    <div className="flex w-full flex-col gap-[5px]">
                      <div className="mt-2 flex">
                        <div>
                          <img
                            loading="lazy"
                            src={userIcon}
                            className="aspect-[0.99] w-[40px] object-contain"
                          />
                        </div>
                        {formData.userType === "Citizen" && (
                          <div className="ml-3">
                            <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                              {formData.email}
                            </p>
                            <p
                              className="max-md:max-w-full mt-1 whitespace-nowrap"
                              style={{ fontSize: "12px", fontWeight: 500 }}
                            >
                              {formData.last_name
                                ? formData.last_name
                                : "Last Name not provided"}
                              ,{" "}
                              {formData.first_name
                                ? formData.first_name
                                : "First Name not provided"}
                            </p>
                          </div>
                        )}
                        {formData.userType === "Center" && (
                          <div className="ml-3">
                            <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                              {formData.email}
                            </p>
                            <p
                              className="max-md:max-w-full mt-1 whitespace-nowrap"
                              style={{ fontSize: "12px", fontWeight: 500 }}
                            >
                              {formData.name_of_hub
                                ? formData.name_of_hub
                                : "Not provided"}
                            </p>
                          </div>
                        )}
                        {formData.userType === "Partner" && (
                          <div className="ml-3">
                            <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                              {formData.email}
                            </p>
                            <p
                              className="max-md:max-w-full mt-1 whitespace-nowrap"
                              style={{ fontSize: "12px", fontWeight: 500 }}
                            >
                              {formData.name_of_org
                                ? formData.name_of_org
                                : "Not provided"}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="relative mt-2 w-full">
                        <TextBoxComponent
                          placeholder="Password"
                          floatLabelType="Auto"
                          cssClass="e-outline"
                          type={showPassword ? "text" : "password"}
                          onPaste={(e) => e.preventDefault()}
                          name="password"
                          value={formData.password}
                          input={(args) => {
                            // Check how `args` delivers the input value.
                            const password = args?.value || ""; // Safe access to `args.value`

                            // Update the form data state
                            setFormData((prev) => ({
                              ...prev,
                              password,
                            }));

                            // Update validation states
                            setIsValidLength(password.length >= 8);
                            setHasUpperCase(/[A-Z]/.test(password));
                            setHasNumberOrSpecialChar(
                              /[0-9!@#$%^&*(),.?":{}|<>]/.test(password)
                            );
                            setPasswordsMatch(password === confirmPW);
                          }}
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer bg-white text-blue-500"
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                        {missingFields.includes("password") && (
                          <span className="text-xs text-red-500">Required</span>
                        )}
                      </div>
                      <div className="relative w-full">
                        <TextBoxComponent
                          placeholder="Confirm Password"
                          floatLabelType="Auto"
                          cssClass="e-outline"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPW}
                          onPaste={(e) => e.preventDefault()}
                          input={(args) => {
                            // Access `args.value` for the input value
                            const confirmPassword = args?.value || ""; // Safe access to `args.value`
                            setConfirmPW(confirmPassword);

                            // Update password match validation
                            setPasswordsMatch(
                              formData.password === confirmPassword
                            );
                          }}
                        />
                        <button
                          type="button"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer bg-white text-blue-500"
                        >
                          {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                      <div className="flex flex-col gap-2">
                        <p className="text-start text-xs font-semibold">
                          Your password must include:
                        </p>
                        <div
                          className={`flex items-center gap-1 text-xs font-medium ${
                            isValidLength && formData.password
                              ? "text-[#16A34A]"
                              : "text-[#991B1B]"
                          }`}
                        >
                          <img
                            src={
                              isValidLength && formData.password
                                ? bxcheck
                                : bxclose
                            }
                            alt="check-icon"
                          />
                          <p>At least 8 characters</p>
                        </div>
                        <div
                          className={`flex items-center gap-1 text-xs font-medium ${
                            hasUpperCase && formData.password
                              ? "text-[#16A34A]"
                              : "text-[#991B1B]"
                          }`}
                        >
                          <img
                            src={
                              hasUpperCase && formData.password
                                ? bxcheck
                                : bxclose
                            }
                            alt="check-icon"
                          />
                          <p>At least 1 uppercase letter</p>
                        </div>
                        <div
                          className={`flex items-center gap-1 text-xs font-medium ${
                            hasNumberOrSpecialChar && formData.password
                              ? "text-[#16A34A]"
                              : "text-[#991B1B]"
                          }`}
                        >
                          <img
                            src={
                              hasNumberOrSpecialChar && formData.password
                                ? bxcheck
                                : bxclose
                            }
                            alt="check-icon"
                          />
                          <p>At least 1 number or special character</p>
                        </div>
                        <div
                          className={`flex items-center gap-1 text-xs font-medium ${
                            passwordsMatch && formData.password && confirmPW
                              ? "text-[#16A34A]"
                              : "text-[#991B1B]"
                          }`}
                        >
                          <img
                            src={
                              passwordsMatch && formData.password && confirmPW
                                ? bxcheck
                                : bxclose
                            }
                            alt="check-icon"
                          />
                          <p>Passwords match</p>
                        </div>
                      </div>
                      <div
                        className="flex max-w-[483px] flex-col justify-center tracking-wide"
                        style={{ marginTop: "40px" }}
                      >
                        <div className="text-gray-700 max-md:max-w-full flex w-full flex-col items-start text-base leading-none">
                          <div className="flex w-full gap-0.5">
                            <input
                              type="checkbox"
                              id="privacyPolicyCheckbox"
                              name="privacyPolicyCheckbox"
                              className={`h-5 min-h-[20px] w-5 rounded border border-solid 
                              ${
                                !isPasswordValid()
                                  ? "bg-gray-100 cursor-not-allowed border-zinc-200"
                                  : "border-zinc-300 hover:cursor-pointer"
                              }`}
                              value={agree}
                              onChange={(e) => setAgree(e.target.checked)}
                              disabled={!isPasswordValid()}
                            />
                            <label
                              htmlFor="privacyPolicyCheckbox"
                              className={`text-gray-700 pCy-0.5 z-10 mr-0 min-h-[21px] flex-auto gap-2.5 self-stretch px-2.5 text-base leading-none
                              ${!isPasswordValid() ? "text-gray-400" : ""}`}
                            >
                              I agree with{" "}
                              <a
                                className={`text-[inherit] ${
                                  isPasswordValid()
                                    ? "text-blue-500"
                                    : "text-gray-400"
                                } [text-decoration:none]`}
                                href="/privacy"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a
                                className={`text-[inherit] ${
                                  isPasswordValid()
                                    ? "text-blue-500"
                                    : "text-gray-400"
                                } [text-decoration:none]`}
                                href="/terms"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Terms & Conditions
                              </a>
                            </label>
                          </div>
                        </div>
                        <div className="max-md:max-w-full mt-5 flex w-full flex-col justify-center text-center">
                          <button
                            className={`max-md:max-w-full w-full gap-2.5 self-stretch whitespace-nowrap rounded-md p-2.5 text-xs font-bold text-white
                            ${
                              !isPasswordValid() || !agree
                                ? "cursor-not-allowed bg-gray-400"
                                : "bg-login-btn hover:cursor-pointer"
                            }`}
                            onClick={handleContinue2}
                            disabled={
                              !isPasswordValid() || !agree || buttonDisabled
                            }
                          >
                            {buttonDisabled ? "Please wait..." : "Continue"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                <div className="z-0 flex w-[483px] max-w-full flex-col items-center justify-center">
                  <div className="flex w-full flex-col gap-[5px]">
                    <div className="max-md:max-w-full mt-5 flex w-full flex-col justify-center text-center">
                      <div className="self-center text-base leading-none text-black">
                        <span className="text-gray-700">
                          Already have an account?
                        </span>{" "}
                        <a href="/login" className="text-blue-500">
                          Login
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-login-right-panel block w-[350px] rounded-r-lg p-4 leading-4 mq700:hidden">
            <h3 className="pt-10 leading-relaxed text-white">
              Welcome to the DARCI Program, a disaster aware and community ready
              application.
              <br />
              <br />
              We are here to support your efforts in strengthening community
              preparedness and recovery.
              <br />
              <br />
              Access comprehensive tools, resources, and insights to enhance
              your leadership, response, and recovery capabilities.
              <br />
              <br />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

RegisterV2.propTypes = {
  className: PropTypes.string,
};

export default RegisterV2;
