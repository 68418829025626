import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import DisasterMgtService from "../../../service/citizen/DisasterMgtService";
import DisasterImpactGraph from "../.././DashboardGraphs/DisasterImpactGraph";
import InjuryGraph from "../.././DashboardGraphs/InjuryGraph";
import HomeDamageGraph from "../.././DashboardGraphs/HomeDamageGraph";
import HomeDestroyedGraph from "../.././DashboardGraphs/HomeDestroyedGraph";
import HomeDamageTypedGraph from "../.././DashboardGraphs/HomeDamageTypedGraph";

const DisasterDashboard = ({ propId }) => {
  const [disasterData, setDisasterData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempData, setTempData] = useState({});
  const [lastEdited, setLastEdited] = useState(null);
  const [selectedStage, setSelectedStage] = useState("response");
  const disasterId = Array.isArray(propId) ? propId[0] : propId;
  const [affectedCounties, setAffectedCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [editing, setEditing] = useState({
    allowDeleting: true,
    allowAdding: true,
    allowEditing: true,
    mode: "Dialog",
    showDeleteConfirmDialog: true,
    dialog: { cssClass: "custom-dialog" },
  });
  const [toolbarOptions, setToolbarOptions] = useState([
    "Add",
    "Edit",
    "Delete",
  ]); // Initialize toolbar options
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalResponses, setTotalResponses] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalValueSum, setTotalValue] = useState(0);
  const [immediateAssistance, setImmediateAssistance] = useState(0);
  const [recoveryAssistance, setRecoveryAssistance] = useState(0);

  const [disasterNames, setDisasterNames] = useState([]);
  const [userId, setUserId] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [checkboxStates, setCheckboxStates] = useState({
    show_disaster_impact_graph: false,
    show_injury_graph: false,
    show_home_damage_graph: false,
    show_homes_destroyed_graph: false,
    show_home_damage_typed_graph: false,
  });

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedImmediateAssistance = formatter.format(immediateAssistance);
  const formattedRecoveryAssistance = formatter.format(recoveryAssistance);

  useEffect(() => {
    const fetchDisaster = async () => {
      if (!disasterId) {
        console.error("No valid ID provided to DisasterDashboard component.");
        return;
      }

      try {
        const docRef = doc(db, "declared-disaster", disasterId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setDisasterData(data);
          setAffectedCounties(data.affected_counties || []);
          setSelectedCounty(data.affected_counties?.[0] || "");
        }
      } catch (error) {
        console.error("Error fetching disaster data:", error);
      }
    };

    fetchDisaster();
  }, [disasterId]);

  const handleCountyChange = (e) => {
    setSelectedCounty(e.target.value);
  };

  useEffect(() => {
    const fetchDisaster = async () => {
      const id = Array.isArray(disasterId) ? disasterId[0] : disasterId;

      if (!id) {
        console.error("No valid ID provided to DisasterDetail component.");
        return;
      }

      try {
        const docRef = doc(db, "declared-disaster", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDisasterData({ id, ...docSnap.data() });
          setTempData({ id, ...docSnap.data() });
          setLastEdited(new Date().toLocaleString());
        }
      } catch (error) {
        console.error("Error fetching disaster data:", error);
      }
    };

    fetchDisaster();
  }, [disasterId]);

  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          setUserRole(role);
          if (role === "center") {
            setEditing({
              allowEditing: false,
              allowAdding: false,
              allowDeleting: false,
              mode: "Dialog",
              dialog: { cssClass: "custom-dialog" },
            });
            setToolbarOptions([]);
          }
        }
      }
    };

    const fetchData = async () => {
      try {
        // Fetch declared disaster data
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const fetchedData = querySnapshot.docs.map((doc) => {
          const activeData = doc.data();
          return {
            ...activeData,
            disaster_id: doc.id,
            disaster_date: activeData.disaster_date
              ? activeData.disaster_date.toDate().toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : null,
          };
        });
        setActiveData(fetchedData);

        // Fetch survey responses data for charts
        const surveySnapshot = await getDocs(
          collection(db, "survey_responses")
        );
        setTotalResponses(surveySnapshot.size);
        // Initialize variables to store tally for charts
        let disasterImpactCount = { affected: 0, notAffected: 0 };
        let injuryCount = {
          noInjury: 0,
          minorInjury: 0,
          medicalTreatment: 0,
          visitedHospital: 0,
          admitted: 0,
        };
        let homeDamageCount = {
          noDamage: 0,
          slightDamage: 0,
          limitedDamage: 0,
          severeDamage: 0,
          totalDestruction: 0,
        };
        let homesDestroyedCount = { damaged: 0, destroyed: 0, undamaged: 0 };
        let typeOfHomeDamageCount = {
          "Check All": 0,
          Siding: 0,
          Flooring: 0,
          Cabinets: 0,
          "Shed/garage": 0,
          Roof: 0,
          Foundation: 0,
          "Floor Joists": 0,
          HVAC: 0,
          Driveway: 0,
          Gutters: 0,
          Ceiling: 0,
          Drywall: 0,
          Electrical: 0,
          "Yard/Uprooted trees": 0,
          Other: 0,
        };

        // surveySnapshot.forEach((doc) => {
        //   const data = doc.data().responses;

        //   // Disaster Impact
        //   if (data["Was the impacted address your primary address?"] === "No") {
        //     disasterImpactCount.notAffected += 1;
        //   } else if (
        //     data["Was the impacted address your primary address?"] === "Yes"
        //   ) {
        //     disasterImpactCount.affected += 1;
        //   }

        //   // Injury Data Mapping
        //   if (data["What level of injury did you sustain?"] === "No Injury") {
        //     injuryCount.noInjury += 1;
        //   } else if (
        //     data["What level of injury did you sustain?"] === "Minor Injury"
        //   ) {
        //     injuryCount.minorInjury += 1;
        //   } else if (
        //     data["What level of injury did you sustain?"] ===
        //     "Sought medical treatment"
        //   ) {
        //     injuryCount.medicalTreatment += 1;
        //   } else if (
        //     data["What level of injury did you sustain?"] === "Visited Hospital"
        //   ) {
        //     injuryCount.visitedHospital += 1;
        //   } else if (
        //     data["What level of injury did you sustain?"] === "Admitted"
        //   ) {
        //     injuryCount.admitted += 1;
        //   }

        //   // Home Damage
        //   switch (data["What level of damage did your home sustain?"]) {
        //     case "No Damage":
        //       homeDamageCount.noDamage += 1;
        //       break;
        //     case "Slight Damage":
        //       homeDamageCount.slightDamage += 1;
        //       break;
        //     case "Limited Damage":
        //       homeDamageCount.limitedDamage += 1;
        //       break;
        //     case "Severe Damage":
        //       homeDamageCount.severeDamage += 1;
        //       break;
        //     case "Total Destruction":
        //       homeDamageCount.totalDestruction += 1;
        //       break;
        //     default:
        //       break;
        //   }

        //   // Homes Destroyed
        //   switch (data["The impacted home:"]) {
        //     case "Has minor damage":
        //       homesDestroyedCount.undamaged += 1;
        //       break;
        //     case "Has major damage":
        //       homesDestroyedCount.damaged += 1;
        //       break;
        //     case "Is destroyed":
        //       homesDestroyedCount.destroyed += 1;
        //       break;
        //     default:
        //       break;
        //   }

        //   // Type of Home Damage
        //   const homeDamageMap = data["There is visible damage to the home's:"];

        //   if (homeDamageMap) {
        //     Object.keys(typeOfHomeDamageCount).forEach((type) => {
        //       if (homeDamageMap[type] === true) {
        //         typeOfHomeDamageCount[type] += 1;
        //       }
        //     });
        //   }
        // });

        // setDisasterImpactData(disasterImpactCount);
        // setInjuryData(injuryCount);
        // setHomeDamageData(homeDamageCount);
        // setHomesDestroyedData(homesDestroyedCount);
        // setTypeOfHomeDamageData(typeOfHomeDamageCount);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchUserRole();
    fetchData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const {
          allRecords,
          totalRecords,
          totalValueSum,
          immediateAssistanceSum,
          recoveryAssistanceSum,
        } = await DisasterMgtService.fetchDisasterData(disasterId);
        setData(allRecords);
        setTotalRecords(totalRecords);
        setTotalValue(totalValueSum);
        setImmediateAssistance(immediateAssistanceSum);
        setRecoveryAssistance(recoveryAssistanceSum);
      } catch (error) {
        console.error("Error loading disaster data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadSurveyData = async () => {
      try {
        const {
          disasterImpactCount,
          injuryCount,
          homeDamageCount,
          homesDestroyedCount,
          typeOfHomeDamageCount,
        } = await DisasterMgtService.fetchDisasterSurvey(disasterId);


        setDisasterImpactData(disasterImpactCount);
        setInjuryData(injuryCount);
        setHomeDamageData(homeDamageCount);
        setHomesDestroyedData(homesDestroyedCount);
        setTypeOfHomeDamageData(typeOfHomeDamageCount);
      } catch (error) {
        console.error("Error loading survey data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadSurveyData();
  }, []);

  useEffect(() => {
    const fetchUserCheckboxStates = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        setUserId(user.uid);
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCheckboxStates({
            ...checkboxStates,
            ...userData,
          });
        }
      }
    };

    fetchUserCheckboxStates();
  }, []);

  useEffect(() => {
    const fetchDisasterNames = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const names = querySnapshot.docs.map((doc) => doc.data().disaster_name);
        setDisasterNames(names);
      } catch (error) {
        console.error("Error fetching disaster names:", error);
      }
    };

    fetchDisasterNames();
  }, []);

  const handleCheckboxChange = async (field, checked) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [field]: checked,
    }));

    if (userId) {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        [field]: checked,
      });
    }
  };

  const [disasterImpactData, setDisasterImpactData] = useState({
    affected: 0,
    notAffected: 0,
  });
  const [injuryData, setInjuryData] = useState({
    noInjury: 0,
    minorInjury: 0,
    medicalTreatment: 0,
    visitedHospital: 0,
    admitted: 0,
  });
  const [homeDamageData, setHomeDamageData] = useState({
    noDamage: 0,
    slightDamage: 0,
    limitedDamage: 0,
    severeDamage: 0,
    totalDestruction: 0,
  });
  const [homesDestroyedData, setHomesDestroyedData] = useState({
    damaged: 0,
    destroyed: 0,
    undamaged: 0,
  });
  const [typeOfHomeDamageData, setTypeOfHomeDamageData] = useState({
    "Check All": 0,
    Siding: 0,
    Flooring: 0,
    Cabinets: 0,
    "Shed/garage": 0,
    Roof: 0,
    Foundation: 0,
    "Floor Joists": 0,
    HVAC: 0,
    Driveway: 0,
    Gutters: 0,
    Ceiling: 0,
    Drywall: 0,
    Electrical: 0,
    "Yard/Uprooted trees": 0,
    Other: 0,
  });

  if (!disasterData) {
    return <p>Loading disaster dashboard...</p>;
  }

  return (
    <div className="">
      {/* Report Generator Start */}
      <div className="w-full flex-1 bg-white pr-4">
        <section
          className={`text-13xl flex w-full max-w-full flex-col items-start justify-start gap-5 text-left`}
        >
          <div className="flex w-full items-center justify-end gap-2.5">
            {affectedCounties.length > 0 ? (
              <select
                value={selectedCounty}
                onChange={handleCountyChange}
                className="relative text-[20px] font-extrabold leading-normal text-blue-500"
              >
                {affectedCounties.map((county, index) => (
                  <option key={index} value={county}>
                    {county}
                  </option>
                ))}
              </select>
            ) : (
              <p className="relative text-[20px] font-extrabold leading-normal text-blue-500">
                Select County
              </p>
            )}
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-5">
            <div className="box-border flex w-full flex-row flex-wrap items-start justify-start gap-5">
              {/* Card: People Affected */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    People Affected
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {totalRecords}
                  </div>
                </div>
              </div>

              {/* Card: Immediate Response Assistance Received */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    Immediate Response Assistance Received
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {formattedImmediateAssistance}
                  </div>
                </div>
              </div>

              {/* Card: Recovery Assistance Received */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    Recovery Assistance Received
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {formattedRecoveryAssistance}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Dropdown Checkbox */}
        <div className="mt-4 flex w-full items-center justify-end">
          <div className="relative">
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
            >
              {sidebarOpen ? "Close Settings" : "Open Settings"}
            </button>

            {/* Dropdown Content */}
            {sidebarOpen && (
              <div
                className="absolute right-0 mt-2 w-72 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg"
                style={{ zIndex: 1 }}
              >
                <div className="">
                  <div className=" flex flex-col gap-4">
                    <CheckBoxComponent
                      label="Show Disaster Impact Graph"
                      checked={checkboxStates.show_disaster_impact_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_disaster_impact_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Injury Graph"
                      checked={checkboxStates.show_injury_graph}
                      change={(e) =>
                        handleCheckboxChange("show_injury_graph", e.checked)
                      }
                    />
                    <CheckBoxComponent
                      label="Show Home Damage Graph"
                      checked={checkboxStates.show_home_damage_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_home_damage_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Homes Destroyed Graph"
                      checked={checkboxStates.show_homes_destroyed_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_homes_destroyed_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Type of Home Damage Graph"
                      checked={checkboxStates.show_home_damage_typed_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_home_damage_typed_graph",
                          e.checked
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Graphs */}
        <section className="mx-auto mt-4 w-full">
          <div className="flex flex-wrap justify-between gap-5">
            {checkboxStates.show_disaster_impact_graph && (
              <div className="min-w-[400px] max-w-full flex-1">
                <DisasterImpactGraph disasterImpactData={disasterImpactData} />
              </div>
            )}
            {checkboxStates.show_injury_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <InjuryGraph injuryData={injuryData} />
              </div>
            )}
            {checkboxStates.show_home_damage_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDamageGraph homeDamageData={homeDamageData} />
              </div>
            )}
            {checkboxStates.show_homes_destroyed_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDestroyedGraph homesDestroyedData={homesDestroyedData} />
              </div>
            )}
            {checkboxStates.show_home_damage_typed_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDamageTypedGraph
                  typeOfHomeDamageData={typeOfHomeDamageData}
                />
              </div>
            )}
          </div>
        </section>
      </div>
      {/* Report Generator End */}
    </div>
  );
};

export default DisasterDashboard;
