import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import placeholderImage from "../icons/Opportunities/opportunities.png";
import { useEffect } from "react";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  updateDoc,
  where,
  query,
  getDocs,
  doc,
  getFirestore,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const VolunteerOpportunities = ({ className = "", onNavigateBack }) => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [volunteers, setVolunteers] = useState([]);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");

  const filteredVolunteers = volunteers.filter((volunteer) =>
    volunteer.volunteer.title.toLowerCase().includes(searchTitle.toLowerCase())
  );

  const convertToDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "N/A";

    const date = timestamp.toDate();
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const db = getFirestore();

  const fetchVolunteers = async () => {
    if (!userData.id) {
      setIsLoading(false);
    }

    try {
      setIsLoading(true);
      const userIds = [userData.id, userData.citizen_id].filter((id) => id);

      if (userIds.length > 0) {
        const volunteersQuery = query(
          collection(db, "opportunities"),
          where("type", "==", "volunteer"),
          where("center_id", "array-contains", userData.center_id),
          where("is_deleted", "==", false),
          where("published", "==", true)
        );

        const querySnapshot = await getDocs(volunteersQuery);
        const fetchedVolunteers = querySnapshot.docs.map((doc) => ({
          volunteer: doc.data(),
        }));

        console.log("fetchedVolunteers: ", fetchedVolunteers);
        setVolunteers(fetchedVolunteers);
      } else {
        console.log("No valid user IDs found");
        setVolunteers([]);
      }
    } catch (error) {
      console.error("Error fetching volunteers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchVolunteers();
  }, [userData]);

  useEffect(() => {
    if (volunteers.length > 0 && !selectedVolunteer) {
      setSelectedVolunteer(volunteers[0].volunteer);
    }
  }, [volunteers]);

  const VolunteerCard = ({ volunteer }) => {
    const [applicationsCount, setApplicationsCount] = useState(0);
    const isSelected =
      selectedVolunteer && selectedVolunteer.id === volunteer.id;

    const fetchApplicationsCount = async () => {
      try {
        const applicationsRef = collection(
          db,
          "opportunities",
          volunteer.id,
          "applications"
        );
        const applicationsSnapshot = await getDocs(applicationsRef);
        setApplicationsCount(applicationsSnapshot.size);
      } catch (error) {
        console.error("Error fetching applications count:", error);
      }
    };

    useEffect(() => {
      fetchApplicationsCount();
    }, [volunteer.id]);

    return (
      <div
        className={`flex w-full flex-col items-end justify-start gap-[5px] rounded-mini border bg-white ${
          isSelected ? "job-card-selected" : "job-card"
        }`}
        onClick={() => setSelectedVolunteer(volunteer)}
      >
        <div className="w-full px-4 py-2">
          <div className="flex flex-col gap-1">
            <div className="flex w-full justify-between">
              <div className="w-2/3 space-y-1">
                <b className="block truncate text-base">{volunteer.title}</b>
                <div className="flex items-center gap-1">
                  <span className="text-xs font-medium">
                    Application Deadline:
                  </span>
                  <span className="text-xs">
                    {convertToDate(volunteer.applicationDeadline)}
                  </span>
                </div>
                <p className="text-xs">{volunteer.location}</p>

                <div className="flex w-fit rounded bg-gray-300 p-2 text-xs font-semibold">
                  {volunteer.jobType}
                </div>
              </div>
              <img
                className="z-[1] h-[75px] w-[75px] object-cover"
                alt={volunteer.title}
                src={volunteer.backgroundImage || placeholderImage}
              />
            </div>
            <div className="text-sm">
              {volunteer.jobDescription.length > 220
                ? `${volunteer.jobDescription.substring(0, 220)}...`
                : volunteer.jobDescription}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleApply = async () => {
    if (!userData || !userData.id) {
      alert("You must be logged in to apply.");
      return;
    }

    try {
      const applicationsRef = doc(
        db,
        "opportunities",
        selectedVolunteer.id,
        "applications",
        userData.id
      );

      await setDoc(
        applicationsRef,
        {
          userId: userData.id,
          appliedAt: new Date(),
          userName:
            `${userData.first_name} ${userData.last_name}` || "Anonymous",
          email: userData.email || "No email provided",
        },
        { merge: true }
      );

      //open Dialog
    } catch (error) {
      console.error("Error applying to job:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-Inter font-bold text-blue-500">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div className="flex w-full flex-col">
        <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <p className="font-Inter relative inline-block min-w-[80px] text-left text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Opportunities
          </p>
          <div className="font-Inter relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <p className="font-Inter relative inline-block min-w-[77px] text-left text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Volunteer Opportunities
          </p>
        </div>
      </div>
      <div className="flex h-[calc(100vh-150px)] w-full">
        <div className="job-card-cont w-[400px] min-w-[400px] overflow-y-auto border-r border-solid border-gray-300">
          <div className="border-b border-solid border-gray-300 pb-2">
            <input
              type="text"
              placeholder="Search a Volunteer"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              className="font-Inter w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-[#467BA4] focus:outline-none"
            />
          </div>
          <div className="job-card-cont grid grid-cols-1 gap-2">
            {filteredVolunteers.map((volunteer) => (
              <VolunteerCard
                key={volunteer.volunteer.id}
                volunteer={volunteer.volunteer}
              />
            ))}
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          {selectedVolunteer ? (
            <div className="h-full">
              <div className="w-full">
                <div className="mb-5 flex h-[150px] items-center justify-between border-b border-solid border-gray-300 p-2">
                  <div className="w-full space-y-1">
                    <b className="block truncate text-[25px] text-[#FDB841]">
                      {selectedVolunteer.title}
                    </b>
                    <div className="flex items-center gap-1">
                      <span className="text-base font-medium">
                        Application Deadline:
                      </span>
                      <span className="text-base">
                        {convertToDate(selectedVolunteer.applicationDeadline)}
                      </span>
                    </div>
                    <p className="text-base">{selectedVolunteer.location}</p>

                    <div className="flex w-full items-center space-x-2">
                      <div className="flex w-fit rounded bg-gray-300 p-2 text-base font-semibold">
                        {selectedVolunteer.jobType}
                      </div>
                      <div className="h-10 border-r border-solid border-gray-300"></div>
                      <button
                        className={`flex cursor-pointer gap-1 rounded px-3 py-2 text-base font-semibold ${
                          selectedVolunteer?.closed
                            ? "cursor-not-allowed bg-gray-400 text-white"
                            : "bg-[#467BA4] text-[#FDB841]"
                        }`}
                        type="button"
                        onClick={handleApply}
                        disabled={selectedVolunteer?.closed}
                      >
                        {selectedVolunteer?.closed
                          ? "Volunteer Closed"
                          : "Apply Now"}
                      </button>
                    </div>
                  </div>
                  <img
                    className="h-[150px] w-auto"
                    alt={selectedVolunteer.title}
                    src={selectedVolunteer.backgroundImage || placeholderImage}
                  />
                </div>
                <div className="mb-5">
                  <div className="font-Inter mb-1 flex items-center gap-1 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter mt-2 font-bold">
                      VOLUNTEER ROLE DESCRIPTION
                    </p>
                  </div>

                  <div className="rounded-lg bg-white px-4">
                    <p className="font-Inter text-left text-black">
                      {selectedVolunteer.jobDescription || "N/A"}
                    </p>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="font-Inter mb-1 flex items-center gap-2 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter font-bold">
                      SKILLS & QUALIFICATIONS
                    </p>
                  </div>

                  <div className="rounded-lg bg-white px-4">
                    <p className="font-Inter text-left text-black">
                      {selectedVolunteer.qualifications || "N/A"}
                    </p>
                  </div>
                </div>

                <div className="mb-5">
                  <div className="font-Inter mb-1 flex items-center gap-2 text-[#467ba4]">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                    </svg>
                    <p className="text-xl font-Inter font-bold">BENEFITS</p>
                  </div>
                  <div className="rounded-lg bg-white px-6">
                    <p className="font-Inter text-left text-black">
                      {selectedVolunteer.benefits || "N/A"}
                    </p>
                  </div>
                </div>

                <div className="flex w-full gap-2">
                  {/* Contact Information */}
                  <div className="w-1/2">
                    <div className="mb-2 flex items-center gap-2 text-[#467ba4]">
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 7V3H2v18h20V7H12z" />
                      </svg>
                      <p className="text-xl font-Inter font-bold">
                        CONTACT INFORMATION
                      </p>
                    </div>
                    <div className="rounded-lg bg-white px-4">
                      <div className="font-Inter space-y-1 text-left text-black">
                        {[
                          {
                            label: "Contact Name",
                            value: selectedVolunteer.contactName,
                          },
                          {
                            label: "Contact Email",
                            value: selectedVolunteer.contactEmail,
                          },
                          {
                            label: "Contact Phone",
                            value: selectedVolunteer.contactPhone,
                          },
                        ].map((item, index) => (
                          <div key={index} className="flex items-center gap-2">
                            <p className="font-bold">{item.label}:</p>
                            <p>{item.value || "N/A"}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Employer Information */}
                  <div className="w-1/2">
                    <div className="mb-2 flex items-center gap-2 text-[#467ba4]">
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 7V3H2v18h20V7H12z" />
                      </svg>
                      <p className="text-xl font-Inter font-bold">
                        ORGANIZATION INFORMATION
                      </p>
                    </div>
                    <div className="rounded-lg bg-white px-4">
                      <div className="font-Inter space-y-1 text-left text-black">
                        {[
                          {
                            label: "Company Name",
                            value: selectedVolunteer.companyName,
                          },
                          {
                            label: "Location",
                            value: selectedVolunteer.location,
                          },
                          {
                            label: "Volunteer Type",
                            value: selectedVolunteer.jobType,
                          },
                        ].map((item, index) => (
                          <div key={index} className="flex items-center gap-2">
                            <p className="font-bold">{item.label}:</p>
                            <p>{item.value || "N/A"}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="font-Inter flex h-full items-center justify-center text-gray-500">
              Select a volunteer to view details
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

VolunteerOpportunities.propTypes = {
  className: PropTypes.string,
};

export default VolunteerOpportunities;
