import React, { useState, useEffect } from "react";
import {
  Map as VISGoogleMap,
  APIProvider,
  Marker,
} from "@vis.gl/react-google-maps";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { config } from "../../../utils/config";

const DisasterAffectedCounty = ({ propId }) => {
  const [tempData, setTempData] = useState({});
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const fetchDisaster = async () => {
      const id = Array.isArray(propId) ? propId[0] : propId;

      if (!id) {
        console.error("No valid ID provided to DisasterAffectedCounty component.");
        return;
      }

      try {
        const docRef = doc(db, "declared-disaster", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = { id, ...docSnap.data() };
          setTempData(data);

          if (data.affected_counties && Array.isArray(data.affected_counties)) {
            const countyCoordinates = await Promise.all(
              data.affected_counties.map(async (county) => {
                try {
                  const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                      county
                    )}&key=${config.google_maps}`
                  );
                  const geocodeData = await response.json();

                  if (geocodeData.results.length > 0) {
                    const { lat, lng } = geocodeData.results[0].geometry.location;
                    return { lat, lng, county };
                  } else {
                    console.error(`No coordinates found for: ${county}`);
                    return null;
                  }
                } catch (error) {
                  console.error(`Error fetching coordinates for ${county}:`, error);
                  return null;
                }
              })
            );

            // Filter out any null entries
            setMarkers(countyCoordinates.filter((item) => item !== null));
          }
        }
      } catch (error) {
        console.error("Error fetching disaster data:", error);
      }
    };

    fetchDisaster();
  }, [propId]);

  const getMarkerImage = () => ({
    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
    scaledSize: new window.google.maps.Size(35, 35),
    labelOrigin: new window.google.maps.Point(17, -10),
  });

  return (
    <div className="relative h-[calc(100vh-64px)] w-full">
      <APIProvider apiKey={config.google_maps}>
        <VISGoogleMap
          defaultZoom={4}
          defaultCenter={{ lat: 39.8, lng: -98.57 }}
          options={{
            disableDefaultUI: true,
            gestureHandling: "greedy",
          }}
        >
          {/* Add markers for all affected counties */}
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={{ lat: marker.lat, lng: marker.lng }}
              icon={getMarkerImage()}
              label={{
                text: marker.county,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            />
          ))}
        </VISGoogleMap>
      </APIProvider>
    </div>
  );
};

export default DisasterAffectedCounty;
